import { ImageComponent } from "./../../modals/image/image.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { FileHandle } from "./../../directives/dragDrop.directive";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ENTER } from "@angular/cdk/keycodes";
import { FullServiceService } from "./../../../services/full-service.service";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { UntypedFormControl } from "@angular/forms";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from "@angular/material/legacy-autocomplete";
import { MatLegacyChipInputEvent as MatChipInputEvent } from "@angular/material/legacy-chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UserService } from "../../../services/user.service";

@Component({
  selector: "app-add-article",
  templateUrl: "./add-article.component.html",
  styleUrls: ["./add-article.component.scss"],
})
export class AddArticleComponent implements OnInit {
  form: UntypedFormGroup;
  userFilter: UntypedFormGroup;
  disabledBtn = true;
  // subs = [];
  // Modal Image
  image: [];
  types = [{ type: "video" }, { type: "article" }, { type: "galery" }];

  usersSearch = "";
  users = [];

  // Tags
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER];
  fruitCtrl = new UntypedFormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = [];

  @ViewChild("fruitInput") fruitInput: ElementRef<HTMLInputElement>;

  fileList: Array<File> = [];
  list: File = null;
  progress = 0;
  urlList: string;
  files: FileHandle[] = [];
  downloadLink = false;

  waitingFiles = [];

  urlAfterUpload: any;
  // categories: Category[];
  objectId: string;

  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: [File] = [null];

  constructor(
    private full: FullServiceService,
    private userService: UserService,
    private router: Router,
    private translae: TranslateService,
    public dialog: MatDialog,
    private activateRouter: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.userFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl("", []),
      role: new UntypedFormControl("user", []),
      deleted: new UntypedFormControl(false, []),
      active: new UntypedFormControl(true, []),
      advertiserStatus: new UntypedFormControl(true, []),
    });

    // this.getCategoriesNames();

    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allFruits.slice()
      )
    );
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl("", [Validators.required]),
      des: new UntypedFormControl("", [Validators.required]),
      shortDes: new UntypedFormControl("", []),
      images: new UntypedFormControl([""], []),
      mainImage: new UntypedFormControl("", []),
      category: new UntypedFormControl("000000000000000000000000", [
        Validators.required,
      ]),
      parentCat: new UntypedFormControl("000000000000000000000000", [
        Validators.required,
      ]),
      price: new UntypedFormControl(0, []),

      verified: new UntypedFormControl("", [Validators.required]),
      promoted: new UntypedFormControl("", [Validators.required]),
      status: new UntypedFormControl("", [Validators.required]),
      type: new UntypedFormControl("poster", [Validators.required]),

      posterLink: new UntypedFormControl("", []),
      posterLinkTitleAr: new UntypedFormControl("", []),
      posterLinkTitleEn: new UntypedFormControl("", []),

      ifNoti: new UntypedFormControl("false", []),
      notiTitle: new UntypedFormControl("", []),
      notiBody: new UntypedFormControl("", []),

      user: new UntypedFormControl("", [Validators.required]),
    });

    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = "";
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(
      (fruit) => fruit.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getObject(id: string) {
    this.full.getOneProduct(this.objectId).subscribe(
      (response: any) => {
        response.data.des = this.htmlDecode(response.data.des);

        this.form.patchValue(response.data);

        this.form.patchValue({
          // user: response.data.user + '',
          status: response.data.status + "",
          promoted: response.data.promoted + "",
          verified: response.data.verified + "",
          mainImage: response.data.mainImage,
          images: response.data.images,
          parentCat: "000000000000000000000000",
          category: "000000000000000000000000",
        });
        this.full
          .getAllUsersForDropDownWithSearch(1, 8, this.userFilter.value)
          .subscribe(
            (usersData: any) => {
              this.users = usersData.data;
              this.userService
                .getOneUser(response.data.user)
                .subscribe((userData: any) => {
                  this.users.unshift(userData.data);
                  this.form.patchValue({ user: response.data.user + "" });
                });
            },
            (error) => {}
          );
        // this.full.getAllSubsForCategory(1, 500, response.data.parentCat).subscribe((subs: any) => {
        //   this.subs = subs.data;
        //   this.form.patchValue({
        //     category: response.data.category[0]._id + '',
        //   });
        // })

        if (response.data.images.length > 0) {
          if (response.data.images[0] === "") {
            // this.form.patchValue({
            //   images: []
            // })
            this.waitingFiles = [];
          } else {
            this.waitingFiles = response.data.images;
          }
        }

        this.thumbnailFileList = response.data.mainImage;
        this.thumbnailUrlList = response.data.mainImage;
        this.fruits = response.data.tags;
      },
      (err) => {
        this.snackBar.open(err);
      }
    );
  }

  htmlDecode(input) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }

  // getSubs(event) {

  //   this.full.getAllSubsForCategory(1, 500, event.value).subscribe((subs: any) => {
  //     this.subs = subs.data;
  //   })
  // }

  selectFile(event) {
    // this.waitingFiles = this.fileList;
    // this.list = null;
    this.fileList = [];

    if (event.target.files.length > 12) {
      this.full.openSnackBar(
        this.translae.instant("youCanUploadOnly12FileEveryTime"),
        "أغلق",
        "danger"
      );
      return false;
    }

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < event.target.files.length; i++) {
      this.list = event.target.files[i];
      this.fileList.push(this.list);

      this.disabledBtn = false;
    }
    this.upload();
  }

  upload() {
    const fd = new FormData();
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.fileList.length; i++) {
      fd.append("images", this.fileList[i], this.fileList[i].name);
    }
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.waitingFiles = this.waitingFiles.concat(event.body.images);

            this.form.patchValue({
              images: this.waitingFiles,
            });
            // this.downloadLink = true;
            this.disabledBtn = true;
        }
      },
      (err) => {}
    );

    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedImage() {
    this.progress = 0;
    this.fileList = [];
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];

    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append(
      "images",
      this.thumbnailFileList[0],
      this.thumbnailFileList[0].name
    );
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              mainImage: event.body.images[0],
            });
            this.thumbnailUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.thumbnailUrlList = this.translae.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      mainImage: null,
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  openImage(image): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: "800px",
      data: image,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.image = result;
      this.waitingFiles = result;
      if (this.image) {
      } else {
        this.image = [];
        this.waitingFiles = [];
      }

      this.form.patchValue({
        images: result,
      });
    });
  }

  filesDropped(files: FileHandle[]): void {
    this.urlAfterUpload = files[0].url;
    this.fileList[0] = files[0].file;
  }

  thumbnailFilesDropped(files: FileHandle[]): void {
    this.thumbnailUrlAfterUpload = files[0].url;
    this.thumbnailFileList[0] = files[0].file;
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      // if (this.form.get('mainImage').value === '') {
      //   this.form.patchValue({
      //     mainImage: 'assets/default.jpg'
      //   })
      // }
      this.full.addProduct(this.form.value).subscribe(
        (data: any) => {
          this.router.navigateByUrl("/products");
          this.full.openSnackBar(data.message || "", "أغلق", "success");
        },
        (err) => {
          this.full.openSnackBar(err || "", "أغلق", "danger");
        }
      );
    } else {
      this.full.editOneProduct(this.objectId, this.form.value).subscribe(
        (data: any) => {
          this.router.navigateByUrl("/products");
          this.full.openSnackBar(data.message || "", "أغلق", "success");
        },
        (err) => {
          this.full.openSnackBar(err || "", "أغلق", "danger");
        }
      );
    }
  }

  // getCategoriesNames() {
  //   this.full.getAllParentCategoriesForDropdown(1, 500)
  //     .subscribe((response: any) => {
  //       this.categories = response.data;
  //     }, (error) => {
  //     });
  // }

  getAllUsers() {
    this.full
      .getAllUsersForDropDownWithSearch(1, 100, this.userFilter.value)
      .subscribe(
        (response: any) => {
          this.users = response.data;
        },
        (error) => {}
      );
  }

  changeSearch() {
    if (this.usersSearch) {
      this.userFilter.patchValue({
        searchWord: this.usersSearch,
        advertiserStatus: true,
      });
      this.full
        .getAllUsersForDropDown(1, 10, this.usersSearch, this.userFilter.value)
        .subscribe(
          (response: any) => {
            this.users = response.data;
          },
          (error) => {}
        );
    }
  }
}
