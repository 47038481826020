<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'GalleryList' | translate}}</h3>
                    </div>
                </div>
                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-gallery')">{{'addNewGallery' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <div class="row text-center">
                    <div class="col-lg-5 col-12">
                        <mat-form-field class="full-width">
                            <mat-label>{{'user' | translate}}</mat-label>
                            <mat-select placeholder="{{ '' | translate }}">
                                <mat-option>
                                    <ngx-mat-select-search [(ngModel)]="usersSearch"
                                        [ngModelOptions]="{ standalone: true }"
                                        [placeholderLabel]="''" (ngModelChange)="changeSearch()">
                                        <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option (click)='filterByUser("")'>
                                    {{ 'showAll' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let g of users" [value]="g._id"
                                    (click)='filterByUser(g._id)'>
                                    {{ g.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'image' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" class="pointer">
                            <img [src]="row.image" width="50px" alt=""
                                (click)="openImage(row.image)">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer"
                                routerLink='/edit-gallery/{{row._id}}'>
                                edit
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="caption">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'caption' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" class="pointer"
                            (click)='openMessage(row.caption)'>
                            {{row.caption | slice:0:40}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="show">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'show' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" routerLink='/edit-user/{{row.user}}'>
                                visibility
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'status' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span>
                                <mat-slide-toggle color="primary" [checked]="row?.status"
                                    (change)="activate(row, $event)">
                                    <span class="green" [hidden]='row?.status !== true'>{{'active' |
                                        translate}}</span>
                                    <span class="red" [hidden]='row?.status !== false'>{{'notActive'
                                        |
                                        translate}}</span>
                                </mat-slide-toggle>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" (click)="confirmDialog(row._id)">
                                delete
                            </i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getSliders($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>