import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomValidators } from 'ng2-validation';
import { UserService } from '../../../services/user.service';
import { FullServiceService } from '../../../services/full-service.service';


@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {
  form: UntypedFormGroup;
  passForm: UntypedFormGroup;
  
  user;
  userData;

  objectId: string;
  roles = ['admin', 'editor', 'employee'];
  constructor(
    private userService: UserService,
    private router: Router,
    private translae: TranslateService,
    public dialog: MatDialog,
    private activateRouter: ActivatedRoute,
    private full: FullServiceService,
    private translate: TranslateService,
  ) {
    const newPassword = new UntypedFormControl('', [Validators.required]);
    const passwordConfirm = new UntypedFormControl('', [CustomValidators.equalTo(newPassword)]);

    this.passForm = new UntypedFormGroup({
      newPassword,
      passwordConfirm
    });
  }

  ngOnInit() {
    const password = new UntypedFormControl(null, [Validators.required]);
    const passwordConfirm = new UntypedFormControl(null, [CustomValidators.equalTo(password)]);
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.required, Validators.pattern('^[+]*[\s\./0-9]{3,}$')]),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}')]),
      password,
      passwordConfirm,
      role: new UntypedFormControl('admin', [Validators.required]),
      active: new UntypedFormControl('true', [Validators.required]),
    });
    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.userService.getOneEmployee(this.objectId).subscribe((data: any) => {
        const u: any = data.data;
        this.user = data.data;
        this.form.patchValue({
          name: u.name,
          phone: u.phone,
          email: u.email,
          role: u.role,
          active: u.active + '',
        });
        this.form.get('password').setValidators([]);
        this.form.get('password').updateValueAndValidity();
      });
    }

    // if (localStorage.getItem('currentUser')) {
    //   this.userData = JSON.parse(localStorage.getItem('currentUser')).data.user;
    //   if (this.userData.role === 'editor') {
    //     this.roles = ['user']
    //   }
    // }
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }
    if (!this.objectId) {
      this.userService.signUpEmployee(this.form.value)
        .subscribe((response: any) => {
          this.router.navigateByUrl('/stuff-list');
          this.full.openSnackBar(response.message[0] || '', 'أغلق', 'success');
        }, (error) => {
          this.full.openSnackBar(error || '', 'أغلق', 'danger');
        });
    } else {
      const u: any = this.form.value
      let body = {
        name: u.name,
        phone: u.phone,
        email: u.email,
        role: u.role,
        active: u.active + '',
      }

      this.userService.editUser(this.objectId, body)
        .subscribe((response: any) => {
          this.router.navigateByUrl('/stuff-list');
        }, (error) => {
          this.full.openSnackBar(error || '', 'أغلق', 'danger');
        });
    }
  }

  editPassword() {
    this.userService.editUserPass(this.objectId, this.passForm.value)
      .subscribe((response: any) => {
        this.router.navigateByUrl('/users-list');
        this.full.openSnackBar(response.message || '', 'أغلق', 'success');
      }, (error) => {
        this.full.openSnackBar(error || '', 'أغلق', 'danger');
      });
  }
}