<mat-dialog-content class="mat-typography">
    <div class="container-fluid">
        <div class="row text-center">
            <div class="col">
                <h2 class="h2-responsive">{{title}}</h2>
            </div>
        </div>
        <div class="row image-row" (cdkDropListDropped)="drop($event)" cdkDropList>
            <div class="col-12" *ngFor='let p of image; let i = index' cdkDrag>
                <div class="img-col">
                    <!-- <i class="uil uil-multiply pointer" (click)='removeItem(p)'></i> -->
                    <img id="img{{i}}" [src]="p | secure | async" width="100%" alt="" (click)="openImage(p)">
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="download()" class="btn btn-primary">Download PDF</button>

    <!-- <button type="button" (click)="onDown('pdf', true, image[0])">Save PDF</button> -->
    <!-- <button mat-button mat-dialog-close>{{'removeAll' | translate}}</button> -->
    <!-- <button mat-button cdkFocusInitial [mat-dialog-close]="image">{{'saveChanges' | translate}}</button> -->
</mat-dialog-actions>