<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'stuffList' | translate}}</h3>
                    </div>
                </div>
                <div class="row" style="align-items: center;">
                    <div class="col-lg-4">
                        <mat-form-field class="full-width">
                            <mat-label>{{'searchIn' | translate}}</mat-label>
                            <input matInput type="text" [(ngModel)]="searchWord" (input)='searchStuff()'>
                        </mat-form-field>
                    </div>
                    <div class="col-2 t-a-c">
                        <button mat-raised-button color="primary" (click)="goTo('add-employee')">{{'addNewEmployee' | translate}}</button>
                    </div>
                </div>
                <form [formGroup]="userFilter">
                    <div class="row" style="align-items: center;">
                        <div class="col-lg-3">
                            <mat-form-field class="full-width">
                                <mat-label> {{'status' | translate}}</mat-label>
                                <mat-select formControlName='active'>
                                    <mat-option value='true'>
                                        {{'active' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'inActive' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                            <mat-form-field class="full-width">
                                <mat-label> {{'role' | translate}}</mat-label>
                                <mat-select formControlName='role'>
                                    <mat-option value='admin'>
                                        {{'admin' | translate}}
                                    </mat-option>
                                    <mat-option value='editor'>
                                        {{'editor' | translate}}
                                    </mat-option>
                                    <mat-option value='employee'>
                                        {{'employee' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3 t-a-c">
                            <button mat-raised-button (click)="filter()" color="primary">
                                <span>{{'filterStuff' | translate}}</span>
                            </button>
                        </div>
                        <div class="col-lg-2 t-a-c">
                            <button
                              mat-raised-button
                              (click)="clearFilter()"
                              color="primary"
                            >
                              <span>{{ "cleatAllFilter" | translate }}</span>
                            </button>
                          </div>
                    </div>
                </form>

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>
                    <!-- email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.email ? row.email : '--------'}}
                        </td>
                    </ng-container>
                    <!-- phone Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" class="uni"> {{row.phone ? row.phone : '--------'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'role' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.role}} </td>
                    </ng-container>
                    <!-- active -->
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'status' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-slide-toggle *ngIf='row.role !== "owner"' color="primary"
                                [checked]="row.active" (change)="activate(row, $event)">
                                <span class="green" [hidden]='row.active !== true'>{{'active' |
                                    translate}}</span>
                                <span class="red" [hidden]='row.active !== false'>{{'inActive' |
                                    translate}}</span>
                            </mat-slide-toggle>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.createdAt | date:'short'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" *ngIf='row.role !== "owner"'
                                (click)='edit(row._id)'>
                                edit
                            </i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf='row.role !== "owner"' (click)='confirmDialog(row._id)'
                                class="pointer">{{'delete' | translate}}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="changePassword">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'changePassword' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i *ngIf='row.role !== "owner"' (click)='chagngePasswordDialog(row._id)'
                            class="material-icons pointer">security</i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getAllStuff($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>