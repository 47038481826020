import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-add-communications-attepmt',
  templateUrl: './add-communications-attepmt.component.html',
  styleUrls: ['./add-communications-attepmt.component.scss']
})
export class AddCommunicationsAttepmtComponent implements OnInit {
  deletedImages = [];
  form: UntypedFormGroup;
  id = '';
  brand: any;

  clientFilter: UntypedFormGroup;
  linkedUsers = [];
  clientSearch = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public dialog: MatDialog,
    private full: FullServiceService,
    private toastr: ToastrManager
  ) {
    this.clientFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
    })
   }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;

    this.form = new UntypedFormGroup({
      type: new UntypedFormControl('', [Validators.required]),
      result: new UntypedFormControl('', []),
      notes: new UntypedFormControl('', []),
      date: new UntypedFormControl('', []),
      client: new UntypedFormControl('', [Validators.required]),
    });

    if (this.id) {
      this.full.getOneCommunicationAttempt(this.id).subscribe((data: any) => {
        if (!data) {
          this.toastr.successToastr(this.translate.instant('addedSuccessfully'));
          this.router.navigateByUrl('/communications-list');
          return;
        } else {
          this.brand = data.data;

          this.form.patchValue(this.brand);
        }
      });
    }

    this.full.getAllClientBase(1, 8, this.clientFilter.value)
        .subscribe((linkedUsersData: any) => {
          this.linkedUsers = linkedUsersData.data;
          
        }, (error) => {
      });
  }



  addOrEdit() {
    if (!this.id) {
      this.full.addCommunicationAttempt(this.form.value).subscribe(
        (message: string) => {
          this.router.navigateByUrl('/communications-list');
        },
        err => { }
      );
    } else {
      this.full.editOneCommunicationAttempt(this.id, this.form.value).subscribe(
        (message: string) => {
          this.router.navigateByUrl('/communications-list');
        },
        err => { }
      );
    }
  }

  getAllLinkedUsers() {
    this.full.getAllClientBase(1, 50, this.clientFilter.value)
      .subscribe((response: any) => {
        this.linkedUsers = response.data;
      }, (error) => {
      });
  }

  changeLinkedUsersSearch() {
    if (this.clientSearch) {
      this.clientFilter.patchValue({
        searchWord: this.clientSearch,
      })
      this.full.getAllClientBase(1, 50, this.clientFilter.value)
        .subscribe((response: any) => {
          this.linkedUsers = response.data;
        }, (error) => {
        });
    }
  }

  valid(controlName, errorName) {
    return (
      this.form.get(controlName).hasError(errorName) &&
      (this.form.get(controlName).dirty || this.form.get(controlName).touched)
    );
  }
}
