import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { startWith } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddNoteToUserComponent } from '../add-note-to-user/add-note-to-user.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent  implements OnInit {

  userId: string;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>

  dataUser$: Observable<any>;
  dataUserSubject: BehaviorSubject<any>;

  constructor(
    private userService: UserService,
    private router: Router,
    private activateRouter: ActivatedRoute,
    public dialog: MatDialog,
  )
  {
    this.isLoadingSubject = new BehaviorSubject<any>(true);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.dataUserSubject = new BehaviorSubject<any>(null);
    this.dataUser$ = this.dataUserSubject.asObservable();
    
    this.userId = this.activateRouter.snapshot.params.id;
    this.getUserDetails();
  }

    ngOnInit() {

    }

    getUserDetails() {
      this.userService.getUserDetailsForAdmin(this.userId).subscribe((data: any) => {
        if(data.state) {
          this.dataUserSubject.next(data.data);
          this.isLoadingSubject.next(false);
          setTimeout(() => {
            this.isLoading$.subscribe(x => console.log('as', x))
            this.dataUser$.subscribe((data) => {
              console.log('dd', data);
            })          
          }, 1500);
        }else{
          this.router.navigateByUrl(`/users-list`);
        }
      }, err => {
        console.log('getUserDetails', err);
        this.router.navigateByUrl(`/users-list`);
      });
    }

    addNote() {
      const dialogRef = this.dialog.open(AddNoteToUserComponent, {
        data: this.userId
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if(result && result === true){
          this.getUserDetails();
          console.log('maa');
          
        }
      });
      
    }
}
