<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <h4 class="text-center" *ngIf="!objectId">{{'addNewOffer' | translate}}</h4>
                <h4 class="text-center" *ngIf="objectId">{{'editOffer' | translate}}</h4>
                <form [formGroup]='form'>
                    <div class="progress form-group">
                        <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar"
                            [style.width.%]="progress">
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col">
                            <div class="upload-file-card">
                                <div class="flex">
                                    <img src="assets/icons/upload-files.png" width="30px" alt="">
                                    <h5 color='primary'>
                                        <span [hidden]='!urlList'>{{'imageUploadedSuccessfully' | translate}}</span>
                                        <span [hidden]='urlList'>{{'uploadNewImage' | translate}}</span>
                                    </h5>
                                    <div class="spacer"></div>
                                    <button mat-raised-button color="primary"
                                        (click)="upload()">{{'uploadFile' | translate}}</button>
                                </div>
                                <label class="file-list" *ngIf="fileList.length > 0">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <i class="material-icons pointer" (click)="deleteUploadedImage()">
                                                    close
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container">
                                        <div class="col-12 list-col">
                                            <ul class="list-ul" *ngIf="!objectId">
                                                <li>
                                                    {{'name' | translate}}: &nbsp; {{fileList[0]?.name}}
                                                </li>
                                                <hr>
                                                <li>
                                                    {{'type' | translate}}: &nbsp; {{fileList[0]?.type}}
                                                </li>
                                                <hr>
                                                <li>
                                                    {{'size' | translate}}: &nbsp; {{fileList[0]?.size / 1000}}
                                                    &nbsp;{{'kb' | translate}}
                                                </li>
                                            </ul>
                                            <ul class="second-list-ul">
                                                <li>
                                                    <img [src]="urlList ? urlList : urlAfterUpload" alt="">
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </label>
                                <label *ngIf="fileList.length === 0" class="uploader" for="upload-photo" appDrag
                                    (files)="filesDropped($event)">
                                    <svg class="upload-icon" xmlns="http://www.w3.org/2000/svg" width="146.628"
                                        height="117.317" viewBox="0 0 146.628 117.317">
                                        <path id="upload-icon-hover" d="M122.549,34.885A51.368,51.368,0,0,0,24.51,48.755a29.353,29.353,0,0,0,6.9,57.9,
                                                    7.338,7.338,0,1,0,0-14.676,14.676,14.676,0,1,1,0-29.353,7.338,7.338,0,0,0,7.338-7.338,
                                                    36.691,36.691,0,0,1,71.4-11.815,7.338,7.338,0,0,0,5.724,4.917,22.015,22.015,0,0,1,1.761,
                                                    42.855,7.351,7.351,0,1,0,3.669,14.236,36.691,36.691,0,0,0,1.247-70.594Zm-41.9,15.19a7.586,
                                                    7.586,0,0,0-10.42,0L48.213,72.09a7.368,7.368,0,1,0,10.42,10.42l9.466-9.54v41.021a7.338,7.338,
                                                    0,1,0,14.676,0V72.971l9.466,9.54a7.368,7.368,0,1,0,10.42-10.42Z"
                                            transform="translate(-1.991 -4.013)" fill="#c9c9c9" />
                                    </svg>
                                    <label for="upload-photo">{{'dragDropFiles' | translate}}</label>
                                    <input type="file" (change)="selectFile($event)" name="photo" id="upload-photo" />
                                </label>
                                <p>
                                    {{'filesExtensions' | translate}}: [png, jpg, jpeg, gif, svg]
                                </p>
                            </div>
                        </div>
                    </div>
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'global' | translate}}
                            </ng-template>
                            <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <input matInput [matDatepicker]="picker" formControlName='startDate'
                                            placeholder="{{'startDate' | translate}}">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <input matInput [matDatepicker]="datePiker" formControlName='endDate'
                                            placeholder="{{'endDate' | translate}}">
                                        <mat-datepicker-toggle matSuffix [for]="datePiker"></mat-datepicker-toggle>
                                        <mat-datepicker #datePiker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'arabic' | translate}}
                            </ng-template>
                            <!-- <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <textarea rows="3" formControlName='sectionDesAr' matInput
                                            placeholder="{{'sectionDesAr' | translate}}"></textarea>
                                    </mat-form-field>
                                </div>
                            </div> -->

                            <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <input formControlName='titleAr' matInput
                                            placeholder="{{'titleAr' | translate}}">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <textarea rows="7" formControlName='descAr' matInput
                                            placeholder="{{'descAr' | translate}}"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'english' | translate}}
                            </ng-template>
                            <!-- <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <textarea rows="3" formControlName='sectionDesEn' matInput
                                            placeholder="{{'sectionDesEn' | translate}}"></textarea>
                                    </mat-form-field>
                                </div>
                            </div> -->
                            <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <input formControlName='titleEn' matInput
                                            placeholder="{{'titleEn' | translate}}">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <textarea rows="7" formControlName='descEn' matInput
                                            placeholder="{{'descEn' | translate}}"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'french' | translate}}
                            </ng-template>
                            <!-- <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <textarea rows="3" formControlName='sectionDesFr' matInput
                                            placeholder="{{'sectionDesFr' | translate}}"></textarea>
                                    </mat-form-field>
                                </div>
                            </div> -->
                            <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <input formControlName='titleFr' matInput
                                            placeholder="{{'titleFr' | translate}}">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row max-width-row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <textarea rows="7" formControlName='descFr' matInput
                                            placeholder="{{'descFr' | translate}}"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                    <div class="row max-width-row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid' color="primary">
                                <span *ngIf="!objectId">{{'addNewOffer' | translate}}</span>
                                <span *ngIf="objectId">{{'editOffer' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>