<div class="container container-top-margin" dir="rtl">
  <div class="row row-center">
    <div class="col">
      <form [formGroup]="form">
        <div class="row text-right">
          <div class="col-12">
            <h5>إرسال إشعار للمستخدم</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="example-container">
              <mat-form-field class="example-full-width" style="width: 100%">
                <span>{{ "title" | translate }}</span>
                <input matInput formControlName="titleAr" />
              </mat-form-field>
            </div>
          </div>
          <div class="col-12">
            <div class="example-container">
              <mat-form-field class="example-full-width" style="width: 100%">
                <span>{{ "body" | translate }}</span>
                <textarea
                  rows="5"
                  matInput
                  formControlName="bodyAr"
                  type="text"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col">
            <button
              mat-raised-button
              color="warn"
              (click)="sendFcm()"
              [disabled]="!form.valid"
            >
              {{ "sendFcm" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
