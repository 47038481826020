import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SelectionModel } from '@angular/cdk/collections';
import { ImageComponent } from '../modals/image/image.component';
import { FullServiceService } from '../../services/full-service.service';

export interface CategoryImage {
  image: string;
}

@Component({
  selector: 'app-our-agents',
  templateUrl: './our-agents.component.html',
  styleUrls: ['./our-agents.component.scss']
})

export class OurAgentsComponent implements OnInit {
  activeForm: UntypedFormGroup;
  data: any[] = [];
  pageEvent;
  selectedId = [];
  deleteForm: UntypedFormGroup;

  displayedColumns: string[] = ['title', 'subTitle', 'status', 'edit'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  priorityForm: UntypedFormGroup;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private router: Router,
    public dialog: MatDialog
  ) { this.getData(1); }

  ngOnInit() {
    this.activeForm = new UntypedFormGroup({
      status: new UntypedFormControl(null, [])
    });
  }

  getData(event: any) {
    this.full.getAllAgents(event.pageIndex + 1 || 1, event.pageSize).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openImage(image: string): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: { image: image[0] }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  activate(category: any, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      status: event.checked
    });
    this.full.changeStatusAgent(category._id, this.activeForm.value).subscribe((data: any) => {
      category.status = event.checked;
      this.full.openSnackBar(data.message[0] || '', 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
