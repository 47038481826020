<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'users' | translate}}</h3>
                    </div>
                </div>
                <!-- <div class="row add-item-row">
                    <div class="col-lg-3 flex">
                        <button mat-raised-button color="primary" (click)="goTo('add-user')">{{'addNewUser' | translate}}</button>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-lg-4">
                        <mat-form-field class="full-width">
                            <mat-label>{{'searchInUsers' | translate}}</mat-label>
                            <input matInput type="text" [(ngModel)]="searchWord" (input)='searchUsers()'>
                        </mat-form-field>
                    </div>
                    <div class="export-container">
                        <button mat-raised-button color="primary" (click)="exportArray()">Export to Excel</button>
                    </div>
                </div>
                <form [formGroup]="userFilter">
                    <div class="row" >
                        <!-- <div class="col-lg-2">
                            <mat-form-field class="full-width">
                                <mat-label> {{'isVerified' | translate}}</mat-label>
                                <mat-select formControlName='verified'>
                                    <mat-option value='true'>
                                        {{'verified' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'notVerified' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                        <div class="col-lg-2">
                            <mat-form-field class="full-width">
                                <mat-label> {{'ishasPackage' | translate}}</mat-label>
                                <mat-select formControlName='verified'>
                                    <mat-option value='true'>
                                        {{'hasPackage' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'noOrExpirePackage' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2">
                            <mat-form-field class="full-width">
                                <mat-label> {{'status' | translate}}</mat-label>
                                <mat-select formControlName='active'>
                                    <mat-option value='true'>
                                        {{'active' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'inActive' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2">
                            <mat-form-field class="full-width">
                                <mat-label> {{'city' | translate}}</mat-label>
                                <mat-select formControlName='city'>
                                    <mat-option *ngFor="let c of cities" value='{{c._id}}'>
                                        {{c.name | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2">
                            <mat-form-field class="full-width">
                                <mat-label> {{'package' | translate}}</mat-label>
                                <mat-select formControlName='package'>
                                    <mat-option *ngFor="let package of packages" value='{{package._id}}'>
                                        {{package.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="date-block__list">
                            <mat-form-field>
                              <input matInput [matDatepicker]="fromDate" [min]="minDate" placeholder="{{'fromDate' | translate}}" formControlName="fromDate">
                              <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                              <mat-datepicker #fromDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="date-block__list">
                            <mat-form-field>
                              <input matInput [matDatepicker]="toDate" placeholder="{{'toDate' | translate}}" formControlName="toDate">
                              <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                              <mat-datepicker #toDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <!-- <div class="x">
                            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker">
                            minDate: Date;
                            maxDate: Date;
                          
                            constructor() {
                              // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
                              const currentYear = new Date().getFullYear();
                              this.minDate = new Date(currentYear - 20, 0, 1);
                              this.maxDate = new Date(currentYear + 1, 11, 31);
                            }
                            <mat-form-field>
                                <mat-label>Enter a date range</mat-label>
                                <mat-date-range-input [rangePicker]="picker">
                                  <input matStartDate placeholder="Start date" formControlName="fromDate">
                                  <input matEndDate placeholder="End date" formControlName="toDate">
                                </mat-date-range-input>
                                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                              </mat-form-field>
                        </div> -->
                    </div>
                    <div class="row" >
                        <div class="col-lg-3 t-a-c">
                            <button mat-raised-button (click)="filter()" color="primary">
                                <span>{{'filterUsers' | translate}}</span>
                            </button>
                        </div>
                        <div class="col-lg-2 t-a-c">
                            <button
                              mat-raised-button
                              (click)="clearFilter()"
                              color="primary"
                            >
                              <span>{{ "cleatAllFilter" | translate }}</span>
                            </button>
                          </div>
                    </div>
                </form>

                <table id="ExampleMaterialTable" mat-table [dataSource]="dataSource" matSort>

                    <!-- name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <a  routerLink='/users-list/{{row._id}}'>
                                <i class="material-icons pointer small" routerLink='/users-list/{{row._id}}'>
                                    visibility
                                </i>
                                {{row.name}}
                            </a>
                        </td>
                    </ng-container>
                    <!-- email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.email ? row.email : '--------'}}
                        </td>
                    </ng-container>
                    <!-- phone Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" class="uni"> {{row.phone ? row.phone : '--------'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="whatsappPhone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'whatsappPhone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" class="uni"> {{row?.whatsappPhone ? row.whatsappPhone : '--------'}} </td>
                    </ng-container>
                    <!-- city-->
                    <ng-container matColumnDef="city">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'city' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.city ? row.city?.name : '--------'}}</td>
                    </ng-container>
                    <!-- package -->
                    <ng-container matColumnDef="package">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'package' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.package ? row.package.package.name : '--------'}}</td>
                    </ng-container>
                    <!-- active -->
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'status' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-slide-toggle *ngIf='row.role !== "owner"' color="primary"
                                [checked]="row.active" (change)="activate(row, $event)">
                                <span class="green" [hidden]='row.active !== true'>{{'active' |
                                    translate}}</span>
                                <span class="red" [hidden]='row.active !== false'>{{'inActive' |
                                    translate}}</span>
                            </mat-slide-toggle>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.createdAt | date: 'short'}} </td>
                    </ng-container>
                    <!-- verified -->
                    <ng-container matColumnDef="verified">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'verified' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span>
                                <mat-slide-toggle color="primary" [checked]="row?.verified"
                                    (change)="changeVerified(row, $event)">
                                    <span class="green"
                                        [hidden]='row?.verified !== true'>{{'verified' |
                                        translate}}</span>
                                    <span class="red"
                                        [hidden]='row?.verified !== false'>{{'notVerified' |
                                        translate}}</span>
                                </mat-slide-toggle>
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pausePackage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'pausePackage' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <ng-container *ngIf="row.package">

                            
                            <mat-slide-toggle *ngIf='row.role !== "owner"' color="primary" [checked]="row?.package?.isPause" (change)="changePausePackage(row, $event)">
                                <span class="red" *ngIf='row?.package?.isPause'>{{'paused' | translate}}</span>
                                <span class="green" *ngIf='!row?.package?.isPause'>{{'not pause' | translate}}</span>
                            </mat-slide-toggle>
                        </ng-container>
                        <ng-container *ngIf="!row.package">
                            {{'--------'}}
                        </ng-container>
                        </td>
                    </ng-container>
                    <!-- add package -->
                    <ng-container matColumnDef="addPackage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          {{ "addPackage" | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                          <a (click)="openAddPackageModal(row._id)">
                            <i class="material-icons plus pointer">add</i>
                          </a>
                        </td>
                      </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" *ngIf='row.role !== "owner"'
                                (click)='edit(row._id)'>
                                edit
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="changePassword">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'changePassword' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i *ngIf='row.role !== "owner"' (click)='chagngePasswordDialog(row._id)'
                            class="material-icons pointer">security</i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i *ngIf='row.role !== "owner"' style="color: red;" (click)='confirmDialog(row._id)'
                                class="material-icons pointer">delete</i>
                            <!-- <span *ngIf='row.role !== "owner"' (click)='confirmDialog(row._id)'
                                class="pointer">{{'delete' | translate}}</span> -->
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getAllUsers($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>