import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { Category } from './../../../models/category';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-author-list',
  templateUrl: './author-list.component.html',
  styleUrls: ['./author-list.component.scss']
})
export class AuthorListComponent implements OnInit {

  pageEvent;
  activeForm: UntypedFormGroup;
  data: Category[] = [];

  // types is [ text, img, date, inside-url, new-url, boolean, innerHtml, object, translated-text ]
  displayedColumns = [
    'name',
    'email',
    'bio',
    'image',
    'status',
    'date',
    'edit',
  ];

  tabelKeys = [
    { label: 'name', key: 'name', type: 'text' },
    { label: 'email', key: 'email', type: 'text' },
    { label: 'bio', key: 'bio', type: 'text' },
    { label: 'image', key: 'image', type: 'img' },
    { label: 'status', key: 'status', type: 'boolean' },
    { label: 'date', key: 'createdAt', type: 'date' },
  ];
  dataSource: MatTableDataSource<Category>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image = [];

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private router: Router,
    public dialog: MatDialog
  ) {
    // this.getArticles(1);
  }

  ngOnInit() {
    this.activeForm = new UntypedFormGroup({
      status: new UntypedFormControl('', [])
    });
  }

  // getArticles(event: any) {
  //   this.full.getAllAuthors(event.pageIndex + 1 || 1, event.pageSize).subscribe((data: any) => {
  //     this.data = data.data;
  //     this.dataSource = new MatTableDataSource(this.data);
  //     this.length = data.itemCount;
  //   }, err => {
  //   });
  // }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openImage(image): void {
    this.image.push(image);
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: [...this.image]
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = [];
    });
  }

  activate(article: any, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      status: event.checked
    });

    this.full.changeStatusAuthor(article._id, this.activeForm.value).subscribe((data: any) => {
      article.status = event.checked;
      this.full.openSnackBar(data.message[0] || '', 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
