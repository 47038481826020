import { Router } from '@angular/router';
import { apiUrl } from './../models/BaseURL';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FullServiceService } from './full-service.service';
import { GlobalDataService } from '../app/services/global-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  readonly api = apiUrl;
  isAuth = false;
  userID = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private full: FullServiceService,
    private global: GlobalDataService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${this.api}/users/login`, { email, password })
      .pipe(map(user => {
        this.full.user = user.data.user;
        // login successful if there's a jwt token in the response and this account is verified
        if (user.data && user.token && user.data.user.active) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.isAuth = true;
          this.currentUserSubject.next(user);
        }
        return user;
      }));
  }

  checkJWTtoken() {
    return this.http.get(this.api + '/users/check').subscribe(
      (res: any) => {
        this.full.user = res.user.user;
        this.isAuth = true;
        // this.sendUsername(res.user.username);
      },
      err => {
        localStorage.removeItem('currentUser');
        this.router.navigateByUrl('/login');
        this.isAuth = false;
      }
    );
  }

  logout() {
    this.destroyUserCredentials();
  }

  destroyUserCredentials() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.isAuth = false;
    this.router.navigateByUrl('/login');
  }

  ifVerified() {
    const active = this.currentUserSubject.value.data.active;
    if (active) {
      return true;
    }
    return false;
  }

  ifOwner() {
    const role = this.currentUserSubject.value.data.user.role;
    if (role === 'owner') {
      return true;
    }
    return false;
  }

  ifAdmin() {
    const role = this.currentUserSubject.value.data.user.role;
    if (role === 'admin') {
      return true;
    }
    return false;
  }

  ifEditor() {
    const role = this.currentUserSubject.value.data.user.role;
    if (role === 'editor') {
      return true;
    }
    return false;
  }
  ifEmployee() {
    const role = this.currentUserSubject.value.data.user.role;
    if (role === 'employee') {
      return true;
    }
    return false;
  }

  ifUser() {
    const role = this.currentUserSubject.value.role;
    if (role === 'user' || role === 'admin') {
      return true;
    }
    return false;
  }

  ifNormalUser() {
    const role = this.currentUserSubject.value.role;
    if (role === 'user') {
      return true;
    }
    return false;
  }

  isLoggedIn(): boolean {
    return this.isAuth;
  }
}
