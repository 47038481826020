<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'lotSubscribers' | translate}}</h3>
                    </div>
                </div>

                <div class="row text-right">
                    <div class="col">
                        <p>الفائز في هذه القرعة هو: <b class='pointer'
                                (click)='edit(lotInfo?.winnerUser?._id)'>{{lotInfo?.winnerUser?.name}}</b></p>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="full-width">
                            <mat-label>{{'searchInUsers' | translate}}</mat-label>
                            <input matInput type="text" [(ngModel)]="searchWord" (input)='searchUsers()'>
                        </mat-form-field>
                    </div>
                </div> -->

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>

                    <!-- phone Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
                    </ng-container>

                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'code' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row?.lotLastCode}} </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" *ngIf='row.role !== "owner"' (click)='edit(row._id)'>
                                edit
                            </i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0" [length]="length"
                    [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = getAllUsers($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>