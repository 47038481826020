import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from '../../services/full-service.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-send-specific-noti',
  templateUrl: './send-specific-noti.component.html',
  styleUrls: ['./send-specific-noti.component.scss']
})
export class SendSpecificNotiComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private userService: UserService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<SendSpecificNotiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      titleAr: new UntypedFormControl('', [Validators.required]),
      bodyAr: new UntypedFormControl('', [Validators.required]),
      recivers: new UntypedFormControl([''], []),
    });
  }

  sendFcm() {
    this.form.patchValue({
      recivers: [this.data]
    })
    this.full.sendFcmForUser(this.form.value).subscribe(datqa => {
      this.toastr.successToastr(this.translate.instant('fcmSentSuccessfully'));
      this.dialogRef.close();
    }, err => {
      this.toastr.errorToastr(err);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
