<div class="container container-top-margin add-container">
  <div class="row">
    <div class="col">
      <div class="page-head">
        <div class="flex flex-centerd">
          <i class="uil uil-book-medical"></i>
          <div class="flex-column">
            <h4 class="h4-responsive text-center" *ngIf="!objectId">
              {{ "addNewLesson" | translate }}
            </h4>
            <h4 class="h4-responsive text-center" *ngIf="objectId">
              {{ "editLesson" | translate }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!(spinner$ | async)">
    <div class="col">
      <!-- <mat-card class="add-card">
        <form [formGroup]="form">
          <div class="progress form-group">
            <div
              class="progress-bar progress-bar-striped bg-success-bar"
              role="progressbar"
              [style.width.%]="progress"
            ></div>
          </div>
          <div class="row text-center">
            <div class="col">
              <h5 color="primary">
                <span>{{ "video" | translate }}</span>
              </h5>
              <label
                *ngIf="thumbnailFileList.length > 0"
                class="upload-label"
                for="upload-photo"
                appDrag
              >
                <video controls width="250">
                  <source [src]="thumbnailUrlList" type="video/webm" />

                  <source [src]="thumbnailUrlList" type="video/mp4" />

                  Download the
                  <a [href]="thumbnailUrlList">WEBM</a>
                  or
                  <a [href]="thumbnailUrlList">MP4</a>
                  video.
                </video>
                <i
                  class="material-icons pointer close-icon"
                  (click)="deleteUploadedVideo()"
                >
                  close
                </i>
              </label>
              <label
                *ngIf="thumbnailFileList.length === 0"
                class="upload-label"
                for="upload-thumbnail"
                appDrag
              >
                <img
                  class="pointer"
                  src="https://www.greatmats.com/images/placeholder-all.png"
                  width="80%"
                  alt=""
                />
                <input
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  (change)="videoSelectFile($event)"
                  name="my-video"
                  id="upload-thumbnail"
                />
              </label>
              <br />
              <br />
            </div>
          </div>
          <div class="row text-center">
            <div class="col-6 margin-auto">
              <mat-form-field class="full-width">
                <input
                  formControlName="nameEn"
                  matInput
                  placeholder="{{ 'nameEn' | translate }}"
                />
              </mat-form-field>
            </div>
            <div class="col-6 margin-auto">
              <mat-form-field class="full-width">
                <input
                  formControlName="nameFr"
                  matInput
                  placeholder="{{ 'nameFr' | translate }}"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-6 margin-auto">
              <mat-form-field class="full-width">
                <input
                  formControlName="nameAr"
                  matInput
                  placeholder="{{ 'nameAr' | translate }}"
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="full-width">
                <input
                  formControlName="index"
                  matInput
                  placeholder="{{ 'index' | translate }}"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row text-center">
            <div class="col">
              <mat-form-field class="full-width">
                <mat-label>{{ "lessonUnit" | translate }}</mat-label>
                <mat-select
                  placeholder="{{ '' | translate }}"
                  formControlName="unit"
                >
                  <mat-option *ngFor="let g of units" [value]="g._id">
                    {{ g.nameEn }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row text-center">
            <div class="col">
              <br />
              <br />
              <br />
              <button
                mat-raised-button
                (click)="addEdit()"
                [disabled]="!form.valid"
                color="primary"
              >
                <span *ngIf="!objectId">{{ "addNewLesson" | translate }}</span>
                <span *ngIf="objectId">{{ "editLesson" | translate }}</span>
              </button>
            </div>
          </div>
        </form>
      </mat-card> -->
      <mat-stepper orientation="vertical" [linear]="true" #stepper>
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>{{'selectMaterial' | translate}}</ng-template>
              <mat-form-field class="full-width">
                <mat-label>{{ "lessonsCat" | translate }}</mat-label>
                <mat-select placeholder="{{ '' | translate }}" formControlName="lessonsCat" >
                  <mat-option *ngFor="let g of lessonsCategories" [value]="g._id">
                    {{ g.nameEn }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            <div>
              <button mat-button matStepperNext (click)="getUnitsForLessonsCat()">{{'next' | translate}}</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="form">
          <mat-card class="add-card">
            <form [formGroup]="form">
              <div class="progress form-group">
                <div
                  class="progress-bar progress-bar-striped bg-success-bar"
                  role="progressbar"
                  [style.width.%]="progressV"
                ></div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <h5 color="primary">
                    <span>{{ "video" | translate }}</span>
                  </h5>
                  <label *ngIf="thumbnailFileListV.length > 0" class="upload-label" for="upload-photo" appDrag>
                    <video controls width="640px" height="267px" controls>
                    <!-- <video controls width="250"> -->
                      <!-- <source [src]="thumbnailUrlListV | secure | async" type="video/webm" /> -->
                      <!-- <source [src]="thumbnailUrlListV | secure | async" type="video/mp4" /> -->
                      <source src="{{thumbnailUrlListV}}" type="video/mp4"  />
                      <source src="{{thumbnailUrlListV}}" type="video/webm"  />
                        <!-- Download the<a [href]="thumbnailUrlList">WEBM</a>or<a [href]="thumbnailUrlList">MP4</a>video. -->
                    </video>
                    <i class="material-icons pointer close-icon" (click)="deleteUploadedVideo()"> close </i>
                    <!-- <i fileSaver [method]="'GET'" [fileName]="'videoName.mp4'" [url]="'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'" class="material-icons pointer download-icon" style="top:50%">download</i> -->
                    <!-- <button class="downlaod-video-button" type="button" fileSaver [method]="'GET'" [fileName]="'videoName.mp4'" [url]="thumbnailUrlListV">Download Video</button> -->
                  </label>
                  <label *ngIf="thumbnailFileListV.length === 0" class="upload-label" for="upload-thumbnail-mp4" appDrag >
                    <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                    <input type="file" class="upload-thumbnail-id" accept="video/mp4,video/x-m4v,video/*" (change)="videoSelectFile($event)" name="my-video" id="upload-thumbnail-mp4" />
                  </label>
                  <br />
                  <br />
                </div>
              </div>
              <div class="progress form-group">
                <div
                  class="progress-bar progress-bar-striped bg-success-bar"
                  role="progressbar"
                  [style.width.%]="progress"
                ></div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <h5 color="primary">
                    <span>{{ "image" | translate }}</span>
                  </h5>
                  <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag >
                    <!-- <img [src]="thumbnailUrlList" width="80%" alt="" class="pointer" /> -->
                    <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer" />
                    <i class="material-icons pointer close-icon" (click)="deleteUploadedThumbnailImage()"> close</i>
                  </label>
                  <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail" appDrag >
                    <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                    <input type="file" accept="image/*" (change)="thumbnailSelectFile($event)" name="photo" id="upload-thumbnail" />
                  </label>
                  <br />
                  <br />
                </div>
              </div>
              <div class="row text-center">
                <div class="col-6 margin-auto">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="nameEn"
                      matInput
                      placeholder="{{ 'nameEn' | translate }}"
                    />
                  </mat-form-field>
                </div>
                <div class="col-6 margin-auto">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="nameFr"
                      matInput
                      placeholder="{{ 'nameFr' | translate }}"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row text-center">
                <div class="col-6 margin-auto">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="nameAr"
                      matInput
                      placeholder="{{ 'nameAr' | translate }}"
                    />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="index"
                      matInput
                      placeholder="{{ 'index' | translate }}"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <mat-form-field class="full-width">
                    <mat-label>{{ "lessonUnit" | translate }}</mat-label>
                    <mat-select
                      placeholder="{{ '' | translate }}"
                      formControlName="unit"
                    >
                      <mat-option *ngFor="let g of units" [value]="g._id">
                        {{ g.nameEn }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <br />
                  <br />
                  <br />
                  <button
                    mat-raised-button
                    (click)="addEdit()"
                    [disabled]="!form.valid"
                    color="primary"
                  >
                    <span *ngIf="!objectId">{{ "addNewLesson" | translate }}</span>
                    <span *ngIf="objectId">{{ "editLesson" | translate }}</span>
                  </button>
                </div>
              </div>
            </form>
          </mat-card>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
