<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'deleteAccountRequests' | translate}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="full-width">
                            <mat-label>{{'searchIn' | translate}}</mat-label>
                            <input matInput type="text" [(ngModel)]="searchWord" (input)='searchRequests()'>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="full-width">
                            <mat-label>{{'status' | translate}}</mat-label>
                            <mat-select matNativeControl value="status" [(ngModel)]="status" (selectionChange)="searchRequests()">
                                <mat-option value="">{{'all' | translate}}</mat-option>
                                <mat-option value="pendding">{{'pendding' | translate}}</mat-option>
                                <mat-option value="accepted">{{'accepted' | translate}}</mat-option>
                                <mat-option value="rejected">{{'rejected' | translate}}</mat-option>
                              </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <table mat-table [dataSource]="dataSource" matSort>
                    <!-- name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row?.user?.name}} </td>
                    </ng-container>
                    <!-- email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.user.email ? row?.user?.email : '--------'}}
                        </td>
                    </ng-container>
                    <!-- phone Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' | translate}} </th>
                        <td mat-cell *matCellDef="let row" class="uni" style="cursor: alias;" (click)="goToLink(row.user.phone)"> {{row.user.phone ? row.user.phone : '--------'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="whatsappPhone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'whatsappPhone' | translate}} </th>
                        <td mat-cell *matCellDef="let row" class="uni" style="cursor: alias;" (click)="goToLink(row?.user?.whatsappPhone)"> {{row.user?.whatsappPhone ? row.user.whatsappPhone : '--------'}} </td>
                    </ng-container>
                    <!-- reson -->
                    <ng-container matColumnDef="reason">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'reason' | translate}} </th>
                        <td mat-cell *matCellDef="let row" class="uni"> {{row.reason ? row.reason : '--------'}} </td>
                    </ng-container>
                    <!-- active -->
                    <ng-container matColumnDef="proccessed">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'status' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <!-- <mat-slide-toggle *ngIf='row.role !== "owner"' color="primary"
                                [checked]="row.isProccessed" (change)="changeProccessed(row, $event)">
                                <span class="green" [hidden]='row.isProccessed !== true'>{{'proccessed' |
                                    translate}}</span>
                                <span class="red" [hidden]='row.isProccessed !== false'>{{'pending' |
                                    translate}}</span>
                            </mat-slide-toggle> -->
                            <!-- {{row.status}} -->
                            <mat-form-field [ngClass]="{'rejected': row.isAdminRejected, 'accepted': row.isAdminAccepted, 'pendding': row.status === 'pendding'}">
                                <mat-select matNativeControl [value]="row.status" [disabled]="row.isAdminAccepted || row.isAdminRejected" (selectionChange)="changeStatus(row, $event)">
                                  <mat-option value="accepted">{{'accepted' | translate}}</mat-option>
                                  <mat-option value="rejected">{{'rejected' | translate}}</mat-option>
                                  <mat-option value="pendding">{{'pendding' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <!-- verified -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getAllDeleteAccountRequests($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>