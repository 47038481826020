import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGuard implements CanActivate {

  constructor(
    private router: Router,
    private toastr: ToastrManager,
    private authenticationService: AuthService,
    private translate: TranslateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if ((currentUser.data.user.role.includes('owner') || currentUser.data.user.role.includes('admin') || currentUser.data.user.role.includes('editor') || currentUser.data.user.role.includes('employee')) && currentUser.data.user.active) {
      // this user is Admin
      return true;
    }

    this.toastr.warningToastr(this.translate.instant('dontHavePermission'));
    // not Admin ? redirect to home page with the return url
    this.router.navigate(['/articles'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
