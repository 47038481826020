import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { Category } from './../../../models/category';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MessageComponent } from '../../modals/message/message.component';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  activeForm: UntypedFormGroup;
  userFilter: UntypedFormGroup;
  filterForm: UntypedFormGroup;
  data: Category[] = [];
  users = [];
  usersSearch = '';

  displayedColumns: string[] = [
    'image', 'caption', 'status', 'show', 'edit',
    'delete'
  ];
  dataSource: MatTableDataSource<Category>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;
  pageEvent;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {
    this.userFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      role: new UntypedFormControl('user', []),
      deleted: new UntypedFormControl(false, []),
      active: new UntypedFormControl(true, []),
    });

    this.filterForm = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      minPrice: new UntypedFormControl(0, []),
      maxPrice: new UntypedFormControl(999999999999, []),
      promoted: new UntypedFormControl(null, []),
      verified: new UntypedFormControl(null, []),
      category: new UntypedFormControl(null, []),
      status: new UntypedFormControl(null, []),
      user: new UntypedFormControl('', []),
    });
    
    this.full.getAllUsersForDropDown(1, 50, this.usersSearch, this.userFilter.value)
    .subscribe((response: any) => {
      this.users = response.data;

    }, (error) => {
    });

    if(this.activatedRoute.snapshot.params.user) {
      this.filterByUser(this.activatedRoute.snapshot.params.user);
    } else {
      this.getSliders(1);
    }
   }

  ngOnInit() {
    this.activeForm = new UntypedFormGroup({
      status: new UntypedFormControl('', [])
    });
    // this.dataSource.sort = this.sort;
  }

  filterByUser(id: string) {
    this.filterForm.patchValue({
      user: id
    });
    this.router.navigate(['/gallery', { user: this.filterForm.get('user').value }]);
    this.getSliders(1)
  }

  getSliders(event: any) {
    this.full.getAllGallerys(event.pageIndex + 1 || 1, event.pageSize || 10, this.filterForm.get('user').value).subscribe((data: any) => {
      this.data = data.data;
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
      this.dataSource = new MatTableDataSource(this.data);
    }, err => {
    });
  }

  openImage(image: string): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: [image]
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  activate(category: any, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      status: event.checked
    });

    this.full.editOneGallery(category._id, this.activeForm.value).subscribe((data: any) => {
      category.status = event.checked;
      this.toastr.successToastr(data.message[0]);
    }, err => {
      this.toastr.errorToastr(err);
    });
  }

  delete(id) {
    this.full.deleteOneGallery(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar('تم حذف العمل بنجاح', 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar('حدث خطأ أثناء حذف العمل', 'أغلق', 'danger');
    });
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  changeSearch() {
    if (this.usersSearch) {
      this.userFilter.patchValue({
        searchWord: this.usersSearch,
        advertiserStatus: true
      })

      this.full.getAllUsersForDropDown(1, 10, this.usersSearch, this.userFilter.value)
        .subscribe((response: any) => {
          this.users = response.data;

        }, (error) => {
        });
    }
  }

  openMessage(message): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '650px',
      data: message
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
