import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LessCat } from '../../../models/category';

@Component({
  selector: 'app-add-city',
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.scss']
})
export class AddCityComponent implements OnInit {
  form: UntypedFormGroup;
  objectId: string;
  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList2= [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  coverFileList: any = [];
  coverUrlList: string;
  coverUrlAfterUpload: any;
  coverList: File = null;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private snackBar: MatSnackBar,
    private activateRouter: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      nameAr: new UntypedFormControl('', [Validators.required]),
      nameEn: new UntypedFormControl('', [Validators.required]),
      nameFr: new UntypedFormControl('', [Validators.required]),
    });

    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneCity(this.objectId).subscribe((data: any) => {
      this.form.patchValue(data.data);
      this.thumbnailUrlList2 = data.data.files;
      this.thumbnailFileList = data.data.file;
      this.thumbnailUrlList = data.data.file;
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  coverSelectFile(event) {
    this.coverFileList = [];
    this.coverList = event.target.files[0];
    this.coverFileList.push(this.coverList);
    this.coverUpload();
  }

  coverUpload() {
    let fd = new FormData();

    fd.append('images', this.coverFileList[0], this.coverFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            coverImage: event.body.images[0]
          });
          this.coverUrlList = event.body.images[0];
      }
    }, err => {
      this.coverUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedCoverImage() {
    this.form.patchValue({
      coverImage: null
    });
    this.progress = 0;
    this.coverFileList = [];
  }


  addEdit() {
    if (!this.form.valid) {
      return false;
    }
    if (!this.objectId) {
      this.full.addCity(this.form.value).subscribe((data: any) => {
        this.router.navigateByUrl('/cities');
        this.full.openSnackBar(data.message || '', 'أغلق', 'success');
      }, err => {
        this.full.openSnackBar(err || '', 'أغلق', 'danger');
      });
    } else {
      this.full.editOneCity(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.router.navigateByUrl('/cities');
          this.full.openSnackBar(response.message || '', 'أغلق', 'success');
        }, (error) => {
          this.full.openSnackBar(error || '', 'أغلق', 'danger');
        });
    }
  }


  filesSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);    
    this.fileUpload();
  }

  fileUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          // this.form.get('files').setValue([...this.form.get('files').value, {name: this.thumbnailFileList[0].name, url:event.body.images[0]}])
          // this.thumbnailUrlList2.push({name:this.thumbnailFileList[0].name, url: event.body.images[0]});
          this.form.patchValue({
            file: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  // deleteUploadedFiles(file) {
  //   const files = this.form.get('files').value;
  //   const newFiles = files.filter(e=> e.url !== file);
  //   this.form.get('files').setValue(newFiles);
  //   // this.form.patchValue({
  //   //   image: null
  //   // });
  //   // this.progress = 0;
  //   if(!newFiles.length){
  //     this.progress = 0;
  //   }
  //   this.thumbnailUrlList2 = newFiles;
  // }
  deleteUploadedFiles(file) {
    this.form.patchValue({
      file: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

}
