import { apiUrl } from './../../../models/BaseURL';
import * as io from 'socket.io-client';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as $ from 'jquery';


@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {
  messages = [];
  form: UntypedFormGroup;
  userForm: UntypedFormGroup;
  private socket;

  constructor(
    private fullService: FullServiceService,
    public dialogRef: MatDialogRef<ConversationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.socket = io(apiUrl);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      content: new UntypedFormControl('', [Validators.required]),
      userChat: new UntypedFormControl('', [Validators.required]),
      admin: new UntypedFormControl(true, [Validators.required]),
      isRead: new UntypedFormControl(true, [Validators.required]),
    });

    this.userForm = new UntypedFormGroup({
      user: new UntypedFormControl('', [Validators.required])
    });

    this.socket.on('refreshMessages', () => {
      this.getAllMessages();
    });
    this.getAllMessages();

    setTimeout(() => {
      $('#scrollMe').scrollTop($('#scrollMe')[0].scrollHeight);
    }, 500);
  }

  sendMessage() {
    this.form.patchValue({
      userChat: this.messages[0].user,
      admin: true,
      isRead: true
    });

    if (!this.form.valid) {
      return false;
    }

    this.messages.push({
      admin: true,
      createdAt: Date.now(),
      user: this.messages[0].user,
      content: this.form.get('content').value,
      isRead: true
    });

    setTimeout(() => {
      $('#scrollMe').scrollTop($('#scrollMe')[0].scrollHeight);
    }, 100);

    this.fullService.sendMessage(this.form.value)
      .subscribe(data => {
        this.socket.emit('sendMessagePanel', {});
        this.form.get('content').reset();
      }, err => {
      });
  }

  getAllMessages() {
    this.userForm.patchValue({
      user: this.data.chat[0].user
    });

    this.fullService.getAllUserChats(this.userForm.value)
      .subscribe((data: any) => {
        this.messages = data.data.chat;
        setTimeout(() => {
          $('#scrollMe').scrollTop($('#scrollMe')[0].scrollHeight);
        }, 100);
      }, err => {
      });
  }

}
