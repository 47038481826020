<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'featureList' | translate}}</h3>
                    </div>
                </div>
                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-special')">{{'addNewFeature' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'image' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <img [src]="row.image | secure | async" width="50px" alt=""
                                (click)="openImage(row.appImage)">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer"
                                routerLink='/edit-special/{{row._id}}'>
                                edit
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" (click)="confirmDialog(row._id)">
                                delete
                            </i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getData($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>