<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center">{{ id ? ('editClientAddress' |
                            translate) : ('addClientAddress' | translate) }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-center">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'client' | translate}}
                                </mat-label>
                                <mat-select placeholder="{{ '' | translate }}"
                                    formControlName="client">
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="clientSearch"
                                            [ngModelOptions]="{ standalone: true }"
                                            [placeholderLabel]="''"
                                            (ngModelChange)="changeLinkedUsersSearch()">
                                            <mat-icon ngxMatSelectSearchClear>delete
                                            </mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let g of linkedUsers" [value]="g._id">
                                        {{ g.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'city' | translate}}</mat-label>
                                <mat-select formControlName="city"
                                    (selectionChange)="getStatesForCity($event.value)">
                                    <mat-option *ngFor="let c of cities" [value]="c._id">
                                        {{c.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'state' | translate}}</mat-label>
                                <mat-select placeholder="{{ '' | translate }}"
                                    formControlName="state">
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="stateSearchWord"
                                            noEntriesFoundLabel="{{'noResults' | translate}}"
                                            [ngModelOptions]="{ standalone: true }"
                                            [placeholderLabel]="''"
                                            (ngModelChange)="changeStatesSearch()">
                                            <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let g of states" [value]="g._id">
                                        {{ g.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row max-width-row">
                        <div class="col">
                            <div class="example-container">
                                <mat-form-field class="example-full-width">
                                    <span>{{ 'address' | translate }}</span>
                                    <textarea rows="5" matInput
                                        formControlName="address"> </textarea>
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="valid('address', 'required')">{{
                                        'required' | translate }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col">
                        <div class="row text-center max-width-row">
                            <div class="col">
                                <button mat-raised-button color="warn" (click)="addOrEdit()"
                                    [disabled]="!form.valid">
                                    {{ id ? ('editClientAddress' | translate) : ('addClientAddress'
                                    | translate) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>