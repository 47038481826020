<mat-dialog-content class="mat-typography">
    <div class="container-fluid">
        <div class="row text-center">
            <div class="col">
                <h2 class="h2-responsive">سجلّ الرسائل</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="userMessage">{{message?.message}}</p>
            </div>
        </div>
        <div class="row" *ngFor="let m of chatHistory">
            <div class="col">
                <p
                    [ngClass]="{'adminMessage': m.admin === true, 'userMessage' : m.admin === false }">
                    {{m?.message}}</p>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-10">
                <form [formGroup]="form">
                    <mat-form-field class="full-width" style="text-align: right;">
                        <textarea formControlName='message' type="text" matInput
                            placeholder="{{'message' | translate}}"></textarea>
                    </mat-form-field>
                </form>
            </div>
            <div class="col-2">
                <button mat-raised-button (click)="send()" [disabled]='!form.valid' color="primary">
                    <span>{{'send' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</mat-dialog-content>