import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category } from '../../classes/Category';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent implements OnInit {
  products = [];
  categories: Category[];
  searchWord = '';
  form: FormGroup;

  objectId: string;

  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  appFileList = [];
  appUrlList: string;
  appUrlAfterUpload: any;
  appList: File = null;

  filterForm: FormGroup = new FormGroup({
    searchWord: new FormControl('', []),
    minPrice: new FormControl(0, []),
    maxPrice: new FormControl(999999999999, []),
    promoted: new FormControl(null, []),
    verified: new FormControl(null, []),
    category: new FormControl(null, []),
    user: new FormControl(null, []),
  });

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      iban: new FormControl('', [Validators.required]),
      bankName: new FormControl('', [Validators.required]),
    });

    this.objectId = this.activateRouter.snapshot.params.id;

    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneBank(this.objectId).subscribe((data: any) => {
      const s = data.data;
      this.form.patchValue(s);
    }, err => {
      this.snackBar.open(err);
    });
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      this.full.addBank(this.form.value).subscribe((data: any) => {
        this.toastr.successToastr('تمت إضافة البنك بنجاح');
        this.router.navigateByUrl('/bank-list');
      }, err => {
        this.toastr.errorToastr(err);
      });
    } else {
      this.full.editOneBank(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.toastr.successToastr('حدث خطأ أثناء إضافة البنك');
          this.router.navigateByUrl('/bank-list');
        }, (error) => {
          this.toastr.errorToastr(error);
        });
    }
  }
}
