import { apiUrl } from './../../../models/BaseURL';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormGroup } from '@angular/forms';
import { FullServiceService } from './../../../services/full-service.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileHandle } from './../../directives/dragDrop.directive';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
  fileList: Array<File> = [];
  list: File = null;
  progress = 0;
  urlList: string;
  files: FileHandle[] = [];
  form: UntypedFormGroup;
  downloadLink = false;
  readonly api = apiUrl;
  disabledBtn = true;

  displayedColumns: string[] = [
    'name', 'type', 'size', 'downloadLink', 'delete'
  ];

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private full: FullServiceService
  ) { }

  ngOnInit() {
  }


  selectFile(event) {
    if (event.target.files.length > 12) {
      this.toastr.errorToastr(this.translae.instant('youCanUploadOnly12FileEveryTime'));
      return false;
    }

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < event.target.files.length; i++) {
      this.list = event.target.files[i];
      this.fileList.push(this.list);
      this.disabledBtn = false;
    }
  }

  upload() {
    const fd = new FormData();
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.fileList.length; i++) {
      fd.append('files', this.fileList[i], this.fileList[i].name);
      this.full.uploadFiles(fd).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
            this.fileList = event.body.fileData;
            this.downloadLink = true;
            this.disabledBtn = true;
        }
      }, err => {
      });
    }

    this.toastr.successToastr(this.translae.instant('filesUploadedSuccessfully'));

  }

  deleteUploadedImage() {
    this.progress = 0;
    this.fileList = [];
  }

  filesDropped(files: FileHandle[]): void {
    // this.urlAfterUpload = files[0].url;
    this.fileList[0] = files[0].file;
  }

}
