import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { Category } from './../../../models/category';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

export interface CategoryImage {
  image: string;
}

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})
export class OffersListComponent implements OnInit {

  activeForm: UntypedFormGroup;
  data: Category[] = [];

  displayedColumns: string[] = [
    'title', 'startDate', 'endDate', 'image',
    'edit', 'delete'
  ];
  dataSource: MatTableDataSource<Category>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    public dialog: MatDialog
  ) { this.getData(); }

  ngOnInit() {
    this.activeForm = new UntypedFormGroup({
      status: new UntypedFormControl('', [])
    });
    // this.dataSource.sort = this.sort;
    this.getData();
  }

  getData() {
    this.full.getAllOffers().subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openImage(image: string): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: { image }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  // activate(category: any, event: MatSlideToggleChange) {
  //   this.activeForm.patchValue({
  //     status: event.checked
  //   });

  //   this.full.changeStatusOffer(category._id, this.activeForm.value).subscribe((data: any) => {
  //     category.status = event.checked;
  //     this.toastr.successToastr(data.message[0]);
  //   }, err => {
  //     this.toastr.errorToastr(err);
  //   });
  // }

  delete(id: string) {
    this.full.deleteOneOffer(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.toastr.successToastr(data.message[0]);
    }, err => {
      this.toastr.errorToastr(err);
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
