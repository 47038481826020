import { AuthService } from "./../../services/auth.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { ToastrManager } from "ng6-toastr-notifications";
import { GlobalDataService } from "../services/global-data.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private global: GlobalDataService,
    private authenticationService: AuthService,
    private toastr: ToastrManager
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    console.log('asssssssssssssssssss');
    
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          if (this.auth.ifAdmin() || this.auth.ifOwner()) {
            this.router.navigateByUrl("/dashboard");
            this.global.links = [
              {
                label: "home",
                role: "admin",
                subLinks: [{ link: "/dashboard", label: "home" }],
              },
              {
                label: "sliderManagement",
                role: "owner",
                subLinks: [
                  { link: "/slider-list", label: "sliderList" },
                  { link: "/add-slider", label: "addSlider" },
                ],
              },

              {
                label: "faq",
                role: "owner",
                subLinks: [{ link: "/faq", label: "faq" }],
              },

              {
                label: "userManagement",
                role: "owner",
                subLinks: [
                  { link: "/users-list", label: "usersList" },
                  { link: "/add-user", label: "addUser" },
                  { link: "/gallery", label: "GalleryList" },
                ],
              },
              {
                label: "contactsManagement",
                role: "owner",
                subLinks: [{ link: "/contacts", label: "contacts" }],
              },
              {
                label: "notificationsManagement",
                role: "owner",
                subLinks: [{ link: "/send-fcm", label: "sendFcm" }],
              },
              {
                label: "logsManagement",
                role: "owner",
                subLinks: [{ link: "/logs", label: "logs" }],
              },
            ];
          } else if (this.auth.ifEditor()) {
            this.router.navigateByUrl("/users-list");
            this.global.links = [
              {
                label: "userManagement",
                role: "owner",
                subLinks: [
                  { link: "/users-list", label: "usersList" },
                  { link: "/add-user", label: "addUser" },
                  { link: "/gallery", label: "GalleryList" },
                ],
              },
            ];
          } else if (this.auth.ifEmployee()) {
          this.router.navigateByUrl("/users-list");
          this.global.links = [
            {
              label: "userManagement",
              role: "owner",
              subLinks: [
                { link: "/users-list", label: "usersList" },
                { link: "/add-user", label: "addUser" },
                { link: "/gallery", label: "GalleryList" },
              ],
            },
          ];
        } else {
            this.router.navigateByUrl("/login");
            this.toastr.errorToastr("لا يمكنك القيام بهذا الإجراء");
            this.auth.logout();
        }
        },
        (error) => {
          this.toastr.errorToastr(error);
          this.loading = false;
        }
      );
  }
}
