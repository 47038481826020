import { Component, Inject, OnInit } from '@angular/core';
import { FullServiceService } from '../../../services/full-service.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-transfer-detailes',
  templateUrl: './transfer-detailes.component.html',
  styleUrls: ['./transfer-detailes.component.scss']
})
export class TransferDetailesComponent implements OnInit {
  order;

  constructor(
    private full: FullServiceService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TransferDetailesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.order = this.data;
  }

  editOrder(status: string) {
    let body = {
      status: status
    }
    this.full.editTransfer(this.order._id, body).subscribe(data => {
      this.full.openSnackBar('تم تعديل الحالة بنجاح', 'أغلق', 'success');
      this.dialog.closeAll();
    }, err => {
      this.full.openSnackBar('اغلق', err, 'danger');
    });
  }
}
