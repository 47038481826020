<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'offerList' | translate}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col flex">
                        <mat-form-field class="filter-input">
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                        </mat-form-field>
                        <div class="spacer"></div>

                        <div class="add-circle pointer" color='primary' (click)="goTo('add-offer')">
                            <i class="material-icons" color='secondary'>
                                add
                            </i>
                        </div>
                    </div>
                </div>

                <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'title' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.title}} </td>
                        </ng-container>

                        <ng-container matColumnDef="startDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'startDate' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.startDate | date:'short'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="endDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'endDate' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.endDate | date:'short'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'image' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer" (click)="openImage(row.offerImage)">
                                    visibility
                                </i>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer" routerLink='/edit-offer/{{row._id}}'>
                                    edit
                                </i>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer" (click)="delete(row._id)">
                                    delete
                                </i>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>
            </mat-card>
        </div>
    </div>
</div>