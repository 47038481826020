import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { FullServiceService } from '../../services/full-service.service';

@Component({
  selector: 'app-lot-subscribers-list',
  templateUrl: './lot-subscribers-list.component.html',
  styleUrls: ['./lot-subscribers-list.component.scss']
})
export class LotSubscribersListComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  verifiedForm: UntypedFormGroup;
  promotedForm: UntypedFormGroup;
  data: User[] = [];
  searchWord = '';
  lotInfo;

  cities = [];
  states = [];

  userFilter: UntypedFormGroup;

  displayedColumns: string[] = ['name', 'phone', 'code', 'edit'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;
  // currentLang = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private toastr: ToastrManager,
    public dialog: MatDialog,
    private full: FullServiceService,
  ) {
  }

  ngOnInit() {
    this.userFilter = new UntypedFormGroup({
      active: new UntypedFormControl(null, []),
      promoted: new UntypedFormControl(null, []),
      verified: new UntypedFormControl(null, []),
      role: new UntypedFormControl(null, []),
      city: new UntypedFormControl(null, []),
      state: new UntypedFormControl(null, []),
      advertiserStatus: new UntypedFormControl(null, []),
    })

    // this.activeForm = new FormGroup({
    //   active: new FormControl('', [])
    // });

    // this.verifiedForm = new FormGroup({
    //   verified: new FormControl('', [])
    // });
    // this.promotedForm = new FormGroup({
    //   promoted: new FormControl('', [])
    // });

    this.getAllUsers(1);
    this.getLotInfo();
    // this.full.getAllParentCities().subscribe((data: any) => {
    //   this.cities = data.data;
    // })
  }

  getAll() {
    this.userFilter.patchValue({
      role: null,
      active: null,
      promoted: null,
      verified: null,
      city: null,
      state: null,
      advertiserStatus: null
    })
    this.getAllUsers(1);
    this.getLotInfo()
  }

  edit(id) {
    let url = window.location.href.replace('lot-list-subs/61a0902769ff54d3c80f2e87', `edit-user/${id}`);
    window.open(url, '_blank')
  }

  getLotInfo() {
    this.full.getOneLot(this.activatedRoute.snapshot.params.id).subscribe((data: any) => {
      this.lotInfo = data.data;
    })
  }

  getAllUsers(event: any) {
    this.full.getAllLotSubs(event.pageIndex + 1 || 1, event.pageSize, this.activatedRoute.snapshot.params.id).subscribe((data: any) => {
      this.data = data.data;

      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  filter() {
    this.getAllUsers(1);
  }

  searchUsers() {
    this.getAllUsers(1);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
