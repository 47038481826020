import { FullServiceService } from './../../services/full-service.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-account-orders',
  templateUrl: './account-orders.component.html',
  styleUrls: ['./account-orders.component.scss']
})
export class AccountOrdersComponent implements OnInit {

  activeForm: UntypedFormGroup;
  data: any[] = [];
  pageEvent;
  displayedColumns: string[] = ['fullName', 'email', 'phone', 'country', 'accountType', 'message', 'date', 'delete'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) { this.getAllContacts(1); }

  ngOnInit() {
    this.activeForm = new UntypedFormGroup({
      fullName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.required]),
      message: new UntypedFormControl('', [Validators.required]),
    });
    // this.dataSource.sort = this.sort;
  }

  getAllContacts(event: any) {
    this.full.getAllAccountOrders(event.pageIndex + 1 || 1, event.pageSize).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      this.length = data.itemCount;
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.full.deleteOneAccountOrder(id)
      .subscribe((response) => {
        this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
        this.toastr.successToastr(this.translate.instant('hasBeenDeleted'));
      }, (error) => {
        this.toastr.errorToastr(error);
      });
  }

}
