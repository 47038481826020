<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'contacts' | translate}}</h3>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- fullName Column -->
                    <ng-container matColumnDef="fullName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'fullName' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>

                    <!-- email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                    </ng-container>

                    <!-- phone Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
                    </ng-container>

                    <!-- Message Column -->
                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'message' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" [title]="row.message" class="pointer">
                            {{row.message| slice:0:30}} </td>
                    </ng-container>

                    <ng-container matColumnDef="show">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'show' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" (click)='openMessage(row)'>
                                visibility
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.createdAt| date:'short'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span (click)='confirmDialog(row._id)' class="pointer"
                                style="font-weight: bold;">
                                {{'delete' | translate}}
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getAllData($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>