<h1 mat-dialog-title class="text-right">{{ "addPackageToUser" | translate }}</h1>
<div mat-dialog-content>
    <div class="container container-top-margin add-container" style="box-shadow: none">
      <div class="row">
        <div class="col">
          <mat-card class="add-card">
            <form [formGroup]="form">
              <div class="row text-center">
                <div class="col-12 margin-auto">
                    <mat-form-field class="full-width">
                        <mat-label> {{'package' | translate}}</mat-label>
                        <mat-select formControlName='package'>
                            <mat-option *ngFor='let package of packages' [value]='package._id'> {{ package?.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                  <!-- <mat-form-field class="full-width">
                    <input formControlName="package" matInput placeholder="{{ 'package' | translate }}" />
                  </mat-form-field> -->
                </div>
              </div>
              <div class="row text-center">
                <div class="col-12 margin-auto">
                  <mat-form-field class="full-width">
                    <input formControlName="discountCode" matInput placeholder="{{ 'discountCode' | translate }}" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row text-center">
                <div class="progress form-group">
                    <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progress"></div>
                </div>
                <!-- <div class="row text-center"> -->
                <!-- <div class="col"> -->
                    <h5 color="primary">
                    <span>{{ "image" | translate }}</span>
                    </h5>
                    <label
                    *ngIf="thumbnailFileList.length > 0"
                    class="upload-label"
                    for="upload-photo"
                    appDrag
                    >
                    <img
                        [src]="thumbnailUrlList | secure | async"
                        width="50%"
                        alt=""
                        class="pointer"
                    />
                    <i
                        class="material-icons pointer close-icon"
                        (click)="deleteUploadedThumbnailImage()"
                    >
                        close
                    </i>
                    </label>
                    <label
                    *ngIf="thumbnailFileList.length === 0"
                    class="upload-label"
                    for="upload-thumbnail"
                    appDrag
                    >
                    <img
                        class="pointer"
                        src="https://www.greatmats.com/images/placeholder-all.png"
                        width="80%"
                        alt=""
                    />
                    <input
                        type="file"
                        (change)="thumbnailSelectFile($event)"
                        accept="image/*"
                        name="photo"
                        id="upload-thumbnail"
                    />
                    </label>
                    <br />
                    <br />
                <!-- </div> -->
                <!-- </div> -->
              </div>
              <div class="row text-center">
                <div class="col-12">
                  <button mat-raised-button (click)="addPackageToUser()" [disabled]="!form.valid" color="primary" >
                    <span>{{ "addPackageToUser" | translate }}</span>
                  </button>
                </div>
              </div>
            </form>
          </mat-card>
        </div>
      </div>
    </div>
  </div>