import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from '../../../services/full-service.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-delete-account-requests',
  templateUrl: './delete-account-requests.component.html',
  styleUrls: ['./delete-account-requests.component.scss']
})
export class DeleteAccountRequestsComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  verifiedForm: UntypedFormGroup;
  cityForm: UntypedFormGroup;
  promotedForm: UntypedFormGroup;
  data: any[] = [];
  searchWord = '';
  status = 'pendding';

  cities = [];
  states = [];

  userFilter: UntypedFormGroup;

  displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'whatsappPhone',
    'reason',
    'proccessed'
  ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // MatPaginator Inputs
  length: number;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrManager,
    public dialog: MatDialog,
    private full: FullServiceService,
  ) {
  }

  ngOnInit() {
    this.activeForm = new UntypedFormGroup({
      isAdminAccepted: new UntypedFormControl(null, []),
      isAdminRejected: new UntypedFormControl(null, [])
    });
    this.getAllDeleteAccountRequests(1);
  }

  getAllDeleteAccountRequests(event: any) {
    this.userService.getAllDeleteAccountRequestsForAdmin(event.pageIndex + 1 || 1, event.pageSize, this.searchWord, this.status).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  searchRequests() {
    this.getAllDeleteAccountRequests(1);
  }
  changeStatus(request, event){
    if(event.value === 'pendding'){
      return
    }
    const requestId = request._id;
    const isAdminAccepted = event.value === 'accepted';
    const isAdminRejected = event.value === 'rejected';
    const body = {
      isAdminAccepted,
      isAdminRejected
    }
    this.userService.changeStatusDeleteAccountRequest(requestId, body).subscribe((data: any) => {
      request.isAdminAccepted = isAdminAccepted;
      request.isAdminRejected = isAdminRejected;
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }
  changeProccessed(request: any, event: any) {
    console.log('request', request);
    console.log('request', event);
    // this.activeForm.patchValue({
    //   isAdminAccepted: event.checked,
    //   isAdminRejected: !event.checked
    // });

    // this.userService.changeStatusDeleteAccountRequest(request._id, this.activeForm.value).subscribe((data: any) => {
    //   request.isAdminAccepted = event.checked;
    //   request.isAdminRejected = !event.checked;
    //   this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    // }, err => {
    //   this.full.openSnackBar(err || '', 'أغلق', 'danger');
    // });
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.full.openSnackBar(this.translate.instant('copyCompletedSuccessfully'), 'أغلق', 'success');
  }
  goToLink(number: string){
    window.open(`https://api.whatsapp.com/send?phone=${number}`, "_blank");
  }
}
