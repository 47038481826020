<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'categories' | translate}}</h3>
                    </div>
                </div>
                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-category')">{{'addNewCategory' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <button mat-raised-button color="primary"
                            (click)="getCategories(1)">{{'showAll' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-12">
                        <mat-form-field class="example-form-field">
                            <mat-label>{{'searchInCats' | translate}}</mat-label>
                            <input matInput type="text" [(ngModel)]="searchWord" (input)='search()'>
                            <button *ngIf="searchWord" matSuffix mat-icon-button aria-label="Clear"
                                (click)="searchWord=''">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>

                <div class="catss">
                    <table>
                        <tr>
                            <th>{{'image' | translate}}</th>
                            <th>{{'name' | translate}}</th>
                            <!-- <th>{{'id' | translate}}</th> -->
                            <th>{{'index' | translate}}</th>
                            <th>{{'edit' | translate}}</th>
                            <th>{{'delete' | translate}}</th>
                        </tr>
                        <br>
                    </table>
                    <mat-accordion *ngFor='let c of data'>
                        <mat-expansion-panel (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <table>
                                    <!-- displayedColumns: string[] = ['image', 'name', 'parent', 'subs', 'edit', 'delete']; -->

                                    <tr>
                                        <td>
                                            <a href="{{c.image}}" target="_blank">
                                                <img src="{{c.image}}" width="60px" alt="">
                                            </a>
                                        </td>
                                        <td>
                                            {{c.name}}
                                        </td>
                                        <!-- <td>
                                            {{c._id}}
                                        </td> -->
                                        <td>
                                            {{c.index}}
                                        </td>
                                        <td>
                                            <i class="material-icons pointer" (click)='edit(c._id)'>
                                                edit
                                            </i>
                                        </td>
                                        <td>
                                            <span (click)='confirmDialog(c)'
                                                class="pointer">{{'delete' |
                                                translate}}</span>
                                        </td>
                                    </tr>
                                </table>
                            </mat-expansion-panel-header>
                            <table class="childs-table">
                                <!-- displayedColumns: string[] = ['image', 'name', 'parent', 'subs', 'edit', 'delete']; -->

                                <tr *ngFor='let ch of c.childs'>
                                    <td>
                                        <a href="{{ch.image}}" target="_blank">
                                            <img src="{{ch.image}}" width="60px" alt="">
                                        </a>
                                    </td>
                                    <td>
                                        {{ch.nameAr}}
                                    </td>
                                    <td>
                                        {{ch._id}}
                                    </td>
                                    <td>
                                        {{ch.index}}
                                    </td>
                                    <td>
                                        <i class="material-icons pointer" (click)='edit(ch._id)'>
                                            edit
                                        </i>
                                    </td>
                                    <td>
                                        <span (click)='confirmDialog(ch)' class="pointer">{{'delete'
                                            |
                                            translate}}</span>
                                    </td>
                                </tr>
                            </table>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <!-- <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'image' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <a href="{{row.image}}" target="_blank">
                                    <img src="{{row.image}}" width="60px" alt="">
                                </a>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="parent">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'catType' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <span [hidden]='!row.isCategory'>{{'parent' | translate}}</span>
                                <span [hidden]='row.isCategory'>{{'sub' | translate}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="subs">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'subs' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <span (click)='showSubsForCat(row._id)' class="pointer">
                                    <i class="material-icons pointer">
                                        visibility
                                    </i>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer" routerLink='/edit-category/{{row._id}}'>
                                    edit
                                </i>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <span (click)='confirmDialog(row._id)' class="pointer">{{'delete' | translate}}</span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table> -->

                    <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                        [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="pageEvent = getCategories($event)"></mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>