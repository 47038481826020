import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ImageComponent } from '../modals/image/image.component';
import { FullServiceService } from '../../services/full-service.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';

export interface CategoryImage {
  image: string;
}

@Component({
  selector: 'app-lot-list',
  templateUrl: './lot-list.component.html',
  styleUrls: ['./lot-list.component.scss']
})
export class LotListComponent implements OnInit {
  data: any[] = [];

  displayedColumns: string[] = [
    'image', 'disImg', 'title', 'drawDate', 'subs', 'edit',
    'delete'
  ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) { this.getSliders(); }

  ngOnInit() {

  }

  getSliders() {
    this.full.getAllLots().subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  extractTimeFromDate(date: Date) {
    var event = new Date(date);
    return event.toLocaleTimeString('en-US')
  }

  openImage(image: string): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: [image]
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  delete(id) {
    this.full.deleteOneLot(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar('تم حذف القرعة بنجاح', 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar('حدث خطأ أثناء حذف القرعة', 'أغلق', 'danger');
    });
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
