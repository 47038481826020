import { User } from "./../../../models/user";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { CustomValidators } from "ng2-validation";
import { HttpEvent, HttpEventType } from "@angular/common/http";

import { UserService } from "../../../services/user.service";
import { FullServiceService } from "../../../services/full-service.service";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  form: UntypedFormGroup;
  passForm: UntypedFormGroup;
  cats = [];
  states = [];
  // specials = [];
  // discounts = [];
  user;
  // cities;
  userData;

  catssearchWord = "";
  // stateSearchWord = '';
  // cityId = '';

  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  coverFileList = [];
  coverUrlList: string;
  coverUrlAfterUpload: any;
  coverList: File = null;

  objectId: string;
  roles = ["owner", "admin", "editor", "user"];
  constructor(
    private userService: UserService,
    private router: Router,
    private translae: TranslateService,
    public dialog: MatDialog,
    private activateRouter: ActivatedRoute,
    private full: FullServiceService
  ) {
    this.getCategoriesNames();
    // this.getCitiesNames();

    const newPassword = new UntypedFormControl("", [Validators.required]);
    const passwordConfirm = new UntypedFormControl("", [
      CustomValidators.equalTo(newPassword),
    ]);

    this.passForm = new UntypedFormGroup({
      newPassword,
      passwordConfirm,
    });
  }

  ngOnInit() {
    const password = new UntypedFormControl(null, [Validators.required]);
    const passwordConfirm = new UntypedFormControl(null, [
      CustomValidators.equalTo(password),
    ]);
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl("", [Validators.required]),
      image: new UntypedFormControl("", []),
      // long: new FormControl('', []),
      // lat: new FormControl('', []),
      cats: new UntypedFormControl([], []),
      // specials: new FormControl(null, []),
      // discounts: new FormControl(null, []),
      cover: new UntypedFormControl("", []),
      gender: new UntypedFormControl("1", [Validators.required]),
      // address: new FormControl('', [Validators.required]),
      birthDate: new UntypedFormControl("", []),
      phone: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[+]*[s./0-9]{3,}$"),
      ]),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}"),
      ]),
      location: new UntypedFormControl({
        coordinates: new UntypedFormControl([], []),
      }),
      sequrityQuetions: new UntypedFormControl([
        {
          quetion: new UntypedFormControl([], []),
          answer: new UntypedFormControl([], []),
        },
      ]),
      password,
      passwordConfirm,
      role: new UntypedFormControl("admin", [Validators.required]),
      quetion: new UntypedFormControl("", [Validators.required]),
      answer: new UntypedFormControl("", [Validators.required]),
      // city: new FormControl('', [Validators.required]),
      // state: new FormControl('', [Validators.required]),
      active: new UntypedFormControl("true", [Validators.required]),
      advertiserStatus: new UntypedFormControl("true", [Validators.required]),
      promoted: new UntypedFormControl("false", [Validators.required]),
      verified: new UntypedFormControl("false", [Validators.required]),
      bio: new UntypedFormControl("", []),
      galleryMaxCount: new UntypedFormControl("50", [Validators.required]),
      facebook: new UntypedFormControl("", []),
      instagram: new UntypedFormControl("", []),
      tiktok: new UntypedFormControl("", [Validators.required]),
      youtube: new UntypedFormControl("", []),
      // hourFrom: new FormControl('', []),
      // hourTo: new FormControl('', []),
      // hourFrom1: new FormControl('', []),
      // hourTo1: new FormControl('', []),
      // alwaysOpen: new FormControl(false, []),
      views: new UntypedFormControl("", []),
      disEmail: new UntypedFormControl("", []),
      website: new UntypedFormControl("", []),
      index: new UntypedFormControl("", []),
      releatedFirst: new UntypedFormControl("", []),
      releatedDate: new UntypedFormControl(Date.now(), []),
    });

    this.objectId = this.activateRouter.snapshot.params.id;

    if (this.objectId) {
      this.userService.getOneUser(this.objectId).subscribe((data: any) => {
        const u: any = data.data;
        this.user = data.data;
        // this.cityId = u.city;
        this.form.patchValue({
          name: u.name,
          image: u.image,
          cover: u.cover,
          phone: u.phone,
          email: u.email,
          role: u.role,
          cats: u.cats,
          tiktok: u.tiktok,
          youtube: u.youtube,
          // specials: u.specials,
          // discounts: u.discounts,
          // long: u.location.coordinates[0],
          // lat: u.location.coordinates[1],
          // location: u.location,
          // address: u.address,
          gender: u.gender || 1,
          birthDate: u.birthDate,
          active: u.active + "",
          promoted: u.promoted + "",
          verified: u.verified + "",
          // city: u.city + '',
          // state: u.state + '',
          advertiserStatus: u.advertiserStatus + "",
          facebook: u.facebook + "",
          galleryMaxCount: u.galleryMaxCount + "",
          views: u.views || 1,
          disEmail: u.disEmail + "",
          website: u.website + "",
          releatedFirst: u.releatedFirst + "",
          releatedDate: u.releatedDate + "",
          instagram: u.instagram + "",
          // hourFrom: u.hourFrom + '',
          // hourTo: u.hourTo + '',
          // hourFrom1: u.hourFrom1 + '',
          // hourTo1: u.hourTo1 + '',
          // alwaysOpen: u.alwaysOpen + '',
          index: u.index || 1,
          bio: this.htmlDecode(u.bio) || "",
        });

        // this.full.getAllSubsForCity(1, 1000, u.city).subscribe((data: any) => {
        //   this.states = data.data;
        //   this.form.patchValue({ state: u.state })
        // })

        let body = {
          cats: u.cats,
        };

        // this.full.getAllSpecialsForCats(1, 100, body).subscribe((data: any) => {
        //   this.specials = data.data;
        //   console.log('Specials: ', this.specials);
        // })

        // this.full.getAllDiscountsForCats(1, 100, body).subscribe((data: any) => {
        //   this.discounts = data.data;
        //   console.log('discounts: ', this.discounts);
        // })

        if (this.user.sequrityQuetions.length > 0) {
          this.form.patchValue({
            quetion: this.user.sequrityQuetions[0].quetion || "",
            answer: this.user.sequrityQuetions[0].answer || "",
          });
        }

        (this.thumbnailFileList = data.data.image),
          (this.thumbnailUrlList = data.data.image);

        (this.coverFileList = data.data.cover),
          (this.coverUrlList = data.data.cover);
        this.form.get("password").setValidators([]);
        this.form.get("password").updateValueAndValidity();
      });
    }

    if (localStorage.getItem("currentUser")) {
      this.userData = JSON.parse(localStorage.getItem("currentUser")).data.user;
      if (this.userData.role === "editor") {
        this.roles = ["user"];
      }
    }
  }

  getCategoriesNames(searchWord = "") {
    this.full.getAllParentCategoriesForDropdown(1, 500, searchWord).subscribe(
      (response: any) => {
        this.cats = response.data;
      },
      (error) => {}
    );
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];

    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  htmlDecode(input) {
    if (input === "") {
      return;
    }
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append(
      "images",
      this.thumbnailFileList[0],
      this.thumbnailFileList[0].name
    );
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              image: event.body.images[0],
            });
            this.thumbnailUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.thumbnailUrlList = this.translae.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null,
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  getSpecialsForCat(event) {
    let specBody = {
      cats: event.value,
    };

    console.log(specBody);

    // this.full.getAllSpecialsForCats(1, 100, specBody).subscribe((data: any) => {
    //   this.specials = data.data;
    //   console.log('Specials: ', this.specials);
    // })
  }

  getDiscountsForCat(event) {
    let disBody = {
      cats: event.value,
    };

    console.log(disBody);

    // this.full.getAllDiscountsForCats(1, 100, disBody).subscribe((data: any) => {
    //   this.discounts = data.data;
    //   console.log('discounts: ', this.discounts);
    // })
  }

  coverSelectFile(event) {
    this.coverFileList = [];

    this.coverList = event.target.files[0];
    this.coverFileList.push(this.coverList);
    this.coverUpload();
  }

  coverUpload() {
    let fd = new FormData();

    fd.append("images", this.coverFileList[0], this.coverFileList[0].name);
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              cover: event.body.images[0],
            });
            this.coverUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.coverUrlList = this.translae.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedCoverImage() {
    this.form.patchValue({
      cover: null,
    });
    this.progress = 0;
    this.coverFileList = [];
  }

  //   const validator = Validators.required;
  //   this.form.get('password').setValidators([validator]);
  //   this.form.get('password').updateValueAndValidity();

  addEdit() {
    if (!this.form.valid) {
      return false;
    }
    if (!this.objectId) {
      this.form.patchValue({
        sequrityQuetions: [
          {
            quetion: this.form.get("quetion").value,
            answer: this.form.get("answer").value,
          },
        ],
        // location: {
        //   type: 'Point',
        //   coordinates: [this.form.get("long").value * 1, this.form.get("lat").value * 1]
        // },
      });
      this.userService.signUp(this.form.value).subscribe(
        (response: any) => {
          this.router.navigateByUrl("/users-list");
          this.full.openSnackBar(response.message[0] || "", "أغلق", "success");
        },
        (error) => {
          this.full.openSnackBar(error || "", "أغلق", "danger");
        }
      );
    } else {
      const u: any = this.form.value;
      let body = {
        name: u.name,
        image: u.image,
        cover: u.cover,
        cats: u.cats,
        youtube: u.youtube,
        tiktok: u.tiktok,
        // specials: u.specials,
        // discounts: u.discounts,
        phone: u.phone,
        // location: {
        //   type: 'Point',
        //   coordinates: [this.form.get("long").value * 1, this.form.get("lat").value * 1]
        // },
        sequrityQuetions: [
          {
            quetion: this.form.get("quetion").value,
            answer: this.form.get("answer").value,
          },
        ],
        email: u.email,
        // city: u.city + '',
        // state: u.state + '',
        role: u.role,
        address: u.address,
        gender: u.gender + "",
        birthDate: u.birthDate,
        active: u.active + "",
        promoted: u.promoted + "",
        verified: u.verified + "",
        facebook: u.facebook + "",
        galleryMaxCount: u.galleryMaxCount + "",
        // hourFrom: u.hourFrom + '',
        // hourTo: u.hourTo + '',
        // hourFrom1: u.hourFrom1 + '',
        // hourTo1: u.hourTo1 + '',
        // alwaysOpen: u.alwaysOpen + '',
        index: u.index + "",
        instagram: u.instagram + "",
        views: u.views + "",
        website: u.website + "",
        releatedDate: Date.now(),
        releatedFirst: u.releatedFirst + "",
        disEmail: u.disEmail + "",
        advertiserStatus: u.advertiserStatus + "",
        bio: u.bio + "",
      };

      this.userService.editUser(this.objectId, body).subscribe(
        (response: any) => {
          this.router.navigateByUrl("/users-list");
          // this.full.openSnackBar(response.message || '', 'أغلق', 'success');
        },
        (error) => {
          this.full.openSnackBar(error || "", "أغلق", "danger");
        }
      );
    }
  }

  editPassword() {
    this.userService.editUserPass(this.objectId, this.passForm.value).subscribe(
      (response: any) => {
        this.router.navigateByUrl("/users-list");
        this.full.openSnackBar(response.message || "", "أغلق", "success");
      },
      (error) => {
        this.full.openSnackBar(error || "", "أغلق", "danger");
      }
    );
  }

  // getCitiesNames() {
  //   this.full.getAllParentCitiesForDropdown(1, 500)
  //     .subscribe((response: any) => {
  //       this.cities = response.data;
  //     }, (error) => {
  //     });
  // }

  changeCatsSearch() {
    this.getCategoriesNames(this.catssearchWord);
  }

  // changeStatesSearch() {
  //   this.getStatesForCity(this.cityId, this.stateSearchWord);
  // }

  // getStatesForCity(id, searchWord = '') {
  //   this.cityId = id;
  //   this.full.getAllSubsForCity(1, 1000, this.cityId, searchWord).subscribe((data: any) => {
  //     this.states = data.data;
  //   })
  // }
}
