<div class="login">
  <div class="container login-container">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="row text-center">
        <div class="col">
          <img src="assets/images/logo.png" width="10%" alt="" />
          <br />
          <br />
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <mat-form-field>
            <input
              matInput
              type="email"
              id="email"
              formControlName="email"
              placeholder="{{ 'email' | translate }}"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <mat-form-field>
            <input
              matInput
              type="password"
              id="password"
              formControlName="password"
              placeholder="{{ 'password' | translate }}"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <button mat-raised-button class="default-btn" color="primary">
            {{ "login" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
