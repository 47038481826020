import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageComponent } from '../../modals/message/message.component';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-user-comments',
  templateUrl: './user-comments.component.html',
  styleUrls: ['./user-comments.component.scss']
})
export class UserCommentsComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  form: UntypedFormGroup;
  data = [];
  categories = [];
  articles = [];
  articleID = '';

  displayedColumns: string[] = ['name', 'comment', 'rate', 'createdAt', 'delete'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;
  currentLang = '';
  id = '';

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.id = activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.getCommentsForAd(1);
  }

  getCommentsForAd(event: any) {
    this.full.getCommentsForUser(event.pageIndex + 1 || 1, event.pageSize, this.id).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  openMessage(message): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '650px',
      data: message
    });
  }

  openImage(image: string): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: { image: image[0] }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  delete(id) {
    this.full.deleteOneComment(id)
      .subscribe((response: any) => {
        this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
        this.toastr.successToastr(response.message);
      }, (error) => {
        this.toastr.errorToastr(error);
      });
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
