<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'comments' | translate}}</h3>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col flex">
                        <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                        </mat-form-field>
                    </div>
                    <div class="col flex">
                        <mat-form-field>
                            <mat-label>{{'categories' | translate}}</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let c of categories" (click)="getArticlesForCategory(c._id)">
                                    <span>{{c.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="spacer"></div>
                    </div>
                    <div class="col flex">
                        <mat-form-field>
                            <mat-label>{{'articles' | translate}}</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let c of articles" (click)="getAllCommentsForArticle(1, c._id)">
                                    <span>{{c.title}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="spacer"></div>
                    </div>
                </div> -->

                <div class="">
                    <!-- mat-elevation-z8 -->
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <span>
                                    {{row?.user?.name}}
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'comment' | translate}} </th>
                            <td mat-cell *matCellDef="let row" [title]='row.comment' class="pointer"
                                (click)='openMessage(row.comment)'>
                                <span>
                                    {{row.comment}}
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="rate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'rate' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                {{row.rate}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                {{row.createdAt | date: 'short'}}
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer" (click)="editDialog(row._id)">
                                    edit
                                </i>
                            </td>
                        </ng-container> -->

                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer" (click)="confirmDialog(row._id)">
                                    delete
                                </i>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="pageEvent = getCommentsForAd($event)"></mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>