import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { Category } from './../../../models/category';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { Article } from '../../../models/article';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  pageEvent;
  userFilter: UntypedFormGroup;
  activeForm: UntypedFormGroup;
  verifiedForm: UntypedFormGroup;
  promotedForm: UntypedFormGroup;
  users = [];
  usersSearch = '';
  searchWord = '';
  minPrice = 0;
  maxPrice = 99999999999;

  filterForm: UntypedFormGroup;
  data: Category[] = [];
  editUrl: '/edit-product/1';

  // types is [ text, img, date, inside-url, new-url, boolean, innerHtml, object, translated-text ]
  displayedColumns = [
    'id',
    'mainImage',
    'name',
    // 'type',
    // 'mainImage',
    // 'des',
    // 'price',
    'verified',
    'promoted',
    'status',
    'date',
    'comments',
    'edit',
    'delete',
  ];

  tabelKeys = [
    { label: 'mainImage', key: 'mainImage', type: 'img' },
    { label: 'name', key: 'name', id: '_id', type: 'text' },
    // { label: 'type', key: 'type', type: 'translated-text' },
    // { label: 'price', key: 'price', type: 'number' },
    // { label: 'des', key: 'des', type: 'innerHtml' },
    // { label: 'verified', key: 'verified', type: 'boolean' },
    // { label: 'promoted', key: 'promoted', type: 'boolean' },
    { label: 'status', key: 'status', type: 'boolean' },
    { label: 'date', key: 'createdAt', type: 'date' },
  ];
  dataSource: MatTableDataSource<Category>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image = [];

  // MatPaginator Inputs
  length: number;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 100, 150, 200];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrManager,
    public dialog: MatDialog
  ) {
    this.userFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      role: new UntypedFormControl('user', []),
      deleted: new UntypedFormControl(false, []),
      active: new UntypedFormControl(true, []),
    })
  }

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      minPrice: new UntypedFormControl(0, []),
      maxPrice: new UntypedFormControl(999999999999, []),
      promoted: new UntypedFormControl(null, []),
      verified: new UntypedFormControl(null, []),
      category: new UntypedFormControl(null, []),
      status: new UntypedFormControl(null, []),
      user: new UntypedFormControl(null, []),
    });
    this.activeForm = new UntypedFormGroup({
      status: new UntypedFormControl('', [])
    });
    this.verifiedForm = new UntypedFormGroup({
      verified: new UntypedFormControl('', [])
    });
    this.promotedForm = new UntypedFormGroup({
      promoted: new UntypedFormControl('', [])
    });
    this.getData(1);
    this.getAllUsers();
  }

  searchAds() {
    this.filterForm.patchValue({
      searchWord: this.searchWord,
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
    })
    this.getData(1);
  }

  filter() {
    this.getData(1);
  }

  getAll() {
    this.filterForm.patchValue({
      user: null,
      status: null,
      promoted: null,
      verified: null,
      category: null
    })
    this.getData(1);
  }

  getData(event: any) {
    this.full.getAllProducts(event.pageIndex + 1 || 1, event.pageSize, this.filterForm.value).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  edit(id) {
    let url = window.location.href.replace('products', `edit-product/${id}`);
    window.open(url, '_blank')
  }

  addSliderForProduct(product) {
    localStorage.setItem('proId', product._id);
    localStorage.setItem('proType', product.type);
    this.router.navigateByUrl('/add-slider')
  }

  filterByUser(id: string) {
    this.filterForm.patchValue({
      user: id
    })
    this.getData(1)
  }

  openImage(image): void {
    this.image.push(image);
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: [...this.image]
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = [];
    });
  }

  activate(article: Article, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      status: event.checked
    });

    this.full.changeStatusProduct(article._id, this.activeForm.value).subscribe((data: any) => {
      article.status = event.checked;
      this.full.openSnackBar(this.translate.instant('adStatusChanges'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  changePromoted(article: Article, event: MatSlideToggleChange) {
    this.promotedForm.patchValue({
      promoted: event.checked
    });

    this.full.changeStatusProduct(article._id, this.promotedForm.value).subscribe((data: any) => {
      article.promoted = event.checked;
      this.full.openSnackBar(this.translate.instant('adStatusChanges'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  changeVerified(article: Article, event: MatSlideToggleChange) {
    this.verifiedForm.patchValue({
      verified: event.checked
    });

    this.full.changeStatusProduct(article._id, this.verifiedForm.value).subscribe((data: any) => {
      article.verified = event.checked;
      this.full.openSnackBar(this.translate.instant('adStatusChanges'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }

  delete(id) {
    this.full.deleteOneProduct(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar(this.translate.instant('articleDeletedSuccess'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  copy(val) {
    // const selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    // selBox.style.opacity = '0';
    // selBox.value = `https://ncrgrup.com/product/${val._id}`;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand('copy');
    // document.body.removeChild(selBox);
    // this.toastr.successToastr(this.translate.instant('linkCopied'));
  }

  getAllUsers() {
    this.full.getAllUsersForDropDownWithSearch(1, 100, this.userFilter.value)
      .subscribe((response: any) => {
        this.users = response.data;
      }, (error) => {
      });
  }

  changeSearch() {
    if (this.usersSearch) {
      this.userFilter.patchValue({
        searchWord: this.usersSearch,
        advertiserStatus: true
      })

      this.full.getAllUsersForDropDown(1, 10, this.usersSearch, this.userFilter.value)
        .subscribe((response: any) => {
          this.users = response.data;

        }, (error) => {
        });
    }
  }
}
