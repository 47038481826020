import { AddFaqComponent } from './add-faq/add-faq.component';
import { FaqComponent } from './faq/faq.component';
import { SettingsComponent } from './settings/settings.component';
import { ProfileComponent } from './personal-info/profile/profile.component';
import { AddSliderComponent } from './sliders/add-slider/add-slider.component';
import { SliderListComponent } from './sliders/slider-list/slider-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ArticlesComponent } from './blog/articles/articles.component';
import { AddArticleComponent } from './blog/add-article/add-article.component';
import { AddBloCategoryComponent } from './blog/add-blo-category/add-blo-category.component';
import { BlogCategoriesComponent } from './blog/blog-categories/blog-categories.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersListComponent } from './userManagement/users-list/users-list.component';
import { AddUserComponent } from './userManagement/add-user/add-user.component';
import { ContactsComponent } from './contactsManagement/contacts/contacts.component';
import { CommentManagementComponent } from './blog/comment-management/comment-management.component';
import { LoggerComponent } from './logger/logger.component';
import { DevelopmentRequestComponent } from './development-request/development-request.component';
import { AddCatRequestComponent } from './add-cat-request/add-cat-request.component';
import { CitiesLisComponent } from './cities/cities-lis/cities-lis.component';
import { AddCityComponent } from './cities/add-city/add-city.component';
import { SendFcmComponent } from './send-fcm/send-fcm.component';
import { SiteInfoComponent } from './site-info/site-info.component';
import { FeaturesListComponent } from './features/features-list/features-list.component';
import { AddFeatuerComponent } from './features/add-featuer/add-featuer.component';
import { UserCommentsComponent } from './userManagement/user-comments/user-comments.component';
import { GalleryComponent } from './userManagement/gallery/gallery.component';
import { AddGalleryComponent } from './userManagement/add-gallery/add-gallery.component';
import { VerifyRequestsComponent } from './verify-requests/verify-requests.component';
import { LotListComponent } from './lot-list/lot-list.component';
import { AddLotListComponent } from './add-lot-list/add-lot-list.component';
import { LotSubscribersListComponent } from './lot-subscribers-list/lot-subscribers-list.component';
import { SpecialsListComponent } from './specials/specials-list/specials-list.component';
import { AddSpecialComponent } from './specials/add-special/add-special.component';
import { DiscountListComponent } from './discounts/discount-list/discount-list.component';
import { AddDiscountComponent } from './discounts/add-discount/add-discount.component';
import { EmptyValueUsersComponent } from './userManagement/empty-value-users/empty-value-users.component';
import { AddClientListComponent } from './clientsList/add-client-list/add-client-list.component';
import { ClientsListComponent } from './clientsList/clients-list/clients-list.component';
import { AddInterestComponent } from './interests/add-interest/add-interest.component';
import { InterestsListComponent } from './interests/interests-list/interests-list.component';
import { AddCommunicationsAttepmtComponent } from './communication-attmpts/add-communications-attepmt/add-communications-attepmt.component';
import { CommunicationsAttepmtsListComponent } from './communication-attmpts/communications-attepmts-list/communications-attepmts-list.component';
import { AddClientOrderComponent } from './clientsList/add-client-order/add-client-order.component';
import { ClientOrdersComponent } from './clientsList/client-orders/client-orders.component';
import { AddJobComponent } from './jobs/add-job/add-job.component';
import { JobsListComponent } from './jobs/jobs-list/jobs-list.component';
import { AddClientAddressComponent } from './clientsList/add-client-address/add-client-address.component';
import { ClientAddressComponent } from './clientsList/client-address/client-address.component';
import { AdminGuard } from '../guards/admin.guard';
import { TransferListComponent } from './transfers/transfer-list/transfer-list.component';
import { AddTransferComponent } from './transfers/add-transfer/add-transfer.component';
import { BanksComponent } from './bank-list/banks/banks.component';
import { AddBankComponent } from './bank-list/add-bank/add-bank.component';
import { OfficeListComponent } from './offices-list/office-list/office-list.component';
import { AddOfficeComponent } from './offices-list/add-office/add-office.component';
import { AddCodeComponent } from './codeList/add-code/add-code.component';
import { CodesComponent } from './codeList/codes/codes.component';
import { AddLessCatComponent } from './less-cat/add-less-cat/add-less-cat.component';
import { LessCatListComponent } from './less-cat/less-cat-list/less-cat-list.component';
import { AddUnitComponent } from './units/add-unit/add-unit.component';
import { UnitsListComponent } from './units/units-list/units-list.component';
import { AddLessonComponent } from './lessons/add-lesson/add-lesson.component';
import { LessonsListComponent } from './lessons/lessons-list/lessons-list.component';
import { AddExercisComponent } from './exercises/add-exercis/add-exercis.component';
import { ExercisListComponent } from './exercises/exercis-list/exercis-list.component';
import { AddBaccalaureateComponent } from './baccalaureates/add-baccalaureate/add-baccalaureate.component';
import { BaccalaureatesListComponent } from './baccalaureates/baccalaureates-list/baccalaureates-list.component';
import { AddBookComponent } from './book/add-book/add-book.component';
import { BookListComponent } from './book/book-list/book-list.component';
import { AddExamComponent } from './exam/add-exam/add-exam.component';
import { ExamsListComponent } from './exam/exams-list/exams-list.component';
import { QuestionsListComponent } from './questions/questions-list/questions-list.component';
import { AddQuestionComponent } from './questions/add-question/add-question.component';
import { ExamQuestionsComponent } from './exam/exam-questions/exam-questions.component';
import { AddPackageComponent } from './packages/add-package/add-package.component';
import { PackagesListComponent } from './packages/packages-list/packages-list.component';
import { AddPaymentMethodsComponent } from './payment-methods/add-payment-methods/add-payment-methods.component';
import { PaymentMethodsListComponent } from './payment-methods/payment-methods-list/payment-methods-list.component';
import { AddFeatureComponent } from './features/add-feature/add-feature.component';
import { RequestPackagesListComponent } from './request-packages/request-packages-list/request-packages-list.component';
import { ViewRequestPackageComponent } from './request-packages/view-request-package/view-request-package.component';
import { PackagesFeauturesListComponent } from './packages-feautures/packages-feautures-list/packages-feautures-list.component';
import { AddPackagesFeautureComponent } from './packages-feautures/add-packages-feauture/add-packages-feauture.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { ForgotPasswordListComponent } from './users/forgot-password-list/forgot-password-list.component';
import { StuffListComponent } from './users/stuff-list/stuff-list.component';
import { AddEmployeeComponent } from './users/add-employee/add-employee.component';
import { EmployeeGuard } from '../guards/employee.guard';
import { DeleteAccountRequestsComponent } from './users/delete-account-requests/delete-account-requests.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-less-cat',
    component: AddLessCatComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-less-cat/:id',
    component: AddLessCatComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'less-cat-list',
    component: LessCatListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-unit',
    component: AddUnitComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-unit/:id',
    component: AddUnitComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'units-list',
    component: UnitsListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-lesson',
    component: AddLessonComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-lesson/:id',
    component: AddLessonComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'lessons-list',
    component: LessonsListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-exercis',
    component: AddExercisComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-exercis/:id',
    component: AddExercisComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'exercises-list',
    component: ExercisListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-baccalaureate',
    component: AddBaccalaureateComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-baccalaureate/:id',
    component: AddBaccalaureateComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'baccalaureates-list',
    component: BaccalaureatesListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-book',
    component: AddBookComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-book/:id',
    component: AddBookComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'books-list',
    component: BookListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-exam',
    component: AddExamComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-exam/:id',
    component: AddExamComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'exams-list/:id/questions',
    component: ExamQuestionsComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'exams-list',
    component: ExamsListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-question',
    component: AddQuestionComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-question/:id',
    component: AddQuestionComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'questions-list',
    component: QuestionsListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-package',
    component: AddPackageComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-package/:id',
    component: AddPackageComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'packages-list',
    component: PackagesListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-payment-method',
    component: AddPaymentMethodsComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-payment-method/:id',
    component: AddPaymentMethodsComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'payment-methods-list',
    component: PaymentMethodsListComponent,
    canActivate: [EmployeeGuard],
  },

  // Slider Management
  {
    path: 'slider-list',
    component: SliderListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-slider',
    component: AddSliderComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-slider/:id',
    component: AddSliderComponent,
    canActivate: [EmployeeGuard],
  },
  // Features Management
  {
    path: 'features-list',
    component: FeaturesListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-feature',
    component: AddFeatureComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-feature/:id',
    component: AddFeatureComponent,
    canActivate: [EmployeeGuard],
  },
  // Package Features Management
  {
    path: 'packages-features-list',
    component: PackagesFeauturesListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-packages-feature',
    component: AddPackagesFeautureComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-packages-feature/:id',
    component: AddPackagesFeautureComponent,
    canActivate: [EmployeeGuard],
  },

  // Specials List Management
  // {
  //   path: 'specials-list',
  //   component: SpecialsListComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'add-special',
  //   component: AddSpecialComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'edit-special/:id',
  //   component: AddSpecialComponent,
  //   canActivate: [AdminGuard],
  // },

  // request packages List Management
  {
    path: 'request-packages-list',
    component: RequestPackagesListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'view-request-package/:id',
    component: ViewRequestPackageComponent,
    canActivate: [EmployeeGuard],
  },
  // Discount List Management
  {
    path: 'discount-list',
    component: DiscountListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-discount',
    component: AddDiscountComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-discount/:id',
    component: AddDiscountComponent,
    canActivate: [EmployeeGuard],
  },

  // Lot Management
  {
    path: 'lot-list',
    component: LotListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'lot-list-subs/:id',
    component: LotSubscribersListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-lot',
    component: AddLotListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-lot/:id',
    component: AddLotListComponent,
    canActivate: [EmployeeGuard],
  },

  // Slider Management
  // {
  //   path: 'gallery',
  //   component: GalleryComponent
  // },
  // {
  //   path: 'add-gallery',
  //   component: AddGalleryComponent
  // },
  // {
  //   path: 'edit-gallery/:id',
  //   component: AddGalleryComponent
  // },

  // Our Services Management
  // {
  //   path: 'services-list',
  //   component: OurServicesComponent
  // },
  // {
  //   path: 'add-sevice',
  //   component: AddNewServiceComponent
  // },
  // {
  //   path: 'edit-service/:id',
  //   component: AddNewServiceComponent
  // },

  // Our Agents Management
  // {
  //   path: 'agents-list',
  //   component: OurAgentsComponent
  // },
  // {
  //   path: 'add-agent',
  //   component: AddAgentComponent
  // },
  // {
  //   path: 'edit-agent/:id',
  //   component: AddAgentComponent
  // },

  // Home Videos Management
  // {
  //   path: 'home-videos-list',
  //   component: HomeVideosComponent
  // },
  // {
  //   path: 'add-home-video',
  //   component: AddHomeVideoComponent
  // },
  // {
  //   path: 'edit-home-video/:id',
  //   component: AddHomeVideoComponent
  // },

  // Partners Management
  // {
  //   path: 'partners-list',
  //   component: PartnersComponent
  // },
  // {
  //   path: 'add-partner',
  //   component: AddPartnerComponent
  // },
  // {
  //   path: 'edit-partner/:id',
  //   component: AddPartnerComponent
  // },

  // Our Clients Management Images
  // {
  //   path: 'clients-list',
  //   component: OurClientsComponent
  // },
  // {
  //   path: 'add-client',
  //   component: AddClientComponent
  // },
  // {
  //   path: 'edit-client/:id',
  //   component: AddClientComponent
  // },

  // // Offers Management
  // {
  //   path: 'offer-list',
  //   component: OffersListComponent
  // },
  // {
  //   path: 'add-offer',
  //   component: AddOfferComponent
  // },
  // {
  //   path: 'edit-offer/:id',
  //   component: AddOfferComponent
  // },

  // // Platform Video Management
  // {
  //   path: 'platform-video',
  //   component: PlatformsVideoComponent
  // },
  // {
  //   path: 'add-platform-video',
  //   component: AddPlatformVideoComponent
  // },
  // {
  //   path: 'edit-platform-video/:id',
  //   component: AddPlatformVideoComponent
  // },

  // // Bussiness Video Management
  // {
  //   path: 'business-plans',
  //   component: PlansComponent
  // },
  // {
  //   path: 'add-business-plan',
  //   component: EditPlansComponent
  // },
  // {
  //   path: 'edit-business-plan/:id',
  //   component: EditPlansComponent
  // },

  // // Profile

  {
    path: 'profile',
    component: ProfileComponent
  },

  // Settings
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AdminGuard],
  },
  // {
  //   path: 'edit-platforms',
  //   component: EditPlatformsComponent
  // },
  // {
  //   path: 'edit-philosophy',
  //   component: EditPhilosophyComponent
  // },
  // {
  //   path: 'edit-forex',
  //   component: EditForexComponent
  // },
  // {
  //   path: 'about-us',
  //   component: AboutUsComponent
  // },

  // Video Lists
  // {
  //   path: 'video-lists',
  //   component: VideoListsComponent
  // },
  // {
  //   path: 'add-video',
  //   component: AddVideoComponent
  // },

  // Codes Management
  {
    canActivate: [EmployeeGuard],
    path: 'codes-list',
    component: CodesComponent
  },
  {
    canActivate: [EmployeeGuard],
    path: 'add-code',
    component: AddCodeComponent
  },
  {
    canActivate: [EmployeeGuard],
    path: 'edit-code/:id',
    component: AddCodeComponent
  },

  // Cities
  {
    path: 'cities',
    component: CitiesLisComponent,
    data: { animation: 'AboutPage' },
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-city',
    component: AddCityComponent,
    data: { animation: 'HomePage' },
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-city/:id',
    component: AddCityComponent,
    data: { animation: 'HomePage' },
    canActivate: [EmployeeGuard],
  },

  // Blog
  {
    path: 'categories',
    component: BlogCategoriesComponent,
    data: { animation: 'AboutPage' },
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-category',
    component: AddBloCategoryComponent,
    data: { animation: 'HomePage' },
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-category/:id',
    component: AddBloCategoryComponent,
    data: { animation: 'HomePage' },
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-product',
    component: AddArticleComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-product/:id',
    component: AddArticleComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'products',
    component: ArticlesComponent,
    canActivate: [EmployeeGuard],
  },
  // Fcm
  {
    path: 'send-fcm',
    component: SendFcmComponent,
    canActivate: [EmployeeGuard],
  },

  // Transfer Management
  {
    canActivate: [EmployeeGuard],
    path: 'trasnfers',
    component: TransferListComponent
  },
  {
    canActivate: [EmployeeGuard],
    path: 'add-trasnfer',
    component: AddTransferComponent
  },
  {
    canActivate: [EmployeeGuard],
    path: 'edit-trasnfer/:id',
    component: AddTransferComponent
  },

  // Office Management
  {
    canActivate: [EmployeeGuard],
    path: 'office-list',
    component: OfficeListComponent
  },
  {
    canActivate: [EmployeeGuard],
    path: 'add-office',
    component: AddOfficeComponent
  },
  {
    canActivate: [EmployeeGuard],
    path: 'edit-office/:id',
    component: AddOfficeComponent
  },

  // Banks Management
  {
    canActivate: [EmployeeGuard],
    path: 'bank-list',
    component: BanksComponent
  },
  {
    canActivate: [EmployeeGuard],
    path: 'add-bank',
    component: AddBankComponent
  },
  {
    canActivate: [EmployeeGuard],
    path: 'edit-bank/:id',
    component: AddBankComponent
  },
  // Site Info
  // {
  //   path: 'site',
  //   component: SiteInfoComponent,
  //   canActivate: [AdminGuard],
  // },

  // Clients List For Client Manager
  // {
  //   path: 'add-client-list',
  //   component: AddClientListComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'edit-client-list/:id',
  //   component: AddClientListComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'clients-list',
  //   component: ClientsListComponent,
  //   canActivate: [AdminGuard],
  // },

  // Client Orders List
  // {
  //   path: 'add-client-order',
  //   component: AddClientOrderComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'edit-client-order/:id',
  //   component: AddClientOrderComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'clients-orders-list',
  //   component: ClientOrdersComponent,
  //   canActivate: [AdminGuard],
  // },

  // Interests List
  // {
  //   path: 'add-interest',
  //   component: AddInterestComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'edit-interest/:id',
  //   component: AddInterestComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'interests-list',
  //   component: InterestsListComponent,
  //   canActivate: [AdminGuard],
  // },

  // Jobs List
  // {
  //   path: 'add-job',
  //   component: AddJobComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'edit-job/:id',
  //   component: AddJobComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'jobs-list',
  //   component: JobsListComponent,
  //   canActivate: [AdminGuard],
  // },

  // Client Address List
  // {
  //   path: 'add-client-address',
  //   component: AddClientAddressComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'edit-client-address/:id',
  //   component: AddClientAddressComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'client-address-list',
  //   component: ClientAddressComponent,
  //   canActivate: [AdminGuard],
  // },

  // Communications List
  // {
  //   path: 'add-communication',
  //   component: AddCommunicationsAttepmtComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'edit-communication/:id',
  //   component: AddCommunicationsAttepmtComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'communications-list',
  //   component: CommunicationsAttepmtsListComponent,
  //   canActivate: [AdminGuard],
  // },

  // {
  //   path: 'add-author',
  //   component: AddAuthorComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'edit-author/:id',
  //   component: AddAuthorComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'author-list',
  //   component: AuthorListComponent,
  //   canActivate: [AdminGuard],
  // },
  // start-old-users
  // {
  //   path: 'comments/:id',
  //   component: CommentManagementComponent
  // },
  // {
  //   path: 'user/comments/:id',
  //   component: UserCommentsComponent
  // },
  // {
  //   path: 'users-list',
  //   component: UsersListComponent,
  //   canActivate: [],
  // },
  // {
  //   path: 'empty-users',
  //   component: EmptyValueUsersComponent,
  //   canActivate: [],
  // },
  // {
  //   path: 'add-user',
  //   component: AddUserComponent,
  // },
  // {
  //   path: 'edit-user/:id',
  //   component: AddUserComponent,
  // },
  // end-old-users
  // start-new-users
  {
    path: 'users-list',
    component: UserListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'users-list/:id',
    component: UserDetailsComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'stuff-list',
    component: StuffListComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'add-employee',
    component: AddEmployeeComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordListComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'delete-account-request',
    component: DeleteAccountRequestsComponent,
    canActivate: [EmployeeGuard],
  },
  // end-new-users

  // Uploader
  // {
  //   path: 'uploade-files',
  //   component: UploadFilesComponent
  // },
  // {
  //   path: 'uploading-center',
  //   component: UploadersComponent
  // },

  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-faq',
    component: AddFaqComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'edit-faq/:id',
    component: AddFaqComponent,
    canActivate: [EmployeeGuard],
  },

  // Features
  // {
  //   path: 'features',
  //   component: FeaturesListComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'add-feature',
  //   component: AddFeatuerComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'edit-feature/:id',
  //   component: AddFeatuerComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'orders',
  //   component: OrdersComponent,
  // },
  // {
  //   path: 'inquiry',
  //   component: InquiriesComponent,
  // },
  {
    path: 'verify-reqs',
    component: VerifyRequestsComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'dev-req',
    component: DevelopmentRequestComponent,
    canActivate: [EmployeeGuard],
  },
  {
    path: 'add-cat-req',
    component: AddCatRequestComponent,
    canActivate: [EmployeeGuard],
  },
  // {
  //   path: 'logs',
  //   component: LoggerComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'accounts',
  //   component: AccountOrdersComponent
  // },
  // {
  //   path: 'chat',
  //   component: ChatComponent
  // },
  { path: '*', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
