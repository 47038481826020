import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TransferStatusComponent } from '../transfer-status/transfer-status.component';
import { TransferDetailesComponent } from '../transfer-detailes/transfer-detailes.component';
import { FullServiceService } from '../../../services/full-service.service';

@Component({
  selector: 'app-transfer-list',
  templateUrl: './transfer-list.component.html',
  styleUrls: ['./transfer-list.component.scss']
})
export class TransferListComponent implements OnInit {
  data: any[] = [];
  pageEvent;
  displayedColumns: string[] = ['orderId', 'name', 'phone', 'value', 'type','monyType', 'status', 'date', 'detailes'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) { this.getAllContacts(1); }

  ngOnInit() {

  }

  getAllContacts(event: any) {
    this.full.getAllTransfers(event.pageIndex + 1 || 1, event.pageSize).subscribe((data: any) => {
      this.data = data.data;
      this.pageIndex = data.currentPage;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addStatus(order) {
    const dialogRef = this.dialog.open(TransferStatusComponent, {
      data: order,
      width: '70%',
      height: '200px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllContacts(this.pageIndex);
    });
  }

  orderDetailes(order) {
    const dialogRef = this.dialog.open(TransferDetailesComponent, {
      width: '650px',
      height: '700px',
      data: order
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllContacts(this.pageIndex);
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }

}
