import { TranslateService } from '@ngx-translate/core';
import { FileHandle } from './../../directives/dragDrop.directive';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-agent',
  templateUrl: './add-agent.component.html',
  styleUrls: ['./add-agent.component.scss']
})
export class AddAgentComponent implements OnInit {
  form: UntypedFormGroup;

  objectId: string;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      titleAr: new UntypedFormControl('', [Validators.required]),
      titleEn: new UntypedFormControl('', [Validators.required]),
      titleTr: new UntypedFormControl('', [Validators.required]),

      subTitleAr: new UntypedFormControl('', [Validators.required]),
      subTitleEn: new UntypedFormControl('', [Validators.required]),
      subTitleTr: new UntypedFormControl('', [Validators.required]),

      status: new UntypedFormControl('', []),
    });

    this.objectId = this.activateRouter.snapshot.params.id;

    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneAgent(this.objectId).subscribe((data: any) => {
      const s = data.data;
      this.form.patchValue(s);
      this.form.patchValue({ status: s.status + '' });
    }, err => {
      this.snackBar.open(err);
    });
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      this.full.addAgent(this.form.value).subscribe((data: any) => {
        this.toastr.successToastr('تمت إضافة الوكيل بنجاح');
        this.router.navigateByUrl('/agents-list');
      }, err => {
        this.toastr.errorToastr(err);
      });
    } else {
      this.full.editOneAgent(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.toastr.successToastr('حدث خطأ أثناء إضافة الوكيل');
          this.router.navigateByUrl('/agents-list');
        }, (error) => {
          this.toastr.errorToastr(error);
        });
    }
  }
}
