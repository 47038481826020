<div class="container-fluid table-container">
    <div class="row" style="padding-top: 1rem;">
        <div class="col" *ngIf="!(isLoading$ | async)">
            <div class="title">
              <h4 translate>userInfo</h4>
              <button (click)="addNote()" mat-raised-button translate><mat-icon>note_add</mat-icon>{{'addNote' | translate}}</button>
            </div>
            <mat-card *ngIf="dataUser$ | async as _userData">
                <mat-card-header>
                  <!-- <div mat-card-avatar class="example-header-image"  [ngStyle]="{'background-color': _userData.user.image ? 'red' : (style2 ? 'blue' : null) }"></div> -->
                  <mat-card-title> <span translate>name</span> : {{_userData.user?.name}} / <span translate>accountCreatedAt</span>:{{_userData.user?.createdAt | date}}</mat-card-title>
                  <mat-card-subtitle> <span translate>phone</span> : {{_userData.user?.phone}}</mat-card-subtitle>
                  <mat-card-subtitle> <span translate>whatsapp</span> : {{_userData.user?.whatsappPhone}}</mat-card-subtitle>
                  <mat-card-subtitle> <span translate>cityState</span> : {{_userData.user?.city?.nameEn}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content *ngIf="_userData.user?.adminNotes &&  _userData.user?.adminNotes.length">
                  <h5 class="notes-title" translate>notes :</h5>
                  <p *ngFor="let not of _userData.user?.adminNotes; let i = index;"> {{i+1}}- {{not.note}}</p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row" style="margin: 1rem 0rem;"></div>
    <div class="row">
      <div class="col" *ngIf="!(isLoading$ | async)">
        <div class="title">
          <h4 translate>userPackages</h4>
        </div>
        <ng-container *ngIf="dataUser$ | async as _userData">
          <mat-card *ngFor="let userPackage of _userData.packages" style="margin: 1rem 0;">
              <mat-card-header>
                <mat-card-title>package: {{userPackage.package?.nameEn}} / {{userPackage.package?.activateDurationInDays}} <span>Days</span>/ {{userPackage.package?.price}} $</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p><span translate>startDate :</span>{{userPackage?.startDate | date}}</p>
                <p><span translate>endDate :</span>{{userPackage?.endDate | date}}</p>
                <p><span translate>discountValue :</span>{{userPackage?.discountValue}}</p>
                <p><span translate>finalValue :</span>{{userPackage?.finalValue}}</p>
                <p><span translate>isAdminAccepted :</span>{{userPackage?.isAdminAccepted}}</p>
                <img *ngIf="userPackage?.ReceiptImage" class="product__img" [src]="userPackage?.ReceiptImage">
                <p *ngIf="userPackage?.ReceiptImage"><a href="{{userPackage?.ReceiptImage}}" target="_blank" rel="noopener noreferrer">ReceiptImage</a></p>
              </mat-card-content>
          </mat-card>
          <mat-card *ngIf="!_userData.packages || !_userData.packages.length">
            <mat-card-header>
              <mat-card-title translate>
                there is no packages was added for this user
              </mat-card-title>
            </mat-card-header>
          </mat-card>
        </ng-container>
      </div>
  </div>
</div>