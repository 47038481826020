<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center" *ngIf="!objectId">{{'addNewMember' |
                            translate}}</h4>
                        <h4 class="h4-responsive text-center" *ngIf="objectId">{{'editMember' |
                            translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='name' matInput placeholder="{{'name' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='email' matInput type='text' id='text'
                                    placeholder="{{'email' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='phone' type="tel" matInput
                                    placeholder="{{'phone' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'role' | translate}}</mat-label>
                                <mat-select formControlName="role">
                                    <mat-option *ngFor="let role of roles" [value]="role">
                                        {{role | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label> {{'status' | translate}}</mat-label>
                                <mat-select formControlName='active'>
                                    <mat-option value='true'>
                                        {{'active' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'inActive' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center" *ngIf="!objectId">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='password' type="password" matInput
                                    placeholder="{{'password' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-center" *ngIf="!objectId">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='passwordConfirm' type="password" matInput
                                    placeholder="{{'confirmPassword' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid'
                                color="primary">
                                <span *ngIf="!objectId">{{'addNewMember' | translate}}</span>
                                <span *ngIf="objectId">{{'editMember' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>

                <form [formGroup]='passForm' *ngIf="objectId">
                    <div class="row text-center">
                        <div class="col">
                            <br>
                            <hr>
                            <h5>{{'editPass' | translate}}</h5>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='newPassword' type="password" matInput
                                    placeholder="{{'password' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input type="password" formControlName='passwordConfirm' matInput
                                    placeholder="{{'confirmPassword' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="editPassword()"
                                [disabled]='!passForm.valid' color="primary">
                                <span>{{'editPassword' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>