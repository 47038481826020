import { HttpEventType, HttpEvent } from '@angular/common/http';
import { FileHandle } from './../directives/dragDrop.directive';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TranslateService } from '@ngx-translate/core';
import { FullServiceService } from './../../services/full-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  aboutUs;
  form: UntypedFormGroup;

  // Iploader
  fileList = [];
  list: File = null;
  progress = 0;
  urlList: string;
  files: FileHandle[] = [];
  urlAfterUpload: any;

  constructor(
    private full: FullServiceService,
    private translate: TranslateService,
    private toastr: ToastrManager
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      sectionImage: new UntypedFormControl('', [Validators.required]),

      sectionDesAr: new UntypedFormControl('', [Validators.required]),
      sectionDesEn: new UntypedFormControl('', [Validators.required]),
      sectionDesFr: new UntypedFormControl('', [Validators.required]),

      fDecTitleAr: new UntypedFormControl('', [Validators.required]),
      fDecTitleEn: new UntypedFormControl('', [Validators.required]),
      fDecTitleFr: new UntypedFormControl('', [Validators.required]),

      fDecAr: new UntypedFormControl('', [Validators.required]),
      fDecEn: new UntypedFormControl('', [Validators.required]),
      fDecFr: new UntypedFormControl('', [Validators.required]),

      sDecTitleAr: new UntypedFormControl('', [Validators.required]),
      sDecTitleEn: new UntypedFormControl('', [Validators.required]),
      sDecTitleFr: new UntypedFormControl('', [Validators.required]),

      sDecAr: new UntypedFormControl('', [Validators.required]),
      sDecEn: new UntypedFormControl('', [Validators.required]),
      sDecFr: new UntypedFormControl('', [Validators.required]),
    });
    this.getData();
  }

  getData() {
    this.full.getAboutus()
      .subscribe((data: any) => {
        this.aboutUs = data.data;
        const d = data.data;
        this.pachValues(d);
      }, err => {
        this.toastr.errorToastr(err);
      });
  }

  pachValues(d) {
    this.form.patchValue({
      sectionImage: d.sectionImage,

      sectionDesAr: d.sectionDesAr,
      sectionDesEn: d.sectionDesEn,
      sectionDesFr: d.sectionDesFr,

      fDecTitleAr: d.fDecTitleAr,
      fDecTitleEn: d.fDecTitleEn,
      fDecTitleFr: d.fDecTitleFr,

      fDecAr: d.fDecAr,
      fDecEn: d.fDecEn,
      fDecFr: d.fDecFr,

      sDecTitleAr: d.sDecTitleAr,
      sDecTitleEn: d.sDecTitleEn,
      sDecTitleFr: d.sDecTitleFr,

      sDecAr: d.sDecAr,
      sDecEn: d.sDecEn,
      sDecFr: d.sDecFr,
    });

    this.fileList[0] = d.sectionImage;
    this.urlList = d.sectionImage;
  }

  selectFile(event) {
    const reader = new FileReader();

    this.list = event.target.files[0];
    this.fileList.push(this.list);
    this.urlAfterUpload = event.target.files[0];
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.urlAfterUpload = reader.result;
    };
  }

  upload() {
    let fd = new FormData();
    fd.append('images', this.fileList[0], this.fileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            sectionImage: event.body.images[0]
          });
          this.urlList = event.body.images[0];
      }
    }, err => {
      this.urlList = this.translate.instant('imageNotUploaded');
    });
  }

  deleteUploadedImage() {
    this.form.patchValue({
      sectionImage: null
    });
    this.progress = 0;
    this.fileList = [];
    this.urlList = '';
  }

  filesDropped(files: FileHandle[]): void {
    this.urlAfterUpload = files[0].url;
    this.fileList[0] = files[0].file;
  }

  edit() {
    this.full.editAboutus(this.aboutUs._id, this.form.value)
      .subscribe(data => {
        this.toastr.successToastr('hasBeenEdited');
      }, e => {
        this.toastr.errorToastr('errorOnEdit');
      });
  }

}
