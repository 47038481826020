<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'uploadingCenter' | translate}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col flex">
                        <mat-form-field class="filter-input">
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                        </mat-form-field>
                        <div class="spacer"></div>

                        <div class="add-circle pointer" color='primary' (click)="goTo('add-category')">
                            <i class="material-icons" color='secondary'>
                                add
                            </i>
                        </div>
                    </div>
                </div>

                <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'type' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.type}} </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="size">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'size' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.size}} </td>
                        </ng-container>

                        <ng-container matColumnDef="uploadTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'uploadTime' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.uploadTime}} </td>
                        </ng-container>

                        <ng-container matColumnDef="downloaded">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'downloaded' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.downloaded}} </td>
                        </ng-container>

                        <ng-container matColumnDef="link">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'link' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.link}} </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'actions' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.actions}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <!-- <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="pageEvent = getCategories($event)"></mat-paginator> -->
                </div>
            </mat-card>
        </div>
    </div>
</div>