<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="text-center" *ngIf="!objectId">{{'addNewFeature' | translate}}</h4>
                        <h4 class="text-center" *ngIf="objectId">{{'editFeature' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <div class="progress form-group">
                        <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar"
                            [style.width.%]="progress">
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5 color='primary'>
                                <span>{{'image' | translate}}</span>
                            </h5>
                            <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag>
                                <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer">
                                <i class="material-icons pointer" (click)="deleteUploadedThumbnailImage()">
                                    close
                                </i>
                            </label>
                            <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail"
                                appDrag>
                                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="thumbnailSelectFile($event)" name="photo" accept="image/*" id="upload-thumbnail" />
                            </label>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-4 margin-auto">
                            <mat-form-field class="full-width">
                                <span>{{ 'title' | translate }}</span>
                                <input matInput formControlName="titleEn" />
                            </mat-form-field>
                        </div>
                            <div class="col-8 margin-auto">
                                <mat-form-field class="full-width">
                                    <span>{{ 'descriptionEn' | translate }}</span>
                                    <textarea rows="1" matInput formControlName="descriptionEn"> </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row text-center">
                    </div>
                    <div class="row text-center">
                        <div class="col-4 margin-auto">
                            <mat-form-field class="full-width">
                                <span>{{ 'title' | translate }}</span>
                                <input matInput formControlName="titleFr" />
                            </mat-form-field>
                        </div>
                            <div class="col-8 margin-auto">
                                <mat-form-field class="full-width">
                                    <span>{{ 'descriptionFr' | translate }}</span>
                                    <textarea rows="1" matInput formControlName="descriptionFr"> </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row text-center">
                    </div>
                    <div class="row text-center">
                        <div class="col-4 margin-auto">
                            <mat-form-field class="full-width">
                                <span>{{ 'title' | translate }}</span>
                                <input matInput formControlName="titleAr" />
                            </mat-form-field>
                        </div>
                            <div class="col-8 margin-auto">
                                <mat-form-field class="full-width">
                                    <span>{{ 'descriptionAr' | translate }}</span>
                                    <textarea rows="1" matInput formControlName="descriptionAr"> </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row text-center">
                    </div>
                    <div class="row max-width-row text-center">
                        <div class="col">
                            <br>
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid' color="primary">
                                <span *ngIf="!objectId">{{'addNewFeature' | translate}}</span>
                                <span *ngIf="objectId">{{'editFeature' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>