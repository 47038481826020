<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="text-center" *ngIf="!objectId">{{'addNewOffice' | translate}}
                        </h4>
                        <h4 class="text-center" *ngIf="objectId">{{'editOffice' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'global' | translate}}
                            </ng-template>
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'country' | translate}}</mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                (selectionChange)="getCitiesForCountry($event.value)"
                                                formControlName="country">
                                                <mat-option *ngFor="let g of countries"
                                                    [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'city' | translate}}</mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="city">
                                                <mat-option *ngFor="let g of cities"
                                                    [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'type' | translate}}</mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="type">
                                                <mat-option value="office">
                                                    {{ 'rechargeOffice' | translate }}
                                                </mat-option>
                                                <mat-option value="fillPoint">
                                                    {{ 'fillPoint' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='name' matInput
                                                placeholder="{{'name' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='phone' matInput
                                                placeholder="{{'phone' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <textarea rows="3" formControlName='address' matInput
                                                placeholder="{{'address' | translate}}"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>

                    <div class="row max-width-row text-center">
                        <div class="col">
                            <br>
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid'
                                color="primary">
                                <span *ngIf="!objectId">{{'addNewOffice' | translate}}</span>
                                <span *ngIf="objectId">{{'editOffice' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>