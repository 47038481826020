<div class="container container-top-margin">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center">{{'changePassword' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin: 50px auto;">
        <div class="col">
            <!-- <mat-card class="add-card"> -->
                <form [formGroup]='form'>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='password' type="password" matInput placeholder="{{'password' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='passwordConfirm' type="password" matInput placeholder="{{'confirmPassword' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="save()" [disabled]='!form.valid'
                                color="primary">
                                <span>{{'save' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            <!-- </mat-card> -->
        </div>
    </div>
</div>