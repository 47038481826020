import { TranslateService } from '@ngx-translate/core';
import { FileHandle } from './../../directives/dragDrop.directive';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  form: UntypedFormGroup;

  objectId: string;

  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      nameAr: new UntypedFormControl('', [Validators.required]),
      nameEn: new UntypedFormControl('', [Validators.required]),
      nameTr: new UntypedFormControl('', [Validators.required]),

      positionAr: new UntypedFormControl('', [Validators.required]),
      positionEn: new UntypedFormControl('', [Validators.required]),
      positionTr: new UntypedFormControl('', [Validators.required]),

      image: new UntypedFormControl('', []),

      desAr: new UntypedFormControl('', [Validators.required]),
      desEn: new UntypedFormControl('', [Validators.required]),
      desTr: new UntypedFormControl('', [Validators.required]),
    });

    this.objectId = this.activateRouter.snapshot.params.id;

    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneClient(this.objectId).subscribe((data: any) => {
      const s = data.data;
      this.form.patchValue(s);
      this.form.patchValue({ status: s.status + '' });

      this.thumbnailFileList = s.image;
      this.thumbnailUrlList = s.image;

    }, err => {
      this.snackBar.open(err);
    });
  }

  thumbnailSelectFile(event) {
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }


  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      this.full.addClient(this.form.value).subscribe((data: any) => {
        this.toastr.successToastr('تمت إضافة العميل بنجاح');
        this.router.navigateByUrl('/clients-list');
      }, err => {
        this.toastr.errorToastr(err);
      });
    } else {
      this.full.editOneClient(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.toastr.successToastr('حدث خطأ أثناء إضافة العميل');
          this.router.navigateByUrl('/clients-list');
        }, (error) => {
          this.toastr.errorToastr(error);
        });
    }
  }
}
