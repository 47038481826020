import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { FullServiceService } from '../../../services/full-service.service';
import { User, UserModel } from '../../../models/user';
import { SendSpecificNotiComponent } from '../../send-specific-noti/send-specific-noti.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';
import { AddPackageToUserComponent } from '../add-package-to-user/add-package-to-user.component';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';

@Component({
  selector: 'app-stuff-list',
  templateUrl: './stuff-list.component.html',
  styleUrls: ['./stuff-list.component.scss']
})
export class StuffListComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  verifiedForm: UntypedFormGroup;
  cityForm: UntypedFormGroup;
  promotedForm: UntypedFormGroup;
  data: UserModel[] = [];
  searchWord = '';

  cities = [];
  states = [];

  userFilter: UntypedFormGroup;

  displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'role',
    'active',
    'date',
    // 'edit',
    // 'delete',
    'changePassword'
  ];
  dataSource: MatTableDataSource<UserModel>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;
  // currentLang = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrManager,
    public dialog: MatDialog,
    private full: FullServiceService,
  ) {
  }

  ngOnInit() {
    this.userFilter = new UntypedFormGroup({
      active: new UntypedFormControl(null, []),
      role: new UntypedFormControl(null, []),
    })

    this.activeForm = new UntypedFormGroup({
      active: new UntypedFormControl('', [])
    });

    this.getAllStuff(1);
  }

  getAll() {
    this.userFilter.patchValue({
      active: null,
      role: null,
    })
    this.getAllStuff(1);
  }

  edit(id) {
    console.log('id', id);
    
    // let url = window.location.href.replace('users-list', `edit-user/${id}`);
    // window.open(url, '_blank')
  }

  getAllStuff(event: any) {
    this.userService.getAllStuffForAdmin(event.pageIndex + 1 || 1, event.pageSize, this.searchWord, this.userFilter.value).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  filter() {
    this.getAllStuff(1);
  }

  searchStuff() {
    this.getAllStuff(1);
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openImage(image: string): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: { image: image[0] }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  notiModal(id: string): void {
    const dialogRef = this.dialog.open(SendSpecificNotiComponent, {
      width: '750px',
      data: id
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  activate(user: User, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      active: event.checked
    });

    this.userService.changeStatusUser(user._id, this.activeForm.value).subscribe((data: any) => {
      user.active = event.checked;
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }
  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  delete(id) {
    this.full.deleteOneUser(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar(this.translate.instant('userDeletedSuccess'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }

  clearFilter() {
    this.userFilter.get('active').setValue(null);
    this.userFilter.get('role').setValue(null);
    this.getAllStuff(1);
  }
  chagngePasswordDialog(id: string){
    console.log('cchagngePasswordDialog', id);
    const dialogRef = this.dialog.open(ChangePasswordModalComponent, {
      width: '50vw',
      data: { userId: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('resuslts', result);
      
    });
  }
}
