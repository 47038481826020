<div
  [class.is-mobile]="mobileQuery.matches"
  [class]="'theme-wrapper ' + theme$"
>
  <!-- [class]="'theme-wrapper ' + (theme$ )" -->
  <mat-toolbar color="primary" class="toolbar mat-elevation-z8" *ngIf="auth.isLoggedIn()" [dir]="dir" >
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="app-name">{{ "app.title" | translate }}</h1>
    <div class="spacer"></div>

    <span class="settings">
      <button mat-button [matMenuTriggerFor]="animals">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #animals="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="profile">
          {{ "profile" | translate }}
        </button>
        <!-- <button mat-menu-item [matMenuTriggerFor]="language">{{ 'language' | translate }}</button> -->
        <!-- <button mat-menu-item [matMenuTriggerFor]="theme">{{ 'theme' | translate }}</button> -->
        <button mat-menu-item (click)="logOut()">
          {{ "logOut" | translate }}
        </button>
        <button mat-menu-item *ngFor="let l of languages" (click)="change(l.value)">{{ l.lang | translate }}</button>
      </mat-menu>

      <mat-menu #profile="matMenu">
        <button mat-menu-item routerLink="/profile">
          {{ "profile" | translate }}
        </button>
      </mat-menu>

      <!-- <mat-menu #language="matMenu">
        <button mat-menu-item *ngFor="let l of languages" (click)="change(l.value)">{{ l.lang | translate }}</button>
      </mat-menu> -->

      <!-- <mat-menu #theme="matMenu">
        <button mat-menu-item *ngFor="let th of themes" (click)="changeTheme(th.value)"> {{ th.theme | translate
          }}</button>
      </mat-menu> -->

      <!-- <mat-menu #logout="matMenu">
        <button mat-menu-item (click)="logOut()"> {{ 'logOut' | translate }}</button>
      </mat-menu> -->
    </span>
  </mat-toolbar>

  <mat-sidenav-container
    class="sidenav-container"
    [dir]="dir"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
  >
    <mat-sidenav
      #sidenav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
    >
      <div class="container-fluid" *ngIf="auth.isLoggedIn()">
        <div class="row text-center">
          <div class="col img-section-col">
            <div class="img-section">
              <img [src]="logo" />
            </div>
          </div>
        </div>

        <div class="row text-center side-title">
          <!-- routerLink='/profile' -->
          <div class="col">
            <h6 class="h6-responsive">{{ global?.userData?.name }}</h6>
          </div>
        </div>
      </div>

      <mat-nav-list *ngIf="auth.isLoggedIn()">
        <mat-expansion-panel *ngFor="let l of global?.links; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-title class="navs-title">
              <p>{{ l.label | translate }}</p>
            </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>
          <ul class="list-group list-group-items" [ngClass]="{ 'text-left': dir === 'ltr' }" >
            <li class="list-group-item pointer" *ngFor="let subIteml_1 of l.subLinks" [routerLink]="subIteml_1?.link" routerLinkActive="active" >
              {{ subIteml_1.label | translate }}
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="navs-title">
              <p>{{ "settings" | translate }}</p>
            </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>
          <ul class="list-group list-group-items" [ngClass]="{ 'text-left': dir === 'ltr' }" >
            <li class="list-group-item pointer" routerLink="/settings" routerLinkActive="active" [hidden]="userData?.role === 'editor'">
              {{ "settings" | translate }}
            </li>
            <li class="list-group-item pointer" (click)="logOut()">
              {{ "logout" | translate }}
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
