import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-add-client-list',
  templateUrl: './add-client-list.component.html',
  styleUrls: ['./add-client-list.component.scss']
})

export class AddClientListComponent implements OnInit {
  form: UntypedFormGroup;
  // communicationAttempt: FormGroup;

  userFilter: UntypedFormGroup;
  clientFilter: UntypedFormGroup;
  disabledBtn = true;

  usersSearch = '';
  users = [];
  communications = [];
  
  clientSearch = '';
  linkedUsers = [];
  interests = [];
  jobs = [];

  phonesArr = [];
  addressArr = [];

  phoneLinks = [];
  addressLinks = [];

  ages = [
    13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,,66,67,68,69,70
  ];

  objectId: string;

  downloadLink = false;

  phoneControl = new UntypedFormControl('', [
    Validators.required,
  ]);

  addressControl = new UntypedFormControl('', [
    Validators.required,
  ]);

  constructor(
    private full: FullServiceService,
    private userService: UserService,
    private router: Router,
    private translae: TranslateService,
    public dialog: MatDialog,
    private activateRouter: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.userFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      role: new UntypedFormControl('user', []),
      deleted: new UntypedFormControl(false, []),
      active: new UntypedFormControl(true, []),
      advertiserStatus: new UntypedFormControl(true, []),
    })
    this.clientFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
    })

    this.full.getAllInterests(1, '').subscribe((usersData: any) => {
      this.interests = usersData.data;
    }, (error) => { });

    this.full.getAllJobs(1, '').subscribe((usersData: any) => {
      this.jobs = usersData.data;
    }, (error) => { });
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      phones: new UntypedFormControl([''], []),
      // address: new FormControl([''], []),
      email: new UntypedFormControl('', []),
      jobs: new UntypedFormControl(null, []),
      age: new UntypedFormControl('', []),
      facebook: new UntypedFormControl('', []),
      instagram: new UntypedFormControl('', []),
      // twitter: new FormControl('', []),
      longitude: new UntypedFormControl('', []),
      latitude: new UntypedFormControl('', []),
      index: new UntypedFormControl('', []),
      gender: new UntypedFormControl('', []),
      additionalInfo: new UntypedFormControl('', []),
      services: new UntypedFormControl(null, []),
      
      interests: new UntypedFormControl(null, []),
      linkedUsers: new UntypedFormControl(null, []),
      
      communicationAttempts: new UntypedFormControl(null, []),
      // user: new FormControl('', [Validators.required]),
    });
    
    // this.communicationAttempt = new FormGroup({
      // type: new FormControl('', [Validators.required]),
      // date: new FormControl('', []),
      // result: new FormControl('', []),
      // notes: new FormControl('', []),
      // client: new FormControl('', [Validators.required]),
    // });
    
    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  pushToLinks() {
    this.phoneLinks.push(this.phoneControl.value);
    
    
    this.phonesArr.push(this.phoneControl.value);
    
    this.form.patchValue({
      phones: this.phonesArr,
    });
    this.phoneControl.reset();
  }
  
  pushToAddress() {
    this.addressLinks.push(this.addressControl.value);

    this.addressArr.push(this.addressControl.value);

    this.form.patchValue({
      address: this.addressArr
    });
    this.addressControl.reset();
  }

  deleteSelectedImg(formValue, index = null) {
    switch (formValue) {
      case 'phones':
        if (index !== null) {
          this.phonesArr.splice(index, 1);
          this.form.patchValue({
            phones: this.phonesArr
          });
        } else {
          this.form.get(formValue).reset();
        }
        break;
      case 'address':
        if (index !== null) {
          this.addressArr.splice(index, 1);
          this.form.patchValue({
            address: this.addressArr
          });
        } else {
          this.form.get(formValue).reset();
        }
        break;

      default:
        break;
    }
  }

  getObject(id: string) {
    this.full.getOneClientBase(this.objectId).subscribe((response: any) => {

      this.form.patchValue(response.data);

      this.form.patchValue({
        status: response.data.status + '',
        age: response.data.age + '',
      });
      this.phonesArr = response.data.phones;

      this.full.getAllUsersForDropDownWithSearch(1, 8, this.userFilter.value)
        .subscribe((usersData: any) => {
          this.users = usersData.data;
          this.userService.getOneUser(response.data.services).subscribe((userData: any) => {
            this.users.unshift(userData.data);
            this.form.patchValue({ services: response.data.services + '' })
          })
        }, (error) => {
      });

      this.full.getAllClientBase(1, 8, this.clientFilter.value)
        .subscribe((linkedUsersData: any) => {
          this.linkedUsers = linkedUsersData.data;
          this.full.getOneClientBase(response.data.linkedUsers).subscribe((linkedUserData: any) => {
            this.users.unshift(linkedUserData.data);
            this.form.patchValue({ linkedUsers: response.data.linkedUsers })
          })
        }, (error) => {
      });
   
    })
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      this.full.addClientBase(this.form.value).subscribe((data: any) => {
        this.router.navigateByUrl('/clients-list');
        this.full.openSnackBar(data.message || '', 'أغلق', 'success');
      }, err => {
        this.full.openSnackBar(err || '', 'أغلق', 'danger');
      });
    } else {
      this.full.editOneClientBase(this.objectId, this.form.value).subscribe((data: any) => {
        this.router.navigateByUrl('/clients-list');
        this.full.openSnackBar(data.message || '', 'أغلق', 'success');
      }, err => {
        this.full.openSnackBar(err || '', 'أغلق', 'danger');
      });
    }
  }

  // addEditCommunicationAttempt() {
  //   if (!this.communicationAttempt.valid) {
  //     return false;
  //   }

  //   if (!this.objectId) {
  //     this.full.addCommunicationAttempt(this.communicationAttempt.value).subscribe((data: any) => {
  //       this.communications.push(data.data)
  //       this.communicationAttempt.reset();
  //       this.full.openSnackBar(data.message || '', 'أغلق', 'success');
  //     }, err => {
  //       this.full.openSnackBar(err || '', 'أغلق', 'danger');
  //     });
  //   } else {
  //     this.full.editOneCommunicationAttempt(this.objectId, this.communicationAttempt.value).subscribe((data: any) => {
  //       this.communicationAttempt.reset();
  //       this.full.openSnackBar(data.message || '', 'أغلق', 'success');
  //     }, err => {
  //       this.full.openSnackBar(err || '', 'أغلق', 'danger');
  //     });
  //   }
  // }

  getAllCommunicationAttemotsForClient(id: string) {
    this.full.getCommunicationAttemptForClient(id)
      .subscribe((response: any) => {
        this.communications = response.data;
      }, (error) => {
      });
  }

  getAllUsers() {
    this.full.getAllUsersForDropDownWithSearch(1, 100, this.userFilter.value)
      .subscribe((response: any) => {
        this.users = response.data;
      }, (error) => {
      });
  }

  changeSearch() {
    if (this.usersSearch) {
      this.userFilter.patchValue({
        searchWord: this.usersSearch,
        advertiserStatus: true
      })
      this.full.getAllUsersForDropDown(1, 10, this.usersSearch, this.userFilter.value)
        .subscribe((response: any) => {
          this.users = response.data;
        }, (error) => {
        });
    }
  }

  getAllLinkedUsers() {
    this.full.getAllClientBase(1, 50, this.clientFilter.value)
      .subscribe((response: any) => {
        this.linkedUsers = response.data;
      }, (error) => {
      });
  }

  changeLinkedUsersSearch() {
    if (this.clientSearch) {
      this.clientFilter.patchValue({
        searchWord: this.clientSearch,
      })
      this.full.getAllClientBase(1, 50, this.clientFilter.value)
        .subscribe((response: any) => {
          this.linkedUsers = response.data;
        }, (error) => {
        });
    }
  }
}
