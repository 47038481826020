<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{ "JobsListManagement" | translate }}</h3>
                    </div>
                </div>
                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-job')">{{'addJob' | translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <div class="">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "name" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "date" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.createdAt | date: "short" }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "edit" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer"
                                    routerLink="/edit-job/{{ row._id }}">
                                    edit
                                </i>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                                translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <span (click)='confirmDialog(row._id)' class="pointer">{{'delete' |
                                    translate}}</span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>

                    <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                        [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="pageEvent = getArticles($event)">
                    </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>