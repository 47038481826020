<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center" *ngIf="!objectId">
                            {{'addNewClientsList' | translate}}
                        </h4>
                        <h4 class="h4-responsive text-center" *ngIf="objectId">{{'editClientsList' |
                            translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <mat-tab-group>
                        <mat-tab label="{{'global' | translate}}">
                            <div class="container">
                                <!-- <div class="row text-center">
                                    <div class="col-lg-6 col-12">
                                        <h5 color='primary'>
                                            <span>{{'maimImage' | translate}}</span>
                                        </h5>
                                        <label *ngIf="thumbnailFileList.length > 0" class="upload-label"
                                            for="upload-photo" appDrag (files)="filesDropped($event)">
                                            <img [src]="thumbnailUrlList" width="80%" alt="" class="pointer">
                                            <i class="material-icons pointer" (click)="deleteUploadedThumbnailImage()">
                                                close
                                            </i>
                                        </label>
                                        <label *ngIf="thumbnailFileList.length === 0" class="upload-label"
                                            for="upload-thumbnail" appDrag (files)="filesDropped($event)">
                                            <img class="pointer"
                                                src="https://www.greatmats.com/images/placeholder-all.png" width="80%"
                                                alt="">
                                            <input type="file" (change)="thumbnailSelectFile($event)" name="photo"
                                                id="upload-thumbnail" />
                                        </label>
                                    </div>

                                    <div class="col-lg-6 col-12">
                                        <h5 color='primary'>
                                            <span>{{'productImages' | translate}}</span>
                                        </h5>
                                        <label class="upload-label" for="photo" appDrag>
                                            <img [src]="waitingFiles[0]" width="80%" alt="" class="pointer"
                                                (click)='openImage(waitingFiles)'>
                                        </label>
                                        <label class="upload-label" for="upload-photo" appDrag
                                            (files)="filesDropped($event)">
                                            <img class="pointer"
                                                src="https://www.greatmats.com/images/placeholder-all.png" width="80%"
                                                alt="">
                                            <input type="file" (change)="selectFile($event)" name="photo"
                                                id="upload-photo" multiple />
                                        </label>
                                    </div>

                                </div> -->

                                <!-- <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'promoted' | translate}}</mat-label>
                                            <mat-select formControlName="promoted">
                                                <mat-option value="true">
                                                    {{ 'promotedEnable' | translate }}
                                                </mat-option>
                                                <mat-option value="false">
                                                    {{ 'promotedDisable' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div> -->

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='name' type="text" matInput
                                                placeholder="{{'name' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='email' type="text" matInput
                                                placeholder="{{'email' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <div class="col-5">
                                        <mat-form-field class="full-width">
                                            <input [formControl]="phoneControl" type="text" matInput
                                                placeholder="{{'phone' | translate}}">
                                        </mat-form-field>
                                    </div>

                                    <div class="col-2">
                                        <button mat-raised-button [disabled]='!phoneControl.valid'
                                            (click)='pushToLinks()' color="primary">
                                            <span>{{'addPhone' |
                                                translate}}</span>
                                        </button>
                                    </div>

                                    <div class="col-5">
                                        <ul class="ul-links">
                                            <li
                                                *ngFor="let i of form.get('phones')?.value; let index = index">
                                                <span class="close-video"
                                                    (click)="deleteSelectedImg('phones', index)">x
                                                </span>
                                                {{index + 1}}- {{i}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="row text-center">
                                    <div class="col-5">
                                        <mat-form-field class="full-width">
                                            <input [formControl]="addressControl" type="text"
                                                matInput placeholder="{{'addAddress' | translate}}">
                                        </mat-form-field>
                                    </div>

                                    <div class="col-2">
                                        <button mat-raised-button [disabled]='!addressControl.valid'
                                            (click)='pushToAddress()' color="primary">
                                            <span>{{'addAddress' |
                                                translate}}</span>
                                        </button>
                                    </div>

                                    <div class="col-5">
                                        <ul class="ul-links">
                                            <li
                                                *ngFor="let i of form.get('address')?.value; let index = index">
                                                <span class="close-video"
                                                    (click)="deleteSelectedImg('address', index)">x
                                                </span>
                                                {{index + 1}}- {{i}}
                                            </li>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="row text-center">
                                    <!-- <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='job' type="text" matInput
                                                placeholder="{{'job' | translate}}">
                                        </mat-form-field>
                                    </div> -->
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'age' | translate}}
                                            </mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="age">
                                                <mat-option *ngFor="let a of ages" [value]="a">
                                                    {{ a }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='age' type="text" matInput
                                                placeholder="{{'age' | translate}}">
                                        </mat-form-field>
                                    </div> -->
                                </div>

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='facebook' type="text" matInput
                                                placeholder="{{'facebook' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='instagram' type="text" matInput
                                                placeholder="{{'instagram' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='longitude' type="text" matInput
                                                placeholder="{{'longitude' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='latitude' type="text" matInput
                                                placeholder="{{'latitude' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <!-- <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='twitter' type="text" matInput
                                                placeholder="{{'twitter' | translate}}">
                                        </mat-form-field>
                                    </div> -->
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'gender' | translate}}
                                            </mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="gender">
                                                <mat-option value="male">
                                                    ذكر
                                                </mat-option>
                                                <mat-option value="female">
                                                    أنثى
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='index' type="text" matInput
                                                placeholder="{{'index' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'releatedServices' | translate}}
                                            </mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="services">
                                                <mat-option>
                                                    <ngx-mat-select-search [(ngModel)]="usersSearch"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [placeholderLabel]="''"
                                                        (ngModelChange)="changeSearch()">
                                                        <mat-icon ngxMatSelectSearchClear>delete
                                                        </mat-icon>
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let g of users" [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'linkedClients' | translate}}
                                            </mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="linkedUsers" multiple>
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                        [(ngModel)]="clientSearch"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [placeholderLabel]="''"
                                                        (ngModelChange)="changeLinkedUsersSearch()">
                                                        <mat-icon ngxMatSelectSearchClear>delete
                                                        </mat-icon>
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let g of linkedUsers"
                                                    [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'interests' | translate}}
                                            </mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="interests" multiple>
                                                <mat-option *ngFor="let g of interests"
                                                    [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'jobs' | translate}}
                                            </mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="jobs" multiple>
                                                <mat-option *ngFor="let g of jobs" [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <textarea rows="10" formControlName='additionalInfo'
                                                type="number" matInput
                                                placeholder="{{'additionalInfo' | translate}}"> </textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>

                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid'
                                color="primary">
                                <span *ngIf="!objectId">{{'addNewClientsList' | translate}}</span>
                                <span *ngIf="objectId">{{'editClientsList' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>