import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-add-client-address',
  templateUrl: './add-client-address.component.html',
  styleUrls: ['./add-client-address.component.scss']
})
export class AddClientAddressComponent implements OnInit {
  deletedImages = [];
  form: UntypedFormGroup;
  id = '';
  brand: any;

  clientFilter: UntypedFormGroup;
  linkedUsers = [];
  clientSearch = '';
  
  states = [];
  cities = [];

  catssearchWord = '';
  stateSearchWord = '';
  cityId = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public dialog: MatDialog,
    private full: FullServiceService,
    private toastr: ToastrManager
  ) {
    this.clientFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
    })

    this.getCitiesNames();
   }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;

    this.form = new UntypedFormGroup({
      city: new UntypedFormControl('', [Validators.required]),
      state: new UntypedFormControl('', []),
      address: new UntypedFormControl('', []),
      client: new UntypedFormControl('', [Validators.required]),
    });

    if (this.id) {
      this.full.getOneClientAddress(this.id).subscribe((data: any) => {
        if (!data) {
          this.toastr.successToastr(this.translate.instant('addedSuccessfully'));
          this.router.navigateByUrl('/client-address-list');
          return;
        } else {
          this.brand = data.data;

          this.form.patchValue(this.brand);
          this.form.patchValue({
            city: this.brand.city + '',
            state: this.brand.state + '',
          });

          this.full.getAllSubsForCity(1, 1000, this.brand.city).subscribe((data: any) => {
            this.states = data.data;
            this.form.patchValue({ state: this.brand.state })
          })
        }
      });
    }

    
    


    this.full.getAllClientBase(1, 8, this.clientFilter.value)
        .subscribe((linkedUsersData: any) => {
          this.linkedUsers = linkedUsersData.data;
          
        }, (error) => {
      });
  }

  getCitiesNames() {
    this.full.getAllParentCitiesForDropdown(1, 500)
      .subscribe((response: any) => {
        this.cities = response.data;
      }, (error) => {
      });
  }

  changeStatesSearch() {
    this.getStatesForCity(this.cityId, this.stateSearchWord);
  }

  getStatesForCity(id, searchWord = '') {
    this.cityId = id;
    this.full.getAllSubsForCity(1, 1000, this.cityId, searchWord).subscribe((data: any) => {
      this.states = data.data;
    })
  }


  addOrEdit() {
    if (!this.id) {
      this.full.addClientAddress(this.form.value).subscribe(
        (message: string) => {
          this.router.navigateByUrl('/client-address-list');
        },
        err => { }
      );
    } else {
      this.full.editOneClientAddress(this.id, this.form.value).subscribe(
        (message: string) => {
          this.router.navigateByUrl('/client-address-list');
        },
        err => { }
      );
    }
  }

  getAllLinkedUsers() {
    this.full.getAllClientBase(1, 50, this.clientFilter.value)
      .subscribe((response: any) => {
        this.linkedUsers = response.data;
      }, (error) => {
      });
  }

  changeLinkedUsersSearch() {
    if (this.clientSearch) {
      this.clientFilter.patchValue({
        searchWord: this.clientSearch,
      })
      this.full.getAllClientBase(1, 50, this.clientFilter.value)
        .subscribe((response: any) => {
          this.linkedUsers = response.data;
        }, (error) => {
        });
    }
  }

  valid(controlName, errorName) {
    return (
      this.form.get(controlName).hasError(errorName) &&
      (this.form.get(controlName).dirty || this.form.get(controlName).touched)
    );
  }
}
