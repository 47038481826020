<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'transersList' | translate}}</h3>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="orderId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'orderId' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.orderId}} </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'fullName' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.userId?.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.userId?.phone}} </td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'value' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.value}}&nbsp;$ </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'type' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.type | translate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="monyType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'monyType' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.monyType | translate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'status' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" class="pointer"
                            (click)='addStatus(row._id)'> {{row.status |
                            translate}}</td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.createdAt| date:'short'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="detailes">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'show' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" (click)='orderDetailes(row)'>
                                visibility
                            </i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getAllContacts($event)"></mat-paginator>
            </mat-card>
        </div>
    </div>
</div>