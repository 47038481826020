<div class="container container-top-margin">
    <div class="row">
        <div class="col">
            <h4 class="text-center">{{'editComment' | translate}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <form [formGroup]='form'>
                <div class="row text-center">
                    <div class="col">
                        <mat-form-field class="full-width">
                            <input formControlName='comment' matInput placeholder="{{'comment' | translate}}">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row text-center">
                    <div class="col">
                        <button mat-raised-button (click)="edit()" [disabled]='!form.valid' color="primary">
                            <span>{{'editComment' | translate}}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>