<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'homeVideosList' | translate}}</h3>
                    </div>
                </div>
                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-home-video')">{{'addHomeVideo' | translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <!-- <div class="spacer"></div>
                        <mat-slide-toggle>حالة الصنف</mat-slide-toggle> -->
                    </div>
                </div>

                <div class="">
                    <!-- mat-elevation-z8 -->
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'title' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.title}} </td>
                        </ng-container>

                        <ng-container matColumnDef="subTitle">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'subTitle' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.subTitle | slice:0:20}} ..</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'status' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-slide-toggle [checked]="row.status" (change)="activate(row, $event)">
                                    <span class="green" [hidden]='row.status === false'>{{'active' | translate}}</span>
                                    <span class="red" [hidden]='!row.status === false'>{{'inActive' | translate}}</span>
                                </mat-slide-toggle>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer" routerLink='/edit-home-video/{{row._id}}'>
                                    edit
                                </i>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = getData($event)">
                    </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>