<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center">{{'editSetings' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <div class="container">
                        <div class="progress form-group">
                            <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar"
                                [style.width.%]="progress">
                            </div>
                        </div>
                        <div class="row text-center">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <h5 color='primary'>
                                    <span>{{'notiImage' | translate}}</span>
                                </h5>
                                <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo"
                                    appDrag>
                                    <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer">
                                    <i class="material-icons pointer" (click)="deleteUploadedThumbnailImage()">
                                        close
                                    </i>
                                </label>
                                <label *ngIf="thumbnailFileList.length === 0" class="upload-label"
                                    for="upload-thumbnail" appDrag>
                                    <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png"
                                        width="80%" alt="">
                                    <input type="file" (change)="thumbnailSelectFile($event)" name="photo"
                                        id="upload-thumbnail" />
                                </label>
                            </div>
                        </div>
                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input formControlName='headerTitel' matInput
                                        placeholder="{{'headerTitel' | translate}}">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <textarea rows="5" formControlName='headerDes' matInput
                                        placeholder="{{'headerDes' | translate}}"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input formControlName='aboutTitel' matInput
                                        placeholder="{{'aboutTitel' | translate}}">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <textarea rows="5" formControlName='aboutDes' matInput
                                        placeholder="{{'aboutDes' | translate}}"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input formControlName='featersTitle' matInput
                                        placeholder="{{'featersTitle' | translate}}">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <textarea rows="5" formControlName='featersDes' matInput
                                        placeholder="{{'featersDes' | translate}}"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-right">
                            <div class="col-11">
                                <mat-form-field class="full-width">
                                    <input formControlName='feat' minlength='5' matInput
                                        placeholder="{{'features' | translate}}">
                                </mat-form-field>
                                <p *ngFor='let f of featArr'>
                                    -&nbsp;{{f}}
                                </p>
                            </div>
                            <div class="col-1 marign-auto">
                                <button mat-raised-button (click)="addFeat()" color="primary">
                                    <span>{{'add' | translate}}</span>
                                </button>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input formControlName='featersSecondTitle' matInput
                                        placeholder="{{'featersSecondTitle' | translate}}">
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input formControlName='videoTitle' matInput
                                        placeholder="{{'videoTitle' | translate}}">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <textarea rows="5" formControlName='videoDes' matInput
                                        placeholder="{{'videoDes' | translate}}"></textarea>
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input formControlName='videoLink' matInput
                                        placeholder="{{'videoLink' | translate}}">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input formControlName='musicTitle' matInput
                                        placeholder="{{'musicTitle' | translate}}">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <textarea rows="5" formControlName='musicDes' matInput
                                        placeholder="{{'musicDes' | translate}}"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input formControlName='contactTitle' matInput
                                        placeholder="{{'contactTitle' | translate}}">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <textarea rows="5" formControlName='contactDes' matInput
                                        placeholder="{{'contactDes' | translate}}"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <textarea rows="5" formControlName='downloadDes' matInput
                                        placeholder="{{'downloadDes' | translate}}"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col">
                                <br>
                                <br>
                                <button mat-raised-button (click)="edit()" [disabled]='!form.valid' color="primary">
                                    <span>{{'edit' | translate}}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>