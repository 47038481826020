<div class="container-fluid box">
    <div class="row text-center">
        <div class="col">
            <h6 class="bold">تفاصيل طلب الشحن</h6>
        </div>
    </div>
    <div class="row text-right">
        <div class="col">
            <h6 class="bold">معلومات المستخدم</h6>
            <hr>
        </div>
    </div>
    <div class="row text-right">
        <div class="col">
            <p>الأسم: {{order.userId?.name}}</p>
            <p>رقم الهاتف: {{order.userId?.phone}}</p>
            <p>البريد الإلكتروني: {{order.userId?.email}}</p>
            <p>الرصيد الحالي: {{order.userId?.balance}}&nbsp;$</p>
        </div>
    </div>
    <div class="row text-right">
        <div class="col">
            <h6 class="bold">تفاصيل العملية</h6>
            <hr>
        </div>
    </div>
    <div class="row text-right">
        <div class="col-2">
            <a [href]="order?.image" target="_blank">
                <img [src]="order?.image | secure | async" width="100%" alt="صورة الحوالة">
            </a>
        </div>
        <div class="col-10">
            <h6 class="bold">{{order.type | translate}}</h6>
            <h6 class="bold">{{order.monyType | translate}}</h6>
            <p style="font-size: .9rem;" [title]="order?.notes">
                {{order?.notes | slice:0:999}}
            </p>
            <h5>
                {{order?.value}}&nbsp;$
            </h5>
            <hr>
        </div>
    </div>
    <div class="row text-right">
        <div class="col">
            <p>القيمة بعد الموافقة: {{order.userId.balance + order.value }}&nbsp;$</p>
        </div>
    </div>
    <div class="row text-right">
        <div class="col">
            <button mat-raised-button (click)="editOrder('completed')" color="primary">
                {{ 'compleateOrder' | translate }}
            </button>
            &nbsp;
            &nbsp;
            <button mat-raised-button (click)="editOrder('canceled')" color="primary">
                {{ 'cancelOrder' | translate }}
            </button>
        </div>
    </div>
</div>