<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center" *ngIf="!objectId">{{'addNewProduct' |
                            translate}}
                        </h4>
                        <h4 class="h4-responsive text-center" *ngIf="objectId">{{'editProduct' |
                            translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <mat-tab-group>
                        <mat-tab label="{{'global' | translate}}">
                            <div class="container">
                                <div class="row text-center">
                                    <div class="col-lg-6 col-12">
                                        <h5 color='primary'>
                                            <span>{{'maimImage' | translate}}</span>
                                        </h5>
                                        <label *ngIf="thumbnailFileList.length > 0"
                                            class="upload-label" for="upload-photo" appDrag
                                            (files)="filesDropped($event)">
                                            <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer">
                                            <i class="material-icons pointer"
                                                (click)="deleteUploadedThumbnailImage()">
                                                close
                                            </i>
                                        </label>
                                        <label *ngIf="thumbnailFileList.length === 0"
                                            class="upload-label" for="upload-thumbnail" appDrag
                                            (files)="filesDropped($event)">
                                            <img class="pointer"
                                                src="https://www.greatmats.com/images/placeholder-all.png"
                                                width="80%" alt="">
                                            <input type="file"
                                                (change)="thumbnailSelectFile($event)" name="photo"
                                                id="upload-thumbnail" />
                                        </label>
                                    </div>

                                    <!-- <div class="col-lg-6 col-12">
                                        <h5 color='primary'>
                                            <span>{{'productImages' | translate}}</span>
                                        </h5>
                                    <label class="upload-label" for="photo" appDrag>
                                        <img [src]="waitingFiles[0]" width="80%" alt=""
                                            class="pointer" (click)='openImage(waitingFiles)'>
                                    </label>
                                    <label class="upload-label" for="upload-photo" appDrag
                                        (files)="filesDropped($event)">
                                        <img class="pointer"
                                            src="https://www.greatmats.com/images/placeholder-all.png"
                                            width="80%" alt="">
                                        <input type="file" (change)="selectFile($event)"
                                            name="photo" id="upload-photo" multiple />
                                    </label>
                                </div> -->

                                </div>

                                <!-- <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'parentCategory' | translate}}</mat-label>
                                            <mat-select formControlName='parentCat' (selectionChange)='getSubs($event)'>
                                                <mat-option *ngFor="let category of categories" [value]="category._id">
                                                    {{category.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'subCategory' | translate}}</mat-label>
                                            <mat-select formControlName="category">
                                                <mat-option *ngFor="let s of subs" [value]="s._id">
                                                    {{s.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div> -->

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'promoted' | translate}}</mat-label>
                                            <mat-select formControlName="promoted">
                                                <mat-option value="true">
                                                    {{ 'promotedEnable' | translate }}
                                                </mat-option>
                                                <mat-option value="false">
                                                    {{ 'promotedDisable' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'isProductVerified' |
                                                translate}}</mat-label>
                                            <mat-select formControlName="verified">
                                                <mat-option value="true">
                                                    {{ 'verified' | translate }}
                                                </mat-option>
                                                <mat-option value="false">
                                                    {{ 'notVerified' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'status' | translate}}</mat-label>
                                            <mat-select formControlName="status">
                                                <mat-option value="true">
                                                    {{ 'active' | translate }}
                                                </mat-option>
                                                <mat-option value="false">
                                                    {{ 'unActive' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'type' | translate}}</mat-label>
                                            <mat-select formControlName="type">
                                                <mat-option value="product">
                                                    {{ 'product' | translate }}
                                                </mat-option>
                                                <mat-option value="poster">
                                                    {{ 'poster' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->
                                </div>

                                <!-- <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='code' matInput
                                                placeholder="{{'productCode' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div> -->

                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'user' | translate}}</mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="user">
                                                <mat-option>
                                                    <ngx-mat-select-search [(ngModel)]="usersSearch"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [placeholderLabel]="''"
                                                        (ngModelChange)="changeSearch()">
                                                        <mat-icon
                                                            ngxMatSelectSearchClear>delete</mat-icon>
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let g of users" [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='price' type="number" matInput
                                                placeholder="{{'price' | translate}}">
                                        </mat-form-field>
                                    </div> -->
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='posterLink' matInput
                                                placeholder="{{'posterLink' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <!--   <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='posterLinkTitleAr' matInput
                                                placeholder="{{'posterLinkTitleAr' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='posterLinkTitleEn' matInput
                                                placeholder="{{'posterLinkTitleEn' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div> -->

                            </div>
                        </mat-tab>

                        <mat-tab label="{{'info' | translate}}">
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='name' matInput
                                                placeholder="{{'name' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <textarea rows='6' formControlName='shortDes' matInput
                                                placeholder="{{'shortDes' | translate}}"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div> -->
                                <div class="row text-right">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <textarea rows='15' formControlName='des' matInput
                                                placeholder="{{'des' | translate}}"></textarea>
                                        </mat-form-field>
                                        <!-- <span>{{'des' | translate}}</span>
                                        <ckeditor formControlName='des' [editor]="Editor"
                                            [config]='config'>
                                        </ckeditor> -->
                                    </div>
                                </div>
                            </div>
                        </mat-tab>

                        <mat-tab label="{{'notification' | translate}}">
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'notificationStatus' |
                                                translate}}</mat-label>
                                            <mat-select formControlName="ifNoti">
                                                <mat-option value="true">
                                                    {{ 'sendNotification' | translate }}
                                                </mat-option>
                                                <mat-option value="false">
                                                    {{ 'dontSendNotification' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row" [hidden]='form.get("ifNoti").value === "false"'>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='notiTitle' matInput
                                                placeholder="{{'notiTitle' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row" [hidden]='form.get("ifNoti").value === "false"'>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <textarea rows='6' formControlName='notiBody' matInput
                                                placeholder="{{'notiBody' | translate}}"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>

                        <!-- <mat-tab label="{{'english' | translate}}">
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='nameEn' matInput
                                                placeholder="{{'name' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <textarea rows='6' formControlName='shortDesEn' matInput
                                                placeholder="{{'shortDes' | translate}}"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row text-right">
                                    <div class="col">
                                        <span>{{'des' | translate}}</span>
                                        <ckeditor formControlName='desEn' [editor]="Editor"></ckeditor>
                                    </div>
                                </div>
                            </div>
                        </mat-tab> -->

                        <!-- <mat-tab label="{{'turkish' | translate}}">
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='nameTr' matInput
                                                placeholder="{{'name' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <textarea rows='6' formControlName='shortDesTr' matInput
                                                placeholder="{{'shortDes' | translate}}"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row text-right">
                                    <div class="col">
                                        <span>{{'des' | translate}}</span>
                                        <ckeditor formControlName='desTr' [editor]="Editor"></ckeditor>
                                    </div>
                                </div>
                            </div>
                        </mat-tab> -->
                    </mat-tab-group>

                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid'
                                color="primary">
                                <span *ngIf="!objectId">{{'addNewProduct' | translate}}</span>
                                <span *ngIf="objectId">{{'editProduct' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>