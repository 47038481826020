<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="text-center" *ngIf="!objectId">{{'addNewCode' | translate}}
                        </h4>
                        <h4 class="text-center" *ngIf="objectId">{{'editCode' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'global' | translate}}
                            </ng-template>
                            <div class="container">
                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='code' matInput
                                                placeholder="{{'code' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='value' type="number" matInput
                                                placeholder="{{'value' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='useLength' type="number"
                                                matInput placeholder="{{'useLength' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <textarea rows="3" formControlName='notes' matInput
                                                placeholder="{{'notes' | translate}}"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>

                    <div class="row max-width-row text-center">
                        <div class="col">
                            <br>
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid'
                                color="primary">
                                <span *ngIf="!objectId">{{'addNewCode' | translate}}</span>
                                <span *ngIf="objectId">{{'editCode' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>