<div class="container" style="direction: rtl; text-align: right; padding-top: 3rem;">
    <div class="row">
        <div class="col">
            <form [formGroup]="form">
                <div class="row lang ">
                    <div class="col-12">
                        <div class="example-container">
                            <mat-form-field style="text-align: right;">
                                <span>الحالة</span>
                                <mat-select formControlName="status" style="text-align: right;">
                                    <mat-option *ngFor="let s of defaultStatus" [value]="s"> {{ s |
                                        translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button mat-raised-button (click)="editOrder()" [disabled]='!form.valid'
                            color="primary">
                            {{ 'addStatus' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>