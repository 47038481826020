<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'users' | translate}}</h3>
                    </div>
                </div>

                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-user')">{{'addNewUser' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <button mat-raised-button (click)="filter()" color="primary">
                            <span>{{'filterUsers' | translate}}</span>
                        </button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <button mat-raised-button (click)="getAll()" color="primary">
                            <span>{{'getAllUsers' | translate}}</span>
                        </button>
                    </div>

                    <!-- <div class="col-lg-2">
                        <button mat-raised-button (click)="filter()" color="primary">
                            <span>{{'filterUsers' | translate}}</span>
                        </button>
                    </div>
                    <div class="col-lg-2">
                        <button mat-raised-button (click)="getAll()" color="primary">
                            <span>{{'getAllUsers' | translate}}</span>
                        </button>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="full-width">
                            <mat-label>{{'searchInUsers' | translate}}</mat-label>
                            <input matInput type="text" [(ngModel)]="searchWord"
                                (input)='searchUsers()'>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <form [formGroup]='userFilter'>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-2">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'isVerified' | translate}}</mat-label>
                                            <mat-select formControlName='verified'>
                                                <mat-option value='true'>
                                                    {{'verified' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'notVerified' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'promoted' | translate}}</mat-label>
                                            <mat-select formControlName='promoted'>
                                                <mat-option value='true'>
                                                    {{'isPromoted' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'notPromoted' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'advertiserStatus' | translate}}
                                            </mat-label>
                                            <mat-select formControlName='advertiserStatus'>
                                                <mat-option value='true'>
                                                    {{'advertiser' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'user' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'status' | translate}}</mat-label>
                                            <mat-select formControlName='active'>
                                                <mat-option value='true'>
                                                    {{'active' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'inActive' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'role' | translate}}</mat-label>
                                            <mat-select formControlName='role'>
                                                <mat-option value='owner'>
                                                    {{'owner' | translate}}
                                                </mat-option>
                                                <mat-option value='admin'>
                                                    {{'admin' | translate}}
                                                </mat-option>
                                                <mat-option value='user'>
                                                    {{'user' | translate}}
                                                </mat-option>
                                                <mat-option value='editor'>
                                                    {{'editor' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col-lg-2">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'city' | translate}}</mat-label>
                                            <mat-select formControlName='city' ful
                                                (selectionChange)="getStatesForCity($event.value)">
                                                <mat-option *ngFor='let c of cities'
                                                    [value]='c._id'>
                                                    {{ c?.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'state' | translate}}</mat-label>
                                            <mat-select formControlName='state'>
                                                <mat-option *ngFor='let c of states'
                                                    [value]='c._id'>
                                                    {{ c?.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->
                                </div>
                                <!-- <div class="row">
                                   
                                </div> -->
                            </div>
                        </form>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>

                    <!-- phone Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" class="uni"> {{row.phone}} </td>
                    </ng-container>
                    <ng-container matColumnDef="qrNum">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'qrNum' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row?.qrNum}} </td>
                    </ng-container>

                    <!-- email Column -->
                    <!-- <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.email}}
                        </td>
                    </ng-container> -->
                    <ng-container matColumnDef="followers">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'followers' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.countFollowers}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'role' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.role | translate}}&nbsp;|&nbsp;<span
                                *ngIf='row.advertiserStatus'>{{'advertiser' |
                                translate}}</span><span *ngIf='!row.advertiserStatus'>{{'normal' |
                                translate}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'status' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-slide-toggle *ngIf='row.role !== "owner"' color="primary"
                                [checked]="row.active" (change)="activate(row, $event)">
                                <span class="green" [hidden]='row.active !== true'>{{'active' |
                                    translate}}</span>
                                <span class="red" [hidden]='row.active !== false'>{{'inActive' |
                                    translate}}</span>
                            </mat-slide-toggle>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="promoted">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'promoted' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span>
                                <mat-slide-toggle color="primary" [checked]="row?.promoted"
                                    (change)="changePromoted(row, $event)">
                                    <span class="green"
                                        [hidden]='row?.promoted !== true'>{{'isPromoted' |
                                        translate}}</span>
                                    <span class="red"
                                        [hidden]='row?.promoted !== false'>{{'notPromoted' |
                                        translate}}</span>
                                </mat-slide-toggle>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="verified">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'verified' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span>
                                <mat-slide-toggle color="primary" [checked]="row?.verified"
                                    (change)="changeVerified(row, $event)">
                                    <span class="green"
                                        [hidden]='row?.verified !== true'>{{'verified' |
                                        translate}}</span>
                                    <span class="red"
                                        [hidden]='row?.verified !== false'>{{'notVerified' |
                                        translate}}</span>
                                </mat-slide-toggle>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="comments">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'comments' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer"
                                routerLink='/user/comments/{{row._id}}'>
                                visibility
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="notification">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'notification' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" (click)='notiModal(row._id)'>
                                visibility
                            </i>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="gallery">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'gallery' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" (click)='filterGallery(row._id)'>
                                visibility
                            </i>
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" *ngIf='row.role !== "owner"'
                                (click)='edit(row._id)'>
                                edit
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf='row.role !== "owner"' (click)='confirmDialog(row._id)'
                                class="pointer">{{'delete' | translate}}</span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getAllUsers($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>