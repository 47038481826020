<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'products' | translate}}</h3>
                    </div>
                </div>

                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-product')">{{'addNewProduct' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <div class="row text-center">
                    <div class="col-lg-3 col-12">
                        <mat-form-field class="full-width">
                            <mat-label>{{'user' | translate}}</mat-label>
                            <mat-select placeholder="{{ '' | translate }}">
                                <mat-option>
                                    <ngx-mat-select-search [(ngModel)]="usersSearch"
                                        [ngModelOptions]="{ standalone: true }"
                                        [placeholderLabel]="''" (ngModelChange)="changeSearch()">
                                        <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option (click)='filterByUser("")'>
                                    {{ 'showAll' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let g of users" [value]="g._id"
                                    (click)='filterByUser(g._id)'>
                                    {{ g.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-12">
                        <mat-form-field class="example-form-field">
                            <mat-label>{{'searchInAds' | translate}}</mat-label>
                            <input matInput type="text" [(ngModel)]="searchWord"
                                (input)='searchAds()'>
                            <button *ngIf="searchWord" matSuffix mat-icon-button aria-label="Clear"
                                (click)="searchWord=''">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <!-- <div class="col-lg-2 col-12">
                        <mat-form-field class="example-form-field">
                            <mat-label>{{'minPrice' | translate}}</mat-label>
                            <input matInput type="number" [(ngModel)]="minPrice" (input)='searchAds()'>
                            <button *ngIf="minPrice" matSuffix mat-icon-button aria-label="Clear" (click)="minPrice=0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-12">
                        <mat-form-field class="example-form-field">
                            <mat-label>{{'maxPrice' | translate}}</mat-label>
                            <input matInput type="number" [(ngModel)]="maxPrice" (input)='searchAds()'>
                            <button *ngIf="maxPrice" matSuffix mat-icon-button aria-label="Clear"
                                (click)="maxPrice=999999999999">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div> -->
                </div>

                <div class="row">
                    <div class="col">
                        <form [formGroup]='filterForm'>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'isVerified' | translate}}</mat-label>
                                            <mat-select formControlName='verified'>
                                                <mat-option value='true'>
                                                    {{'verified' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'notVerified' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'promoted' | translate}}</mat-label>
                                            <mat-select formControlName='promoted'>
                                                <mat-option value='true'>
                                                    {{'isPromoted' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'notPromoted' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'status' | translate}}</mat-label>
                                            <mat-select formControlName='status'>
                                                <mat-option value='true'>
                                                    {{'active' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'inActive' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <button mat-raised-button (click)="filter()"
                                            color="primary">
                                            <span>{{'filterProducts' | translate}}</span>
                                        </button>
                                    </div>
                                    <div class="col-lg-2">
                                        <button mat-raised-button (click)="getAll()"
                                            color="primary">
                                            <span>{{'getAllUsers' | translate}}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'id' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row; let i = index"> {{ i+1 }} </td>
                    </ng-container>

                    <ng-container *ngFor='let t of tabelKeys' [matColumnDef]="t?.label">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{t?.label |
                            translate}} </th>

                        <td mat-cell *matCellDef="let row">
                            <span *ngIf='t?.type === "text"' [title]='row[t?.key]'> {{row[t?.key] |
                                slice:0:40}} ..
                            </span>
                            <span *ngIf='t?.type === "number"'> {{row[t?.key] }} </span>

                            <span *ngIf='t?.type === "translated-text"'> {{row[t?.key] | translate}}
                            </span>


                            <span *ngIf='t?.type === "date"'> {{row[t?.key] | date : 'short'}}
                            </span>

                            <!-- <span *ngIf='t?.type === "object"'> {{row[t?.key][t?.child]}} </span> -->

                            <span *ngIf='t?.type === "boolean"'>
                                <mat-slide-toggle color="primary" [checked]="row[t?.key]"
                                    (change)="activate(row, $event)">
                                    <span class="green" [hidden]='row[t?.key] !== true'>{{'active' |
                                        translate}}</span>
                                    <span class="red" [hidden]='row[t?.key] !== false'>{{'inActive'
                                        | translate}}</span>
                                </mat-slide-toggle>
                            </span>

                            <span *ngIf='t?.type === "img"'>
                                <i class="material-icons pointer" (click)="openImage(row[t?.key])">
                                    visibility
                                </i>
                            </span>

                            <span *ngIf='t?.type === "innerHtml"' class="inner-span">
                                <span [innerHtml]='row[t?.key] | slice:0:15'></span>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="promoted">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'promoted' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span>
                                <mat-slide-toggle color="primary" [checked]="row?.promoted"
                                    (change)="changePromoted(row, $event)">
                                    <span class="green"
                                        [hidden]='row?.promoted !== true'>{{'isPromoted' |
                                        translate}}</span>
                                    <span class="red"
                                        [hidden]='row?.promoted !== false'>{{'notPromoted' |
                                        translate}}</span>
                                </mat-slide-toggle>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="verified">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'verified' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span>
                                <mat-slide-toggle color="primary" [checked]="row?.verified"
                                    (change)="changeVerified(row, $event)">
                                    <span class="green"
                                        [hidden]='row?.verified !== true'>{{'verified' |
                                        translate}}</span>
                                    <span class="red"
                                        [hidden]='row?.verified !== false'>{{'notVerified' |
                                        translate}}</span>
                                </mat-slide-toggle>
                            </span>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="promoted">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'promoted' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span class="green" [hidden]='row.promoted !== true'>{{'isPromoted' | translate}}</span>
                            <span class="red" [hidden]='row.promoted !== false'>{{'inPromoted' | translate}}</span>
                        </td>
                    </ng-container> -->


                    <!-- <ng-container matColumnDef="copy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'copy' | translate}} </th>
                        <td mat-cell *matCellDef="let row" (click)="copy(row)">
                            {{'copy' | translate}}
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="comments">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'comments' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" routerLink='/comments/{{row._id}}'>
                                visibility
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <a (click)='edit(row._id)'>
                                <i class="material-icons pointer">
                                    edit
                                </i>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span (click)='confirmDialog(row._id)' class="pointer">{{'delete' |
                                translate}}</span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getData($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>