<div class="container container-top-margin add-container">
    <div class="row">
      <div class="col">
        <div class="page-head">
          <div class="flex flex-centerd">
            <i class="uil uil-book-medical"></i>
            <div class="flex-column">
              <h4 class="h4-responsive text-center" *ngIf="!objectId">
                {{ "addNewCity" | translate }}
              </h4>
              <h4 class="h4-responsive text-center" *ngIf="objectId">
                {{ "editCity" | translate }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-card class="add-card">
          <form [formGroup]="form">
            <div class="row text-center">
              <div class="col-12 margin-auto">
                <mat-form-field class="full-width">
                  <input
                    formControlName="nameEn"
                    matInput
                    placeholder="{{ 'nameEn' | translate }}"
                  />
                </mat-form-field>
              </div>
              </div>
              <div class="row text-center">
              <div class="col-12 margin-auto">
                <mat-form-field class="full-width">
                  <input
                    formControlName="nameFr"
                    matInput
                    placeholder="{{ 'nameFr' | translate }}"
                  />
                </mat-form-field>
              </div>
              </div>
              <div class="row text-center">
              <div class="col-12 margin-auto">
                <mat-form-field class="full-width">
                  <input
                    formControlName="nameAr"
                    matInput
                    placeholder="{{ 'nameAr' | translate }}"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row text-center">
              <div class="col">
                <br />
                <br />
                <br />
                <button
                  mat-raised-button
                  (click)="addEdit()"
                  [disabled]="!form.valid"
                  color="primary"
                >
                  <span *ngIf="!objectId">{{ "addNewCity" | translate }}</span>
                  <span *ngIf="objectId">{{ "editCity" | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
  