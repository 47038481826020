<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center">{{ id ? ('editClientOrder' |
                            translate) : ('addClientOrder' | translate) }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-center">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]="form">
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'info' | translate}}
                            </ng-template>
                            <div class="row max-width-row">
                                <div class="col">
                                    <div class="example-container">
                                        <mat-form-field class="example-full-width">
                                            <span>{{ 'productType' | translate }}</span>
                                            <input matInput formControlName="productType" />
                                            <mat-hint></mat-hint>
                                            <mat-error *ngIf="valid('productType', 'required')">{{
                                                'required' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill">
                                        <mat-label>التاريخ</mat-label>
                                        <input matInput formControlName="date"
                                            [matDatepicker]="picker">
                                        <mat-hint>MM/DD/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row max-width-row">
                                <div class="col">
                                    <div class="example-container">
                                        <mat-form-field class="example-full-width">
                                            <span>{{ 'qty' | translate }}</span>
                                            <input matInput formControlName="qty" />
                                            <mat-hint></mat-hint>
                                            <mat-error *ngIf="valid('qty', 'required')">{{
                                                'required' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="example-container">
                                        <mat-form-field class="example-full-width">
                                            <span>{{ 'value' | translate }}</span>
                                            <input matInput formControlName="value" />
                                            <mat-hint></mat-hint>
                                            <mat-error *ngIf="valid('value', 'required')">{{
                                                'required' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="example-container">
                                        <mat-form-field class="example-full-width">
                                            <span>{{ 'resource' | translate }}</span>
                                            <input matInput formControlName="resource" />
                                            <mat-hint></mat-hint>
                                            <mat-error *ngIf="valid('resource', 'required')">{{
                                                'required' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row max-width-row">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <mat-label>{{'client' | translate}}
                                        </mat-label>
                                        <mat-select placeholder="{{ '' | translate }}"
                                            formControlName="client">
                                            <mat-option>
                                                <ngx-mat-select-search [(ngModel)]="clientSearch"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    [placeholderLabel]="''"
                                                    (ngModelChange)="changeLinkedUsersSearch()">
                                                    <mat-icon ngxMatSelectSearchClear>delete
                                                    </mat-icon>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let g of linkedUsers"
                                                [value]="g._id">
                                                {{ g.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row max-width-row">
                                <div class="col">
                                    <div class="example-container">
                                        <mat-form-field class="example-full-width">
                                            <span>{{ 'notes' | translate }}</span>
                                            <textarea rows="5" matInput
                                                formControlName="notes"> </textarea>
                                            <mat-hint></mat-hint>
                                            <mat-error *ngIf="valid('notes', 'required')">{{
                                                'required' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </form>
                <div class="row">
                    <div class="col">
                        <div class="row text-center max-width-row">
                            <div class="col">
                                <button mat-raised-button color="warn" (click)="addOrEdit()"
                                    [disabled]="!form.valid">
                                    {{ id ? ('editClientOrder' | translate) : ('addClientOrder'
                                    | translate) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>