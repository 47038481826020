<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center" *ngIf="!objectId">{{'addNewCategory' |
                            translate}}</h4>
                        <h4 class="h4-responsive text-center" *ngIf="objectId">{{'editCategory' |
                            translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <div class="progress form-group">
                        <div class="progress-bar progress-bar-striped bg-success-bar"
                            role="progressbar" [style.width.%]="progress">
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <h5 color='primary'>
                                <span>{{'image' | translate}}</span>
                            </h5>
                            <label *ngIf="thumbnailFileList.length > 0" class="upload-label"
                                for="upload-photo" appDrag>
                                <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer">
                                <i class="material-icons pointer close-icon"
                                    (click)="deleteUploadedThumbnailImage()">
                                    close
                                </i>
                            </label>
                            <label *ngIf="thumbnailFileList.length === 0" class="upload-label"
                                for="upload-thumbnail" appDrag>
                                <img class="pointer"
                                    src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="thumbnailSelectFile($event)"
                                    name="photo" id="upload-thumbnail" />
                            </label>
                            <br>
                            <br>
                        </div>
                        <!-- <div class="col">
                            <h5 color='primary'>
                                <span>{{'coverImage' | translate}}</span>
                            </h5>
                            <label *ngIf="coverFileList.length > 0" class="upload-label" for="upload-photo" appDrag>
                                <img [src]="coverUrlList" width="80%" alt="" class="pointer">
                                <i class="material-icons pointer close-icon" (click)="deleteUploadedCoverImage()">
                                    close
                                </i>
                            </label>
                            <label *ngIf="coverFileList.length === 0" class="upload-label" for="upload-thumbnail"
                                appDrag>
                                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="coverSelectFile($event)" name="photo"
                                    id="upload-thumbnail" />
                            </label>
                            <br>
                            <br>
                        </div> -->
                    </div>

                    <div class="row">
                        <p>
                            {{'addCatGuid' | translate}}
                        </p>
                    </div>

                    <div class="row row-center">
                        <!-- <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'parentCategory' | translate}}</mat-label>
                                <mat-select formControlName="linkedId">
                                    <mat-option *ngFor="let category of categories"
                                        [value]="category._id">
                                        {{category.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                        <!-- <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='index' matInput
                                    placeholder="{{'index' | translate}}">
                            </mat-form-field>
                        </div> -->
                    </div>

                    <div class="row text-center">
                        <div class="col-6 margin-auto">
                            <mat-form-field class="full-width">
                                <input formControlName='nameAr' matInput
                                    placeholder="{{'nameAr' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='index' matInput
                                    placeholder="{{'index' | translate}}">
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-6 margin-auto">
                            <mat-form-field class="full-width">
                                <input formControlName='nameEn' matInput
                                    placeholder="{{'nameEn' | translate}}">
                            </mat-form-field>
                        </div> -->
                    </div>

                    <!-- <div class="row text-center">
                        <div class="col margin-auto">
                            <mat-form-field class="full-width">
                                <input formControlName='color' type='color' matInput
                                    placeholder="{{'color' | translate}}">
                            </mat-form-field>
                        </div>
                    </div> -->
                    <div class="row text-center">
                        <div class="col">
                            <br>
                            <br>
                            <br>
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid'
                                color="primary">
                                <span *ngIf="!objectId">{{'addNewCategory' | translate}}</span>
                                <span *ngIf="objectId">{{'editCategory' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>