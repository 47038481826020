import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Package } from '../../../models/category';
import { FullServiceService } from '../../../services/full-service.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-add-package-to-user',
  templateUrl: './add-package-to-user.component.html',
  styleUrls: ['./add-package-to-user.component.scss']
})
export class AddPackageToUserComponent implements OnInit {
  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailList: File = null;

  form: UntypedFormGroup;
  userId: string;
  packages: Package[];
  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AddPackageToUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getPackages()
  }

  getPackages(){
    this.full.getAllPackagesForDropdown(0, 10, '', true).subscribe((data: any)=> {
      this.packages = data.data;
    })
  }
  ngOnInit() {
    this.userId = this.data;
    this.form = new UntypedFormGroup({
      package: new UntypedFormControl('', [Validators.required]),
      discountCode: new UntypedFormControl(null, []),
      image: new UntypedFormControl('', []),
    });
  }

  addPackageToUser() {
    if(!this.form.get('package').value){
      this.toastr.warningToastr('must select package');
      return;
    }

    this.full.addPackageForUser(this.userId, this.form.value).subscribe(addQuestionForExamData => {
      this.toastr.successToastr(this.translate.instant('questionSuccessfully'));
      this.dialogRef.close(true);
    }, err => {
      this.toastr.errorToastr(err);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translate.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }
  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }
  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

}
