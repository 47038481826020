import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from '../../services/full-service.service';

@Component({
  selector: 'app-end-lot',
  templateUrl: './end-lot.component.html',
  styleUrls: ['./end-lot.component.scss']
})
export class EndLotComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<EndLotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private full: FullServiceService,
    private toastr: ToastrManager,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      winnterUserId: new UntypedFormControl('', [Validators.required]),
    });

  }



}
