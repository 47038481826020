<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center">{{'editMyData' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <div class="row text-center">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 margin-auto">
                        <form [formGroup]='form'>
                            <div class="row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <input formControlName='name' matInput placeholder="{{'name' | translate}}">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <input formControlName='phone' type="tel" matInput
                                            placeholder="{{'phone' | translate}}">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row text-center">
                                <div class="col">
                                    <mat-form-field class="full-width">
                                        <input formControlName='email' matInput placeholder="{{'email' | translate}}">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row text-center">
                                <div class="col">
                                    <button mat-raised-button (click)="editProfile()" [disabled]='!form.valid'
                                        color="primary">
                                        <span *ngIf="!objectId">{{'editMyData' | translate}}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col">
                        <div class="flex">
                            <h4 class="text-center">{{'changePassword' | translate}}</h4>
                        </div>
                    </div>
                </div>
                <form [formGroup]='passForm'>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='oldPassword' matInput
                                    placeholder="{{'oldPassword' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='newPassword' matInput
                                    placeholder="{{'newPassword' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='newPasswordConfirm' matInput
                                    placeholder="{{'newPasswordConfirm' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="changePassword()" [disabled]='!passForm.valid'
                                color="primary">
                                <span>{{'resetPassword' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form> -->
            </mat-card>
        </div>
    </div>
</div>