import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { Category } from './../../../models/category';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-office-list',
  templateUrl: './office-list.component.html',
  styleUrls: ['./office-list.component.scss']
})
export class OfficeListComponent implements OnInit {
  data: Category[] = [];
  pageEvent;
  displayedColumns: string[] = [
    'name', 'address', 'phone', 'city','type', 'edit',
    'delete'
  ];
  dataSource: MatTableDataSource<Category>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  // MatPaginator Inputs
  length: number;
  pageSize = 50;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) { this.getData(); }

  ngOnInit() {

  }

  getData() {
    this.full.getAllOfficeTrasnfers(1, 50).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
    }, err => {
    });
  }

  delete(id) {
    this.full.deleteOneOfficeTrasnfer(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar('تم حذف المكتب بنجاح', 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar('حدث خطأ أثناء حذف المكتب', 'أغلق', 'danger');
    });
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
