import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestPackage } from '../../../models/category';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import jsPDF from 'jspdf';
@Component({
  selector: 'app-view-request-package',
  templateUrl: './view-request-package.component.html',
  styleUrls: ['./view-request-package.component.scss']
})
export class ViewRequestPackageComponent implements OnInit {
  objectId: string;
  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  coverFileList: any = [];
  coverUrlList: string;
  coverUrlAfterUpload: any;
  coverList: File = null;

  requestPackage: any;
  spinner$: Observable<boolean>;
  private spinnerSubject = new Subject<boolean>();
  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private snackBar: MatSnackBar,
    private activateRouter: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.spinner$ = this.spinnerSubject.asObservable().pipe(startWith(true));
  }

  ngOnInit() {
    this.objectId = this.activateRouter.snapshot.params.id;
    if (!this.objectId) {
      this.router.navigateByUrl(`request-packages-list`);
      return
    }
    this.getObject(this.objectId);
  }

  getObject(id: string) {
    this.full.getOneRequestPackage(this.objectId).subscribe((data: any) => {
      this.requestPackage = data.data;
      this.spinnerSubject.next(false);
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  acceptRequest(){
    this.confirmDialog()
  }
  confirmAcceptRequest(){
    this.full.acceptRequestPackage(this.objectId).subscribe((data: any)=> {
      if(data.status){
        this.router.navigateByUrl('request-packages-list')
      }
    })
  }
  confirmDialog(): void {
    const message = this.translae.instant(`Are you sure you want to accept this request`);

    const dialogData = new ConfirmDialogModel(this.translae.instant("Confirm Accept Request Package"), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.confirmAcceptRequest()
      }
    });
  }
  rejectRequest(){
    this.confirmRejectDialog()
  }
  confirmRejectRequest(){
    this.full.rejectRequestPackage(this.objectId).subscribe((data: any)=> {
      if(data.status){
        this.router.navigateByUrl('request-packages-list')
      }
    })
  }
  confirmRejectDialog(): void {
    const message = this.translae.instant(`Are you sure you want to reject this request`);

    const dialogData = new ConfirmDialogModel(this.translae.instant("Confirm Reject Request Package"), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.confirmRejectRequest()
      }
    });
  }
  openImage(image: any){
    window.open(image, '_blank')
  }
  download() {
    console.log(this.requestPackage);
    // let imageData: any = this.getBase64Image(document.getElementById('maa'));
    // console.log(imageData.width, imageData.height);
    
    var doc = new jsPDF();
    // doc.addImage(this.requestPackage.image, "JPEG", 0, 0, 220, imageData.h);
    // doc.addImage(this.requestPackage.image, "JPEG", 0, 0, 0, 0);
    doc.addImage(this.requestPackage.image, 20, 10, 170,280);
    doc.save(`${this.requestPackage.user.name}.pdf`);
  }

  getBase64Image(img) {
    var canvas = document.createElement('canvas');
    canvas.classList.add('myStyle');
    console.log(img.width, 'x', img.height);
    canvas.width = 446;
    canvas.height = 631;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL('image/png');
    return { img: dataURL, width: canvas.width, height: canvas.height, w:img.width, h: img.height };
  }
}
