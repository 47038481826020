import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from '../../services/full-service.service';
import { UserService } from '../../services/user.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-send-fcm',
  templateUrl: './send-fcm.component.html',
  styleUrls: ['./send-fcm.component.scss']
})
export class SendFcmComponent implements OnInit {

  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailList: File = null;

  form: UntypedFormGroup;
  page = 1;
  searchText = '';
  data: any[] = [];
  itemsTotal = 0;
  pagesTotal = 0;
  pageLimit = 0;
  currentPage = 1;
  showSpiner = true;
  deleted = '';

  users: any[] = [];
  dropdownPages = [];

  body = {
    role: 'user'
  }

  constructor(
    public dialog: MatDialog,
    private full: FullServiceService,
    private toastr: ToastrManager,
    private userService: UserService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      titleAr: new UntypedFormControl('', [Validators.required]),
      bodyAr: new UntypedFormControl('', [Validators.required]),
      image: new UntypedFormControl('', [Validators.required]),
      recivers: new UntypedFormControl('', [Validators.required]),
    });

    this.getAllUsers();
  }

  sendFcm() {
    this.full.sendFcm(this.form.value).subscribe(datqa => {
      this.toastr.successToastr(this.translate.instant('fcmSentSuccessfully'));
    }, err => {
      this.toastr.errorToastr(err);
    })
  }

  getAllUsers() {
    this.userService.getAllUsersWithSearch(this.currentPage, 250, this.searchText, this.body)
      .subscribe((data: any) => {
        // this.form.patchValue({
        //   recivers: ['60fbcdcb40549372c1595fa1', '60f3a5b073b5b05fcb824ede']
        // })
        this.dropdownPages = Array.from({ length: data.pageCount }, () => 1);
      });
  }

  selectPage(event) {
    this.userService.getAllUsersWithSearch(event.value, 250, this.searchText, this.body)
      .subscribe((data: any) => {
        let recs = [];
        data.data.map(e => {
          recs.push(e._id)
        })
        this.form.patchValue({
          recivers: recs
        })
        this.dropdownPages = Array.from({ length: data.pageCount }, () => 1);
      });
    this.page = event.value;
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translate.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }
}
