<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="text-center" *ngIf="!objectId">{{'addNewGallery' | translate}}</h4>
                        <h4 class="text-center" *ngIf="objectId">{{'editGallery' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <div class="progress form-group">
                        <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar"
                            [style.width.%]="progress">
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5 color='primary'>
                                <span>{{'image' | translate}}</span>
                            </h5>
                            <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag>
                                <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer">
                                <i class="material-icons pointer" (click)="deleteUploadedThumbnailImage()">
                                    close
                                </i>
                            </label>
                            <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail"
                                appDrag>
                                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="thumbnailSelectFile($event)" name="photo"
                                    id="upload-thumbnail" />
                            </label>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'status' | translate}}</mat-label>
                                <mat-select formControlName="status">
                                    <mat-option value="true">
                                        {{ 'active' | translate }}
                                    </mat-option>
                                    <mat-option value="false">
                                        {{ 'unActive' | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <textarea formControlName='caption' matInput placeholder="{{'caption' | translate}}"
                                    rows="5"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'user' | translate}}</mat-label>
                                <mat-select placeholder="{{ '' | translate }}" formControlName="user">
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="usersSearch"
                                            [ngModelOptions]="{ standalone: true }" [placeholderLabel]="''"
                                            (ngModelChange)="changeSearch()">
                                            <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let g of users" [value]="g._id">
                                        {{ g.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row max-width-row text-center">
                        <div class="col">
                            <br>
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid' color="primary">
                                <span *ngIf="!objectId">{{'addNewGallery' | translate}}</span>
                                <span *ngIf="objectId">{{'editGallery' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>