<div class="container text-center" [dir]='dir'>
    <div class="row">
        <div class="col">
            <h1 mat-dialog-title>
                {{title}}
            </h1>

            <div mat-dialog-content>
                <p>{{message}}</p>
            </div>

            <div mat-dialog-actions>
                <button mat-button (click)="onDismiss()">{{'no' | translate}}</button>
                <button mat-raised-button color="primary" (click)="onConfirm()">{{'yes' | translate}}</button>
            </div>
        </div>
    </div>
</div>