<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'logsManagement' | translate}}</h3>
                    </div>
                </div>

                <div class="row add-item-row">
                    <div class="col-lg-4 col-12">
                        <mat-form-field class="full-width">
                            <mat-label>{{'user' | translate}}</mat-label>
                            <mat-select placeholder="{{ '' | translate }}">
                                <mat-option>
                                    <ngx-mat-select-search [(ngModel)]="usersSearch"
                                        [ngModelOptions]="{ standalone: true }" [placeholderLabel]="''"
                                        (ngModelChange)="changeSearch()">
                                        <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option (click)='filterByUser("")'>
                                    {{ 'showAll' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let g of users" [value]="g._id" (click)='filterByUser(g._id)'>
                                    {{ g.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'user' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span>
                                {{row?.userId?.name}}
                            </span>
                            <br>
                            <span>
                                {{row?.userId?.phone}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="method">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'method' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.method | translate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="apiLink">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apiLink' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{replaceText(row.apiLink)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="statusCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'statusCode' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.statusCode}} </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.createdAt | date:'short'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator *ngIf='!userID' #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getAllData($event)">
                </mat-paginator>

                <mat-paginator *ngIf='userID' #paginator [showFirstLastButtons]="true" [pageIndex]="0" [length]="length"
                    [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getAllDataForUser($event, userID)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>