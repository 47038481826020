<div class="container container-top-margin add-container">
  <div class="row">
    <div class="col">
      <div class="page-head">
        <div class="flex flex-centerd">
          <i class="uil uil-book-medical"></i>
          <div class="flex-column">
            <h4 class="h4-responsive text-center">
              {{ id ? ("editFaq" | translate) : ("addFaq" | translate) }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-center">
    <div class="col">
      <mat-card class="add-card">
        <form [formGroup]="form">
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>
                {{ "arabic" | translate }}
              </ng-template>
              <div class="row text-center" style="margin-top: 1rem">
                <div class="col-12">
                  <!-- <div class="example-container"> -->
                    <mat-form-field class="full-width">
                      <span>{{ "question" | translate }}</span>
                      <input matInput formControlName="questionAr" />
                      <mat-hint></mat-hint>
                      <mat-error *ngIf="valid('questionAr', 'required')">{{
                        "required" | translate
                      }}</mat-error>
                    </mat-form-field>
                  <!-- </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span>{{ "answer" | translate }}</span>
                  <ckeditor
                    [editor]="Editor"
                    formControlName="answerAr"
                  ></ckeditor>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                {{ "english" | translate }}
              </ng-template>
              <div class="row text-center" style="margin-top: 1rem">
                <div class="col-12">
                  <!-- <div class="example-container"> -->
                    <mat-form-field class="full-width">
                      <span>{{ "question" | translate }}</span>
                      <input matInput formControlName="questionEn" />
                      <mat-hint></mat-hint>
                      <mat-error *ngIf="valid('questionEn', 'required')">{{
                        "required" | translate
                      }}</mat-error>
                    </mat-form-field>
                  <!-- </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span>{{ "answer" | translate }}</span>
                  <!-- <ckeditor
                    formControlName="answerEn"
                    [config]="editorConfig"
                  ></ckeditor> -->
                  <ckeditor
                  [editor]="Editor"
                  formControlName="answerEn"
                ></ckeditor>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </form>
        <div class="row" style="margin-top: 1rem">
          <div class="col">
            <div class="row text-center">
              <div class="col">
                <button
                  mat-raised-button
                  color="warn"
                  (click)="addOrEdit()"
                  [disabled]="!form.valid"
                >
                  {{ id ? ("editFaq" | translate) : ("addFaq" | translate) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
