<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'accountRequests' | translate}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col flex">
                        <mat-form-field class="filter-input">
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                        </mat-form-field>
                        <div class="spacer"></div>
                    </div>
                </div>

                <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <!-- fullName Column -->
                        <ng-container matColumnDef="fullName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'fullName' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                        </ng-container>

                        <!-- email Column -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                        </ng-container>

                        <!-- phone Column -->
                        <ng-container matColumnDef="phone">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
                        </ng-container>

                        <!-- country Column -->
                        <ng-container matColumnDef="country">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'country' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.country}} </td>
                        </ng-container>

                        <ng-container matColumnDef="accountType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'accountType' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.accountType | translate}} </td>
                        </ng-container>

                        <!-- Message Column -->
                        <ng-container matColumnDef="message">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'message' | translate}} </th>
                            <td mat-cell *matCellDef="let row" [title]="row.message"> {{row.message | slice:0:30}} </td>
                        </ng-container>

                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' | translate}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.createdAt | date:'short'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' | translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer" (click)='delete(row._id)'>
                                    delete
                                </i>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="pageEvent = getAllContacts($event)"></mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>