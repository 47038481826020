<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="text-center" *ngIf="!objectId">{{'addNewLot' | translate}}</h4>
                        <h4 class="text-center" *ngIf="objectId">{{'editLot' | translate}}</h4>
                    </div>
                    <!-- <div class="spacer"></div>
                    <button mat-raised-button *ngIf="objectId" (click)='OpenEndLot(objectId)' color="primary">
                        <span *ngIf="objectId">{{'endLot' | translate}}</span>
                    </button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <div class="progress form-group">
                        <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar"
                            [style.width.%]="progress">
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <h5 color='primary'>
                                <span>{{'giftImage' | translate}}</span>
                            </h5>
                            <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag>
                                <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer">
                                <i class="material-icons pointer" (click)="deleteUploadedThumbnailImage()">
                                    close
                                </i>
                            </label>
                            <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail"
                                appDrag>
                                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="thumbnailSelectFile($event)" name="photo"
                                    id="upload-thumbnail" />
                            </label>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <h5 color='primary'>
                                <span>{{'disImage' | translate}}</span>
                            </h5>
                            <label *ngIf="disFileList.length > 0" class="upload-label" for="upload-photo" appDrag>
                                <img [src]="disUrlList | secure | async" width="80%" alt="" class="pointer">
                                <i class="material-icons pointer" (click)="deleteUploadedDisImage()">
                                    close
                                </i>
                            </label>
                            <label *ngIf="disFileList.length === 0" class="upload-label" for="upload-thumbnail" appDrag>
                                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="disSelectFile($event)" name="photo"
                                    id="upload-thumbnail" />
                            </label>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='title' matInput placeholder="{{'title' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col margin-auto">
                            <!-- <mat-form-field class="full-width">
                                <input formControlName='drawDate' type='date' matInput
                                    placeholder="{{'drawDate' | translate}}">
                            </mat-form-field> -->
                            <input type="datetime-local" style="width: 100%; border: 0; border-bottom: 1px solid #999;"
                                formControlName='drawDate' value="drawDate" id="birthdaytime" name="birthdaytime">
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label> {{'ifLotDone' | translate}}</mat-label>
                                <mat-select formControlName='ifLotDone'>
                                    <mat-option value='true'>
                                        {{'done' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'notDone' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->

                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <textarea formControlName='giftText' matInput placeholder="{{'giftText' | translate}}"
                                    rows='4'></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <span>{{'lotConditions' | translate}}</span>
                         

                            <ckeditor
                            formControlName="conditions"
                            [config]="editorConfig"
                          ></ckeditor>
                        </div>
                    </div>

                    <div class="row max-width-row text-center">
                        <div class="col">
                            <br>
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid' color="primary">
                                <span *ngIf="!objectId">{{'addNewLot' | translate}}</span>
                                <span *ngIf="objectId">{{'editLot' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>