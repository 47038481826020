import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from '../../../services/full-service.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-forgot-password-list',
  templateUrl: './forgot-password-list.component.html',
  styleUrls: ['./forgot-password-list.component.scss']
})
export class ForgotPasswordListComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  verifiedForm: UntypedFormGroup;
  cityForm: UntypedFormGroup;
  promotedForm: UntypedFormGroup;
  data: any[] = [];
  searchWord = '';

  cities = [];
  states = [];

  userFilter: UntypedFormGroup;

  displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'whatsappPhone',
    'resetToken',
    'proccessed'
  ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // MatPaginator Inputs
  length: number;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrManager,
    public dialog: MatDialog,
    private full: FullServiceService,
  ) {
  }

  ngOnInit() {
    this.activeForm = new UntypedFormGroup({
      isProccessed: new UntypedFormControl(null, [])
    });
    this.getAllForgotPasswordRequests(1);
  }

  getAllForgotPasswordRequests(event: any) {
    this.userService.getAllForgotPasswordRequestsForAdmin(event.pageIndex + 1 || 1, event.pageSize, this.searchWord).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  searchRequests() {
    this.getAllForgotPasswordRequests(1);
  }

  changeProccessed(request: any, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      isProccessed: event.checked
    });

    this.userService.changeStatusResetPasswordRequest(request._id, this.activeForm.value).subscribe((data: any) => {
      request.isProccessed = event.checked;
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.full.openSnackBar(this.translate.instant('copyCompletedSuccessfully'), 'أغلق', 'success');
  }
  goToLink(number: string, code){
    window.open(`https://api.whatsapp.com/send?phone=${number}&text=your reset paswword code is ${code} you can ignor this message if not requestd for this code`, "_blank");
  }
}