import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { FullServiceService } from '../../../services/full-service.service';
import { User } from '../../../models/user';
import { SendSpecificNotiComponent } from '../../send-specific-noti/send-specific-noti.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  verifiedForm: UntypedFormGroup;
  promotedForm: UntypedFormGroup;
  data: User[] = [];
  searchWord = '';

  cities = [];
  states = [];

  userFilter: UntypedFormGroup;

  displayedColumns: string[] = ['name', 'phone', 'followers', 'role', 'qrNum', 'active', 'verified',
    'promoted', 'comments', 'notification', 'edit', 'delete'];
    // 'gallery',
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;
  // currentLang = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrManager,
    public dialog: MatDialog,
    private full: FullServiceService,
  ) {
  }

  ngOnInit() {
    this.userFilter = new UntypedFormGroup({
      active: new UntypedFormControl(null, []),
      promoted: new UntypedFormControl(null, []),
      verified: new UntypedFormControl(null, []),
      role: new UntypedFormControl(null, []),
      city: new UntypedFormControl(null, []),
      state: new UntypedFormControl(null, []),
      advertiserStatus: new UntypedFormControl(null, []),
    })

    this.activeForm = new UntypedFormGroup({
      active: new UntypedFormControl('', [])
    });

    this.verifiedForm = new UntypedFormGroup({
      verified: new UntypedFormControl('', [])
    });
    this.promotedForm = new UntypedFormGroup({
      promoted: new UntypedFormControl('', [])
    });

    this.getAllUsers(1);
    this.full.getAllParentCities().subscribe((data: any) => {
      this.cities = data.data;
    })
  }

  getAll() {
    this.userFilter.patchValue({
      role: null,
      active: null,
      promoted: null,
      verified: null,
      city: null,
      state: null,
      advertiserStatus: null
    })
    this.getAllUsers(1);
  }

  edit(id) {
    let url = window.location.href.replace('users-list', `edit-user/${id}`);
    window.open(url, '_blank')
  }

  getAllUsers(event: any) {
    this.userService.getAllUsersWithSearch(event.pageIndex + 1 || 1, event.pageSize, this.searchWord, this.userFilter.value).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  filter() {
    this.getAllUsers(1);
  }

  searchUsers() {
    this.getAllUsers(1);
  }
  getStatesForCity(id) {
    this.full.getAllSubsForCity(1, 1000, id).subscribe((data: any) => {
      this.states = data.data;
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openImage(image: string): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: { image: image[0] }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  notiModal(id: string): void {
    const dialogRef = this.dialog.open(SendSpecificNotiComponent, {
      width: '750px',
      data: id
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  activate(user: User, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      active: event.checked
    });

    this.userService.changeStatusUser(user._id, this.activeForm.value).subscribe((data: any) => {
      user.active = event.checked;
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  changePromoted(article: any, event: MatSlideToggleChange) {
    this.promotedForm.patchValue({
      promoted: event.checked
    });

    this.userService.changeStatusUser(article._id, this.promotedForm.value).subscribe((data: any) => {
      article.promoted = event.checked;
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  changeVerified(article: any, event: MatSlideToggleChange) {
    this.verifiedForm.patchValue({
      verified: event.checked
    });

    this.userService.changeStatusUser(article._id, this.verifiedForm.value).subscribe((data: any) => {
      article.verified = event.checked;
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  filterGallery(id: string) {
    this.router.navigate(['/gallery', { user: id }]);
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  delete(id) {
    this.full.deleteOneUser(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar(this.translate.instant('userDeletedSuccess'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
