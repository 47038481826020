import { apiUrl } from './../models/BaseURL';
import { User, UserModel } from './../models/user';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UserService {

  readonly api = apiUrl;

  constructor(private http: HttpClient) { }

  getAllUsers(page = 0, limit = 20) {
    return this.http.get<User[]>(`${this.api}/users?page=${page}&limit=${limit}`);
  }
  getUserDetailsForAdmin(userId:string) {
    return this.http.get<any>(`${this.api}/users/${userId}/details-for-admin`);
  }

  getUnreadedVerify(page = 0, limit = 1) {
    return this.http.get<User[]>(`${this.api}/verify/unreaded/all?page=${page}&limit=${limit}`);
  }

  getAllUsersWithSearch(page = 0, limit = 20, searchWord = '', body: any) {
    return this.http.put<User[]>(`${this.api}/users/search?page=${page}&limit=${limit}&searchWord=${searchWord}`, body);
  }
  getAllUsersForAdmin(page = 0, limit = 20, searchWord = '', body: any) {
    return this.http.post<UserModel[]>(`${this.api}/users/users-for-admin?page=${page}&limit=${limit}&searchWord=${searchWord}`, body);
  }
  getAllStuffForAdmin(page = 0, limit = 20, searchWord = '', body: any) {
    return this.http.post<UserModel[]>(`${this.api}/users/stuff-for-admin?page=${page}&limit=${limit}&searchWord=${searchWord}`, body);
  }
  getAllForgotPasswordRequestsForAdmin(page = 0, limit = 20, searchWord = '') {
    return this.http.post<UserModel[]>(`${this.api}/reset-password?page=${page}&limit=${limit}&searchWord=${searchWord}`, {});
  }
  getAllDeleteAccountRequestsForAdmin(page= 0, limit= 20, searchWord= '', status='') {
    return this.http.post<UserModel[]>(`${this.api}/delete-account/all-delete-account-requests?page=${page}&limit=${limit}`, {searchWord, status});
  }

  filterUsersWithEmptyValues(page = 0, limit = 20, searchWord = '', body: any) {
    return this.http.put<User[]>(`${this.api}/users/search/empty?page=${page}&limit=${limit}&searchWord=${searchWord}`, body);
  }

  getMyData() {
    return this.http.get<User>(`${this.api}/users/me`);
  }

  getOneUser(id: string) {
    return this.http.get<User>(`${this.api}/users/${id}`);
  }
  getOneEmployee(id: string) {
    return this.http.get<User>(`${this.api}/users/${id}`);
  }

  signUp(user: User) {
    return this.http.post<User>(`${this.api}/users/signup-admin`, user);
  }
  signUpEmployee(user: User) {
    return this.http.post<User>(`${this.api}/users/signup-employee`, user);
  }

  editUser(id: string, body: any) {
    return this.http.patch<User>(`${this.api}/users/${id}`, body);
  }

  editUserPass(id: string, body: User) {
    return this.http.post<User>(`${this.api}/users/admin/reset-pass/${id}`, body);
  }
  changePasswordForUserByAdmin(id: string, body: any) {
    return this.http.post<User>(`${this.api}/users/admin/reset-pass/${id}`, body);
  }

  editProfile(body: User) {
    return this.http.patch<User>(`${this.api}/users/edit-profile`, body);
  }

  changeStatusUser(id: string, body: User) {
    return this.http.patch(`${this.api}/users/${id}`, body);
  }
  changeUserPackagePause(userId: string, packageId: User) {
    return this.http.post(`${this.api}/userPackages/puse-package-for-user/${userId}`, {packageId: packageId});
  }
  changeStatusResetPasswordRequest(id: string, body: User) {
    return this.http.patch(`${this.api}/reset-password/${id}`, body);
  }
  changeStatusDeleteAccountRequest(id: string, body: any) {
    return this.http.patch(`${this.api}/delete-account/${id}`, body);
  }

}
