<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center">{{ id ? ('editInterest' | translate) :
                            ('addInterest' | translate) }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-center">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]="form">
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'info' | translate}}
                            </ng-template>
                            <div class="row max-width-row">
                                <div class="col">
                                    <div class="example-container">
                                        <mat-form-field class="example-full-width">
                                            <span>{{ 'name' | translate }}</span>
                                            <input matInput formControlName="name" />
                                            <mat-hint></mat-hint>
                                            <mat-error *ngIf="valid('name', 'required')">{{
                                                'required' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </form>
                <div class="row">
                    <div class="col">
                        <div class="row text-center max-width-row">
                            <div class="col">
                                <button mat-raised-button color="warn" (click)="addOrEdit()"
                                    [disabled]="!form.valid">
                                    {{ id ? ('editInterest' | translate) : ('addInterest' |
                                    translate) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>