import { TranslateService } from "@ngx-translate/core";
import { FileHandle } from "./../../directives/dragDrop.directive";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrManager } from "ng6-toastr-notifications";
import { FullServiceService } from "./../../../services/full-service.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-add-offer",
  templateUrl: "./add-offer.component.html",
  styleUrls: ["./add-offer.component.scss"],
})
export class AddOfferComponent implements OnInit {
  form: UntypedFormGroup;
  fileList = [];
  list: File = null;
  progress = 0;
  urlList: string;
  files: FileHandle[] = [];
  urlAfterUpload: any;

  objectId: string;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      offerImage: new UntypedFormControl("", []),

      startDate: new UntypedFormControl("", [Validators.required]),
      endDate: new UntypedFormControl("", [Validators.required]),

      titleAr: new UntypedFormControl("", [Validators.required]),
      titleEn: new UntypedFormControl("", [Validators.required]),
      titleFr: new UntypedFormControl("", []),

      descAr: new UntypedFormControl("", [Validators.required]),
      descEn: new UntypedFormControl("", [Validators.required]),
      descFr: new UntypedFormControl("", []),
    });

    this.objectId = this.activateRouter.snapshot.params.id;

    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneOffer(this.objectId).subscribe(
      (data: any) => {
        const s = data.data;
        this.form.patchValue({
          startDate: s.startDate,
          endDate: s.endDate,

          titleAr: s.titleAr,
          titleFr: s.titleFr,
          titleEn: s.titleEn,

          descEn: s.descEn,
          descFr: s.descFr,
          descAr: s.descAr,

          offerImage: s.offerImage,
        });
        this.fileList = data.data.offerImage;
        this.urlList = data.data.offerImage;
      },
      (err) => {
        this.snackBar.open(err);
      }
    );
  }

  selectFile(event) {
    this.fileList = [];
    const reader = new FileReader();

    this.list = event.target.files[0];
    this.fileList.push(this.list);
    this.urlAfterUpload = event.target.files[0];

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.urlAfterUpload = reader.result;
    };
  }

  upload() {
    let fd = new FormData();
    fd.append("images", this.fileList[0], this.fileList[0].name);
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              offerImage: event.body.images[0],
            });

            this.urlList = event.body.images[0];
        }
      },
      (err) => {
        this.urlList = this.translae.instant("imageNotUploaded");
      }
    );
  }

  deleteUploadedImage() {
    this.form.patchValue({
      offerImage: null,
    });
    this.progress = 0;
    this.fileList = [];
  }

  filesDropped(files: FileHandle[]): void {
    this.fileList = [];
    this.urlAfterUpload = files[0].url;
    this.fileList[0] = files[0].file;
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      this.full.addOffer(this.form.value).subscribe(
        (data: any) => {
          this.toastr.successToastr(data.message[0]);
          this.router.navigateByUrl("/offer-list");
        },
        (err) => {
          this.toastr.errorToastr(err);
        }
      );
    } else {
      this.full.editOneOffer(this.objectId, this.form.value).subscribe(
        (response: any) => {
          this.toastr.successToastr(response.message[0]);
          this.router.navigateByUrl("/offer-list");
        },
        (error) => {
          this.toastr.errorToastr(error);
        }
      );
    }
  }
}
