import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidators } from 'ng2-validation';
import { FullServiceService } from '../../../services/full-service.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  form: UntypedFormGroup;
  
  objectId: string;
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private full: FullServiceService,
    private translate: TranslateService,
    ){
      this.objectId = this.data.userId;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    const password = new UntypedFormControl(null, [Validators.required]);
    const passwordConfirm = new UntypedFormControl(null, [CustomValidators.equalTo(password)]);
    this.form = new UntypedFormGroup({
      password,
      passwordConfirm,
    });
  }
  save() {
    if (!this.form.valid) {
      this.full.openSnackBar('unvalid data' || '', 'أغلق', 'danger');
      return false;
    }
    if (!this.objectId) {
      this.full.openSnackBar('no user Id' || '', 'أغلق', 'danger');
      return false;
    }
    const body = {password: this.form.get('password').value, newPassword: this.form.get('password').value, passwordConfirm: this.form.get('passwordConfirm').value}
    this.userService.changePasswordForUserByAdmin(this.objectId, body)
        .subscribe((response: any) => {
          this.full.openSnackBar(response.message || '', 'أغلق', 'success');
          this.onNoClick()
        }, (error) => {
          this.full.openSnackBar(error || '', 'أغلق', 'danger');
        });
    }
}
