import { TranslateService } from '@ngx-translate/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-office',
  templateUrl: './add-office.component.html',
  styleUrls: ['./add-office.component.scss']
})
export class AddOfficeComponent implements OnInit {
  cities = [];
  countries = [];
  form: FormGroup;

  objectId: string;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
    });

    this.objectId = this.activateRouter.snapshot.params.id;

    if (this.objectId) {
      this.getObject(this.objectId);
    }

    this.full.getAllParentCities(1, 150).subscribe((data: any) => {
      this.countries = data.data;
    })
  }

  getObject(id: string) {
    this.full.getOneOfficeTrasnfer(this.objectId).subscribe((data: any) => {
      const s = data.data;
      this.full.getAllSubsForCity(1, 100, s.country).subscribe((resCities: any) => {
        this.cities = resCities.data;
        this.form.patchValue(s);
      })
    }, err => {
      this.snackBar.open(err);
    });
  }

  getCitiesForCountry(id) {
    this.full.getAllSubsForCity(1, 1000, id).subscribe((data: any) => {
      this.cities = data.data;
    })
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      this.full.addOfficeTrasnfer(this.form.value).subscribe((data: any) => {
        this.toastr.successToastr('تمت إضافة المكتب بنجاح');
        this.router.navigateByUrl('/office-list');
      }, err => {
        this.toastr.errorToastr(err);
      });
    } else {
      this.full.editOneOfficeTrasnfer(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.toastr.successToastr('حدث خطأ أثناء إضافة المكتب');
          this.router.navigateByUrl('/office-list');
        }, (error) => {
          this.toastr.errorToastr(error);
        });
    }
  }
}
