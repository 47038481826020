import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from '../../../services/full-service.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-note-to-user',
  templateUrl: './add-note-to-user.component.html',
  styleUrls: ['./add-note-to-user.component.scss']
})
export class AddNoteToUserComponent implements OnInit{
  userId: string;
  form: UntypedFormGroup;
  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AddNoteToUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.userId = this.data;
    this.form = new UntypedFormGroup({
      note: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    });
  }



  addNoteToUser() {
    if(!this.form.get('note').value || this.form.get('note').value.length < 3){
      this.toastr.warningToastr('must enter note');
      return;
    }

    this.full.addNoteForUser(this.userId, this.form.value).subscribe(data => {
      this.toastr.successToastr(this.translate.instant('noteSuccessfully'));
      this.dialogRef.close(true);
    }, err => {
      this.toastr.errorToastr(err);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
