import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { FullServiceService } from '../../../services/full-service.service';

@Component({
  selector: 'app-add-interest',
  templateUrl: './add-interest.component.html',
  styleUrls: ['./add-interest.component.scss']
})
export class AddInterestComponent implements OnInit {
  deletedImages = [];
  form: UntypedFormGroup;
  id = '';
  brand: any;

  constructor(
    // private productService: CatSubProductsService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public dialog: MatDialog,
    private full: FullServiceService,
    private toastr: ToastrManager
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
    });

    if (this.id) {
      this.full.getOneInterest(this.id).subscribe((data: any) => {
        if (!data) {
          this.toastr.successToastr(this.translate.instant('addedSuccessfully'));
          this.router.navigateByUrl('/interests-list');
          return;
        } else {
          this.brand = data.data;
         
          this.form.patchValue(this.brand);
        }
      });
    }
  }

  addOrEdit() {
    if (!this.id) {
      this.full.addNewInterest(this.form.value).subscribe(
        (message: string) => {
          this.router.navigateByUrl('/interests-list');
        },
        err => { }
      );
    } else {
      this.full.editInterest(this.id, this.form.value).subscribe(
        (message: string) => {
          this.router.navigateByUrl('/interests-list');
        },
        err => { }
      );
    }
  }

  valid(controlName, errorName) {
    return (
      this.form.get(controlName).hasError(errorName) &&
      (this.form.get(controlName).dirty || this.form.get(controlName).touched)
    );
  }
}
