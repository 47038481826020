<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'resetPasswordRequests' | translate}}</h3>
                    </div>
                </div>
                <!-- <div class="row add-item-row">
                    <div class="col-lg-3 flex">
                        <button mat-raised-button color="primary" (click)="goTo('add-user')">{{'addNewUser' | translate}}</button>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-lg-4">
                        <mat-form-field class="full-width">
                            <mat-label>{{'searchIn' | translate}}</mat-label>
                            <input matInput type="text" [(ngModel)]="searchWord" (input)='searchRequests()'>
                        </mat-form-field>
                    </div>
                </div>
                <table mat-table [dataSource]="dataSource" matSort>
                    <!-- name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>
                    <!-- email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.email ? row.email : '--------'}}
                        </td>
                    </ng-container>
                    <!-- phone Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" class="uni" style="cursor: alias;" (click)="goToLink(row.phone, row.resetToken)"> {{row.phone ? row.phone : '--------'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="whatsappPhone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'whatsappPhone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" class="uni" style="cursor: alias;" (click)="goToLink(row?.whatsappPhone, row.resetToken)"> {{row?.whatsappPhone ? row.whatsappPhone : '--------'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="resetToken">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'resetToken' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row" style="cursor: copy;" (click)="copyMessage(row.resetToken)"> {{row.resetToken}} </td>
                    </ng-container>
                    <!-- active -->
                    <ng-container matColumnDef="proccessed">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'status' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-slide-toggle *ngIf='row.role !== "owner"' color="primary"
                                [checked]="row.isProccessed" (change)="changeProccessed(row, $event)">
                                <span class="green" [hidden]='row.isProccessed !== true'>{{'proccessed' |
                                    translate}}</span>
                                <span class="red" [hidden]='row.isProccessed !== false'>{{'pending' |
                                    translate}}</span>
                            </mat-slide-toggle>
                        </td>
                    </ng-container>
                    <!-- verified -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getAllForgotPasswordRequests($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>