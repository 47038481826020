<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'lotList' | translate}}</h3>
                    </div>
                </div>
                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary" (click)="goTo('add-lot')">{{'addNewLot' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'giftImage' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <img [src]="row.giftImg" width="150px" alt="" (click)="openImage(row.giftImg)">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="disImg">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'disImage' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <img [src]="row.disImg" width="150px" alt="" (click)="openImage(row.disImg)">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" routerLink='/edit-lot/{{row._id}}'>
                                edit
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'title' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.title | slice:0:30}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="subs">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'subscribers' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" routerLink='/lot-list-subs/{{row._id}}'>
                                visibility
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="drawDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'drawDate' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.drawDate | date: 'short'}}
                            <!-- --- {{extractTimeFromDate(row.drawDate)}} -->
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" (click)="confirmDialog(row._id)">
                                delete
                            </i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>
            </mat-card>
        </div>
    </div>
</div>