import { ImageComponent } from './../../modals/image/image.component';
import { Router } from '@angular/router';
import { FullServiceService } from './../../../services/full-service.service';
import { Category } from './../../../models/category';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

export interface CategoryImage {
  image: string;
}

@Component({
  selector: 'app-blog-categories',
  templateUrl: './blog-categories.component.html',
  styleUrls: ['./blog-categories.component.scss']
})

export class BlogCategoriesComponent implements OnInit {
  panelOpenState = false;
  activeForm: UntypedFormGroup;
  data: any = [];
  pageEvent;
  selectedId = [];
  deleteForm: UntypedFormGroup;
  searchWord = '';
  // 'position', 'select',
  displayedColumns: string[] = ['image', 'name', 'id', 'parent', 'subs', 'index', 'edit', 'delete'];
  dataSource: MatTableDataSource<Category>;
  selection = new SelectionModel<Category>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  priorityForm: UntypedFormGroup;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private router: Router,
    private toastr: ToastrManager,
    private translate: TranslateService,
    public dialog: MatDialog
  ) { this.getCategories(1); }

  ngOnInit() {
    this.activeForm = new UntypedFormGroup({
      status: new UntypedFormControl(null, [])
    });

    this.priorityForm = new UntypedFormGroup({
      priority: new UntypedFormControl('', [])
    });

    this.deleteForm = new UntypedFormGroup({
      deletedItems: new UntypedFormControl([], [Validators.required])
    });
  }

  chngePriority(id) {
    this.full.editOneCategory(id, this.priorityForm.value).subscribe(data => {
      this.getCategories(1);
    }, err => {
    });

  }

  edit(id) {
    let url = window.location.href.replace('categories', `edit-category/${id}`);
    window.open(url, '_blank')
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }

  // removeItem(item) {

  //   this.image = this.image.filter(e => e !== item);
  // }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  // deleteSelected() {
  //   this.selection.selected.forEach(item => {
  //     this.selectedId.push(item._id);
  //     const index: number = this.data.findIndex(d => d === item);
  //     this.data.splice(index, 1);
  //     this.dataSource = new MatTableDataSource<Category>(this.data);
  //   });
  //   this.deleteForm.patchValue({
  //     deletedItems: this.selectedId
  //   });
  //   this.full.deleteManyCategories(this.deleteForm.value).subscribe((data: any) => {
  //     this.selection = new SelectionModel<Category>(true, []);
  //     this.full.openSnackBar(data.message[0] || '', 'أغلق', 'success');
  //   }, err => {
  //     this.full.openSnackBar(err || '', 'أغلق', 'danger');
  //   });
  // }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Category): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row._id + 1}`;
  }

  getCategories(event: any) {
    this.full.getAllParentCategories(event.pageIndex + 1 || 1, event.pageSize, this.searchWord).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      this.length = data.itemCount;
    }, err => {
    });
  }

  search() {
    this.full.getAllParentCategories(1 ,10, this.searchWord).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      this.length = data.itemCount;
    }, err => {
    });
  }

  showSubsForCat(id: string) {
    this.full.getAllSubsForCategory(1, 100, id).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      this.length = data.itemCount;
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openImage(image: string): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: { image: image[0] }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  // activate(category: Category, event: MatSlideToggleChange) {
  //   this.activeForm.patchValue({
  //     status: event.checked
  //   });
  //   this.full.changeStatusCategory(category._id, this.activeForm.value).subscribe((data: any) => {
  //     category.status = event.checked;
  //     this.full.openSnackBar(data.message[0] || '', 'أغلق', 'success');
  //   }, err => {
  //     this.full.openSnackBar(err || '', 'أغلق', 'danger');
  //   });
  // }

  confirmDialog(id) {

    if (id.adsLength > 0) {
      this.toastr.errorToastr('لا يمكنك حذف صنف يحتوى على خدمات');
      return false;
    }

    if (id.isCategory) {
      if (id.childs.length > 0) {
        this.toastr.errorToastr('لا يمكنك حذف صنف يحتوى على أصناف فرعية');
        return false;
      }
    }
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id._id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  delete(id) {
    this.full.deleteOneCategory(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.getCategories(1);
      this.full.openSnackBar(this.translate.instant('categoryDeletedSuccess'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
