import { ToastrManager } from "ng6-toastr-notifications";
import { slideInAnimation, fadeAnimation } from "./animations/animations";
import { AuthService } from "./../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { RouterOutlet, Router } from "@angular/router";
import { GlobalDataService } from "./services/global-data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [slideInAnimation, fadeAnimation],
})
export class AppComponent implements OnInit {
  mobileQuery: MediaQueryList;
  theme$ = "";
  dir = "";
  logo = "assets/images/logoSide.png";
  profile = "assets/images/profile.png";
  userData: any;

  languages = [
    {
      lang: "AR",
      value: "ar",
    },
    // {
    //   lang: 'TR', value: 'tr'
    // },
    {
      lang: "EN",
      value: "en",
    },
  ];

  themes = [
    {
      theme: "Default",
      value: "default-theme",
    },
    {
      theme: "Light",
      value: "light-theme",
    },
    {
      theme: "Nature",
      value: "nature-theme",
    },
    {
      theme: "Black",
      value: "black-theme",
    },
  ];

  // links = [];

  private mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private translate: TranslateService,
    public auth: AuthService,
    private toastr: ToastrManager,
    private router: Router,
    public global: GlobalDataService
  ) {
    this.ckeckToken();
    translate.addLangs(["ar", "en"]);

    if (localStorage.getItem("lang")) {
      if (localStorage.getItem("lang") === "en") {
        this.dir = "ltr";
        translate.use(localStorage.getItem("lang"));
      } else {
        this.dir = "rtl";
        translate.use(localStorage.getItem("lang"));
      }
    } else {
      this.dir = "rtl";
      localStorage.setItem("lang", "ar");
      translate.setDefaultLang("ar");
    }

    // if (localStorage.getItem('selected-theme')) {
    // this.theme$ = localStorage.getItem('selected-theme');
    // } else {
    localStorage.setItem("selected-theme", "light-theme");
    this.theme$ = "light-theme";
    // }

    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit() {
    if (localStorage.getItem("currentUser")) {
      this.userData = JSON.parse(localStorage.getItem("currentUser")).data.user;

      if (this.userData.role === "owner" || this.userData.role === "admin" || this.userData.role === "editor" || this.userData.role === "employee") {
        this.global.links = [
          {
            label: "home",
            role: "admin",
            subLinks: [{ link: "/dashboard", label: "home" }],
          },
          {
            label: "requestPackagesManagement",
            role: "admin",
            subLinks: [{link: "/request-packages-list", label: "requests" }],
          },
          {
            label: "lessCatManagement",
            role: "editor",
            subLinks: [
              { link: "/less-cat-list", label: "lessCatList" },
              { link: "/add-less-cat", label: "addlessCate" },
            ],
          },
          {
            label: "unitsManagement",
            role: "editor",
            subLinks: [
              { link: "/add-unit", label: "addunit" },
              { link: "/units-list", label: "unitsList" },
            ],
          },
          {
            label: "lessonsManagement",
            role: "editor",
            subLinks: [
              { link: "/add-lesson", label: "addLesson" },
              { link: "/lessons-list", label: "lessonsList" },
            ],
          },
          {
            label: "exercisesManagement",
            role: "editor",
            subLinks: [
              { link: "/add-exercis", label: "addExercis" },
              { link: "/exercises-list", label: "exercisesList" },
            ],
          },
          {
            label: "baccalaureatesManagement",
            role: "editor",
            subLinks: [
              { link: "/add-baccalaureate", label: "addBaccalaureate" },
              { link: "/baccalaureates-list", label: "baccalaureatesList" },
            ],
          },
          {
            label: "booksManagement",
            role: "editor",
            subLinks: [
              { link: "/add-book", label: "addBook" },
              { link: "/books-list", label: "booksList" },
            ],
          },
          {
            label: "examsManagement",
            role: "editor",
            subLinks: [
              { link: "/add-exam", label: "addExam" },
              { link: "/exams-list", label: "examsList" },
            ],
          },
          {
            label: "packagesManagement",
            role: "editor",
            subLinks: [
              { link: "/add-package", label: "addPackage" },
              { link: "/packages-list", label: "packagesList" },
            ],
          },
          {
            label: "paymentMethodsManagement",
            role: "editor",
            subLinks: [
              { link: "/add-payment-method", label: "addPaymentMethod" },
              { link: "/payment-methods-list", label: "paymentMethodsList" },
            ],
          },
          {
            label: "discountsManagement",
            role: "editor",
            subLinks: [
              { link: "/add-discount", label: "addDiscount" },
              { link: "/discount-list", label: "discountsList" },
            ],
          },
          {
            label: "sliderManagement",
            role: "owner",
            subLinks: [
              { link: "/slider-list", label: "sliderList" },
              { link: "/add-slider", label: "addSlider" },
            ],
          },
          {
            label: "featuresManagement",
            role: "owner",
            subLinks: [
              { link: "/features-list", label: "featuresList" },
              { link: "/add-feature", label: "addFeature" },
            ],
          },
          {
            label: "packagesFeaturesManagement",
            role: "owner",
            subLinks: [
              { link: "/packages-features-list", label: "featuresList" },
              { link: "/add-packages-feature", label: "addFeature" },
            ],
          },
          {
            label: "citiesManagement",
            role: "owner",
            subLinks: [
              { link: "/cities", label: "citiesList" },
              { link: "/add-city", label: "addCity" },
            ],
          },
          {
            label: "faq",
            role: "owner",
            subLinks: [{ link: "/faq", label: "faq" }],
          },

          // {
          //   label: "userManagement",
          //   role: "owner",
          //   subLinks: [
          //     { link: "/users-list", label: "usersList" },
          //     { link: "/empty-users", label: "emptyUsersList" },
          //     { link: "/add-user", label: "addUser" },
          //     // { link: '/gallery', label: 'GalleryList' },
          //   ],
          // },
          {
            label: "userManagement",
            role: "owner",
            subLinks: [
              { link: "/users-list", label: "usersList" },
              { link: "/stuff-list", label: "stuffList" },
              { link: "/forgot-password", label: "forgotPasswordRequests" },
              { link: "/delete-account-request", label: "deleteAccountRequests" },
            ],
          },
          {
            label: "contactsManagement",
            role: "owner",
            subLinks: [{ link: "/contacts", label: "contacts" }],
          },
          {
            label: "notificationsManagement",
            role: "owner",
            subLinks: [{ link: "/send-fcm", label: "sendFcm" }],
          },
        ];
      }
      // else if (this.userData.role === "editor") {
      //   this.global.links = [
      //     {
      //       label: "userManagement",
      //       role: "owner",
      //       subLinks: [
      //         { link: "/users-list", label: "usersList" },
      //         { link: "/add-user", label: "addUser" },
      //       ],
      //     },
      //   ];
      // }
    }
  }

  ckeckToken() {
    this.auth.checkJWTtoken();
  }

  ngOnDestroy(): void {
    // this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  change(lang: string) {
    localStorage.setItem("lang", lang.toLowerCase());
    if (lang === "ar") {
      this.dir = "rtl";
      this.translate.use(lang);
    } else {
      this.dir = "ltr";
      this.translate.use(lang);
    }
    // localStorage.setItem('dataLang', lang.toLowerCase());
  }

  changeTheme(value) {
    localStorage.setItem("selected-theme", value);
    this.theme$ = value;
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animation"]
    );
  }

  logOut() {
    this.auth.logout();
    // .subscribe((data: any) => {
    //   localStorage.removeItem('currentUser');
    //   this.auth.isAuth = false;
    //   this.router.navigateByUrl('/login');
    //   this.toastr.successToastr(data.message || '');
    // }, err => {
    //   this.toastr.errorToastr(err);
    // });
  }

  goTo(link: string) {
    if (this.userData) {
      this.router.navigateByUrl("/" + link + "/" + this.userData);
    } else {
      this.router.navigateByUrl("/" + link + "/" + this.auth.userID);
    }
  }
}
