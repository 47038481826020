import { TranslateService } from "@ngx-translate/core";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrManager } from "ng6-toastr-notifications";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { MatDialog } from "@angular/material/dialog";
import { EndLotComponent } from "../end-lot/end-lot.component";
import { FullServiceService } from "../../services/full-service.service";

@Component({
  selector: "app-add-lot-list",
  templateUrl: "./add-lot-list.component.html",
  styleUrls: ["./add-lot-list.component.scss"],
})
export class AddLotListComponent implements OnInit {
  form: UntypedFormGroup;

  objectId: string;

  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  disFileList = [];
  disUrlList: string;
  disUrlAfterUpload: any;
  disList: File = null;

  editorConfig = {
    language: "en",

    extraPlugins:
      "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
      "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
      "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
      "indentblock,indentlist,justify,link,list,liststyle,magicline," +
      "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
      "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
      "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
      "tabletools,templates,toolbar,undo",
    // }
  };

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl("", [Validators.required]),
      conditions: new UntypedFormControl("", [Validators.required]),
      cover: new UntypedFormControl("", []),
      giftImg: new UntypedFormControl("", [Validators.required]),
      disImg: new UntypedFormControl("", [Validators.required]),
      giftText: new UntypedFormControl("", [Validators.required]),
      drawDate: new UntypedFormControl("", [Validators.required]),
      // ifLotDone: new FormControl('', []),
      subscribers: new UntypedFormControl([], []),
    });

    this.objectId = this.activateRouter.snapshot.params.id;

    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneLot(this.objectId).subscribe(
      (data: any) => {
        const s = data.data;
        s.conditions = this.htmlDecode(s.conditions);
        this.form.patchValue(s);
        let d = s.drawDate + "";
        d = d.slice(0, -2);
        this.form.patchValue({
          drawDate: d,
        });
        this.thumbnailFileList = s.giftImg;
        this.thumbnailUrlList = s.giftImg;

        this.disFileList = s.disImg || [];
        this.disUrlList = s.disImg || [];
      },
      (err) => {
        this.snackBar.open(err);
      }
    );
  }

  OpenEndLot(id: string): void {
    const dialogRef = this.dialog.open(EndLotComponent, {
      width: "650px",
      data: id,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  htmlDecode(input) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }

  disSelectFile(event) {
    this.disList = event.target.files[0];
    this.disFileList.push(this.disList);
    this.disUpload();
  }

  disUpload() {
    let fd = new FormData();

    fd.append("images", this.disFileList[0], this.disFileList[0].name);
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              disImg: event.body.images[0],
            });
            this.disUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.disUrlList = this.translae.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedDisImage() {
    this.form.patchValue({
      disImg: null,
    });
    this.progress = 0;
    this.disFileList = [];
  }

  thumbnailSelectFile(event) {
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append(
      "images",
      this.thumbnailFileList[0],
      this.thumbnailFileList[0].name
    );
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              giftImg: event.body.images[0],
            });
            this.thumbnailUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.thumbnailUrlList = this.translae.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      giftImg: null,
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  addHouersToDate(date: Date) {
    var today = new Date(date);
    return today.setHours(today.getHours());
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }
    this.form.patchValue({
      drawDate: this.addHouersToDate(this.form.get("drawDate").value),
    });

    if (!this.objectId) {
      this.full.addLot(this.form.value).subscribe(
        (data: any) => {
          this.toastr.successToastr("تمت إضافة القرعة بنجاح");
          this.router.navigateByUrl("/lot-list");
        },
        (err) => {
          this.toastr.errorToastr(err);
        }
      );
    } else {
      this.full.editOneLot(this.objectId, this.form.value).subscribe(
        (response: any) => {
          this.toastr.successToastr("تم تعديل القرعة بنجاح");
          this.router.navigateByUrl("/lot-list");
        },
        (error) => {
          this.toastr.errorToastr(error);
        }
      );
    }
  }
}
