<div class="container" style="margin-top: 2rem">
  <div class="row" *ngIf="dataStatistics$ | async as _dataStatistics">
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="req-box" routerLink="/request-packages-list">
        {{ "packageRequests" | translate }}
        <p>{{ _dataStatistics.packageRequests }}&nbsp;{{ "request" | translate }}</p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="users-box">
        {{ "endedPackages" | translate }}
        <p>{{ _dataStatistics.endedPackages }}&nbsp;{{ "package" | translate }}</p>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="dataStatistics$ | async as _dataStatistics">
    <div class="col-lg-3 col-md-4 col-sm-6 col-6" *ngFor="let userPackage of _dataStatistics.activePackages	 ">
      <div class="articles-box">
        {{ userPackage.package.nameAr }}
        <p>{{ userPackage.count }}&nbsp;{{ "activePackage" | translate }}</p>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="dataStatistics$ | async as _dataStatistics">
    <div class="col-9">
      <div class="con-box" routerLink="/contacts">
        {{ "messagesNumber" | translate }}
        <p style="margin-bottom: 0.3rem">0 &nbsp;{{ "message" | translate }}</p>
        <!-- <p>
          <span *ngFor="let n of contactsData">{{ n?.name }},&nbsp;</span>
        </p> -->
      </div>
    </div>
    <div class="col-3">
      <div class="con-box" routerLink="/forgot-password">
        {{ "resetPasswordRequests" | translate }}
        <p style="margin-bottom: 0.3rem">{{_dataStatistics.resetPasswordRequests}} &nbsp;{{ "requests" | translate }}</p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="req-box" routerLink="/less-cat-list">
        {{ "lessonsCatsNumber" | translate }}
        <p>{{_dataStatistics.lessonsCatTotal}} &nbsp;{{ "lessonsCat" | translate }}</p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="users-box" routerLink="/lessons-list">
        {{ "lessonsNum" | translate }}
        <p>{{_dataStatistics.lessonTotal}} &nbsp;{{ "lesson" | translate }}</p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="ex-box" routerLink="/exercises-list">
        {{ "exerciseNum" | translate }}
        <p>{{_dataStatistics.exercisTotal}} &nbsp;{{ "exercise" | translate }}</p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="ex-box" routerLink="/baccalaureates-list">
        {{ "prevBacNum" | translate }}
        <p>{{_dataStatistics.baccalaureateTotal}} &nbsp;{{ "file" | translate }}</p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="articles-box" routerLink="/books-list">
        {{ "booksAndAbs" | translate }}
        <p>{{_dataStatistics.bookTotal}} &nbsp;{{ "file" | translate }}</p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="articles-box" routerLink="/exams-list">
        {{ "examNum" | translate }}
        <p>{{_dataStatistics.examTotal}} &nbsp;{{ "exam" | translate }}</p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="articles-box" routerLink="/">
        {{ "quetionsNum" | translate }}
        <p>{{_dataStatistics.questionTotal}} &nbsp;{{ "quetion" | translate }}</p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="articles-box" routerLink="/users-list">
        {{ "allUsersNumber" | translate }}
        <p>{{ _dataStatistics.userTotal }}&nbsp;{{ "user" | translate }}</p>
      </div>
    </div>
    <!-- <div class="col-lg-3 col-md-4 col-sm-6 col-6">
      <div class="articles-box" routerLink="/users-list">
        {{ "subsUsersNumber" | translate }}
        <p>{{ users }}&nbsp;{{ "user" | translate }}</p>
      </div>
    </div> -->
  </div>
</div>

<div class="container" style="padding-bottom: 3rem; margin-top: 3rem">
  <div class="row text-right">
    <div class="col">
      <div class="chart-title">
        <h3>{{ "usersSubs" | translate }}</h3>
        <hr />
      </div>
      <div style="display: block">
        <canvas
          class="margin-auto"
          baseChart
          width="900"
          height="400"
          [type]="'line'"
          [data]="lineChartData"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
        >
        </canvas>
      </div>
    </div>
  </div>
  <div class="row text-right">
    <div class="col">
      <div class="chart-title">
        <hr style="margin-top: 2rem" />
      </div>
      <canvas
        baseChart
        [type]="'pie'"
        [datasets]="pieChartDatasets"
        [labels]="pieChartLabels"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [legend]="pieChartLegend"
      >
      </canvas>
    </div>
  </div>
</div>
