<div class="container container-top-margin add-container">
    <div class="row">
      <div class="col">
        <div class="page-head">
          <div class="flex flex-centerd">
            <i class="uil uil-book-medical"></i>
            <div class="flex-column">
              <h4 class="h4-responsive text-center" *ngIf="!objectId">
                {{ "addNewPackage" | translate }}
              </h4>
              <h4 class="h4-responsive text-center" *ngIf="objectId">
                {{ "editPackage" | translate }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-card class="add-card">
          <form [formGroup]="form">
            <!-- <div class="progress form-group">
              <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progress"></div>
            </div>
            <div class="row text-center">
              <div class="col">
                <h5 color="primary">
                  <span>{{ "image" | translate }}</span>
                </h5>
                <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag>
                  <img
                    [src]="thumbnailUrlList"
                    width="80%"
                    alt=""
                    class="pointer"
                  />
                  <i
                    class="material-icons pointer close-icon"
                    (click)="deleteUploadedThumbnailImage()"
                  >
                    close
                  </i>
                </label>
                <label
                  *ngIf="thumbnailFileList.length === 0"
                  class="upload-label"
                  for="upload-thumbnail"
                  appDrag
                >
                  <img
                    class="pointer"
                    src="https://www.greatmats.com/images/placeholder-all.png"
                    width="80%"
                    alt=""
                  />
                  <input
                    type="file"
                    (change)="thumbnailSelectFile($event)"
                    name="photo"
                    id="upload-thumbnail"
                  />
                </label>
                <br />
                <br />
              </div>
            </div> -->
  
            <div class="row text-center">
              <div class="col-4 margin-auto">
                <mat-form-field class="full-width">
                  <input formControlName="nameEn" matInput placeholder="{{ 'nameEn' | translate }}" id="nameEn" />
                </mat-form-field>
              </div>
              <div class="col-4 margin-auto">
                <mat-form-field class="full-width">
                  <input formControlName="nameFr" matInput id="nameFr" placeholder="{{ 'nameFr' | translate }}" />
                </mat-form-field>
              </div>
              <div class="col-4 margin-auto">
                <mat-form-field class="full-width">
                  <input formControlName="nameAr" matInput id="nameAr" placeholder="{{ 'nameAr' | translate }}" />
                </mat-form-field>
              </div>
            </div>
            <div class="row text-center">
                <div class="col-6 margin-auto">
                    <mat-form-field class="full-width">
                        <mat-label>{{'activateDurationInDays' | translate}}</mat-label>
                      <input type="number" formControlName="activateDurationInDays" matInput id="activateDurationInDays" placeholder="{{ 'activateDurationInDays' | translate }}" />
                    </mat-form-field>
                </div>
                <div class="col-6 margin-auto">
                    <mat-form-field class="full-width">
                        <mat-label>{{'price' | translate}}</mat-label>
                        <input type="number" formControlName="price" matInput id="price" placeholder="{{ 'price' | translate }}" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row text-center">
              <div class="col">
                <br />
                <br />
                <br />
                <button
                  mat-raised-button
                  (click)="addEdit()"
                  [disabled]="!form.valid"
                  color="primary"
                >
                  <span *ngIf="!objectId">{{ "addNewPackage" | translate }}</span>
                  <span *ngIf="objectId">{{ "editPackage" | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
  