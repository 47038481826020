<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="text-center" *ngIf="!objectId">{{'addNewFeature' | translate}}</h4>
                        <h4 class="text-center" *ngIf="objectId">{{'editFeature' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <div class="row text-center">
                        <div class="col-12 margin-auto">
                            <mat-form-field class="full-width">
                                <span>{{ 'text' | translate }}</span>
                                <input matInput formControlName="textEn" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-12 margin-auto">
                            <mat-form-field class="full-width">
                                <span>{{ 'text' | translate }}</span>
                                <input matInput formControlName="textFr" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-12 margin-auto">
                            <mat-form-field class="full-width">
                                <span>{{ 'text' | translate }}</span>
                                <input matInput formControlName="textAr" />
                            </mat-form-field>
                        </div>
                    </div>
                        <div class="row text-center">
                            <div class="col-6">
                                <input type="checkbox" formControlName="free" /> {{ 'paid' | translate }}
                            </div>
                            <div class="col-6">
                                <input type="checkbox" formControlName="paid" /> {{ 'free' | translate }}
                            </div>
                        </div>
                    <div class="row max-width-row text-center">
                        <div class="col">
                            <br>
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid' color="primary">
                                <span *ngIf="!objectId">{{'addNewFeature' | translate}}</span>
                                <span *ngIf="objectId">{{'editFeature' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>