import { ImageComponent } from "./../../modals/image/image.component";
import { MatDialog } from "@angular/material/dialog";
import { Category } from "./../../../models/category";
import { TranslateService } from "@ngx-translate/core";
import { FileHandle } from "./../../directives/dragDrop.directive";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrManager } from "ng6-toastr-notifications";
import { FullServiceService } from "./../../../services/full-service.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-add-author",
  templateUrl: "./add-author.component.html",
  styleUrls: ["./add-author.component.scss"],
})
export class AddAuthorComponent implements OnInit {
  form: UntypedFormGroup;
  disabledBtn = true;
  // Modal Image
  image: [];

  fileList = [];
  list: File = null;
  progress = 0;
  urlList: string;
  files: FileHandle[] = [];
  downloadLink = false;

  urlAfterUpload: any;
  categories: Category[];
  authors = [];
  objectId: string;

  constructor(
    private full: FullServiceService,
    private router: Router,
    private translae: TranslateService,
    public dialog: MatDialog,
    private activateRouter: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl("", [Validators.required]),
      bio: new UntypedFormControl("", []),
      email: new UntypedFormControl("", []),
      website: new UntypedFormControl("", []),
      facebook: new UntypedFormControl("", []),
      twitter: new UntypedFormControl("", []),
      instagram: new UntypedFormControl("", []),
      phone: new UntypedFormControl("", []),
      age: new UntypedFormControl("", []),

      image: new UntypedFormControl("", []),

      status: new UntypedFormControl(null, [Validators.required]),
    });

    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneAuthor(this.objectId).subscribe(
      (response: any) => {
        this.form.patchValue(response.data);
        this.fileList = response.data.image;
        this.urlList = response.data.image;
      },
      (err) => {
        this.snackBar.open(err);
      }
    );
  }

  selectFile(event) {
    this.fileList = [];

    if (event.target.files.length > 12) {
      this.full.openSnackBar(
        this.translae.instant("youCanUploadOnly12FileEveryTime"),
        "أغلق",
        "danger"
      );
      return false;
    }

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < event.target.files.length; i++) {
      this.list = event.target.files[i];
      this.fileList.push(this.list);
      this.disabledBtn = false;
    }

    this.upload();
  }

  upload() {
    const fd = new FormData();
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.fileList.length; i++) {
      fd.append("images", this.fileList[i], this.fileList[i].name);
    }
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.fileList = event.body.images;
            this.form.patchValue({
              image: this.fileList[0],
            });
            // this.downloadLink = true;
            this.disabledBtn = true;
        }
      },
      (err) => {}
    );

    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteImage() {
    this.form.patchValue({
      image: "",
    });
    this.fileList = [];
  }

  deleteUploadedImage() {
    this.progress = 0;
    this.fileList = [];
  }

  openImage(image): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: "800px",
      data: [image],
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.image = result;
      this.fileList = result[0];
      if (this.image) {
      } else {
        this.image = [];
        this.fileList = [];
      }

      this.form.patchValue({
        image: result,
      });
    });
  }

  filesDropped(files: FileHandle[]): void {
    this.urlAfterUpload = files[0].url;
    this.fileList[0] = files[0].file;
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      this.full.addAuthor(this.form.value).subscribe(
        (data: any) => {
          this.router.navigateByUrl("/author-list");
          this.full.openSnackBar(data.message || "", "أغلق", "success");
        },
        (err) => {
          this.full.openSnackBar(err || "", "أغلق", "danger");
        }
      );
    } else {
      this.full.editOneAuthor(this.objectId, this.form.value).subscribe(
        (data: any) => {
          this.router.navigateByUrl("/author-list");
          this.full.openSnackBar(data.message || "", "أغلق", "success");
        },
        (err) => {
          this.full.openSnackBar(err || "", "أغلق", "danger");
        }
      );
    }
  }
}
