import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FullServiceService } from '../../../services/full-service.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-transfer-status',
  templateUrl: './transfer-status.component.html',
  styleUrls: ['./transfer-status.component.scss']
})
export class TransferStatusComponent implements OnInit {

  form: FormGroup;
  order;
  defaultStatus = [
    'processing',
    'canceled',
    'completed',
  ]

  constructor(
    private full: FullServiceService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TransferStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.order = this.data;
    this.form = new FormGroup({
      status: new FormControl('', [Validators.required])
    })
  }

  editOrder() {
    this.full.editTransfer(this.order, this.form.value).subscribe(data => {
      this.full.openSnackBar('تم تعديل الحالة بنجاح', 'أغلق', 'success');
      this.dialog.closeAll();
    }, err => {
      this.full.openSnackBar('اغلق', err, 'danger');
    });
  }
}
