import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LessCat, Unit } from '../../../models/category';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';


@Component({
  selector: 'app-add-exercis',
  templateUrl: './add-exercis.component.html',
  styleUrls: ['./add-exercis.component.scss']
})
export class AddExercisComponent implements OnInit {
  units: Unit[];
  form: UntypedFormGroup;
  objectId: string;
  progressCover = 0;
  progress = 0;
  progressV = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  thumbnailFileListV = [];
  thumbnailUrlListV: string;
  thumbnailListV: File = null;

  coverFileList: any = [];
  coverUrlList: string;
  coverUrlAfterUpload: any;
  coverList: File = null;


  fileFileList = [];
  fileUrlList: string;
  fileList: File = null;

  firstFormGroup: UntypedFormGroup;
  lessonsCategories: LessCat[];
  spinner$: Observable<boolean>;
  private spinnerSubject = new Subject<boolean>();
  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private snackBar: MatSnackBar,
    private activateRouter: ActivatedRoute,
  ) {
    // this.getAllUnits()
    this.spinner$ = this.spinnerSubject.asObservable().pipe(startWith(true));
    this.getAllLessonsCats()
  }

  ngOnInit() {
    this.firstFormGroup = new UntypedFormGroup({
      lessonsCat: new UntypedFormControl('', [Validators.required]),
    });
    this.form = new UntypedFormGroup({
      image: new UntypedFormControl('', []),
      coverImage: new UntypedFormControl('', []),
      color: new UntypedFormControl('', []),
      index: new UntypedFormControl(1, [Validators.required]),
      nameAr: new UntypedFormControl('', [Validators.required]),
      nameEn: new UntypedFormControl('', []),
      nameFr: new UntypedFormControl('', []),
      video: new UntypedFormControl('', []),
      unit: new UntypedFormControl('', [Validators.required]),
      file: new UntypedFormControl('', []),
    });

    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.getObject(this.objectId);
      return;
    }
    setTimeout(() => {
      this.spinnerSubject.next(false);
    }, 100);
  }

  getObject(id: string) {
    this.full.getOneExercis(this.objectId).subscribe((data: any) => {
      this.full.getOneUnit(data.data.unit._id).subscribe((dataUnit:any)=> {
        this.firstFormGroup.get('lessonsCat')?.setValue(dataUnit.data.lessonsCat)
        this.form.patchValue(data.data);
        this.thumbnailFileList = data?.data?.image || [];
        this.thumbnailFileListV = data?.data?.video || [];
        this.thumbnailUrlList = data?.data?.image;
        this.thumbnailUrlListV = data?.data?.video;
        this.coverFileList = data.data.coverImage;
        this.coverUrlList = data.data.coverImage;
        this.fileFileList = data?.data?.file || [];
        this.fileUrlList = data?.data?.file;
        this.form.get('unit').setValue(data?.data?.unit?._id || '')
      });
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();
    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }
    if (!this.objectId) {
      this.full.addExercis(this.form.value).subscribe((data: any) => {
        this.router.navigateByUrl('/exercises-list');
        this.full.openSnackBar(data.message || '', 'أغلق', 'success');
      }, err => {
        this.full.openSnackBar(err || '', 'أغلق', 'danger');
      });
    } else {
      this.full.editOneExercis(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.router.navigateByUrl('/exercises-list');
          this.full.openSnackBar(response.message || '', 'أغلق', 'success');
        }, (error) => {
          this.full.openSnackBar(error || '', 'أغلق', 'danger');
        });
    }
  }
  getAllUnits(){
    this.full.getAllUnitsForDropdown(1, 500, '', true, false)
    .subscribe((response: any) => {
      this.units = response.data;
    }, (error) => {
    });
  }

  videoSelectFile(event) {
    this.thumbnailFileListV = [];
    this.thumbnailListV = event.target.files[0];
    this.thumbnailFileListV.push(this.thumbnailListV);
    this.videoUpload();
  }

  videoUpload() {
    let fd = new FormData();

    fd.append('my-video', this.thumbnailFileListV[0], this.thumbnailFileListV[0].name);
    this.full.uploadVideos(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progressV = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            video: event.body.videos[0]
          });
          this.thumbnailUrlListV = event.body.videos[0];
      }
    }, err => {
      this.thumbnailUrlListV = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }
  deleteUploadedVideo() {
    this.form.patchValue({
      image: null
    });
    this.progressV = 0;
    this.thumbnailFileListV = [];
    this.form.get('video').setValue('');
  }

  getAllLessonsCats(){
    this.full.getAllLessonsCategoriesForDropdownForAdd()
    .subscribe((response: any) => {
      this.lessonsCategories = response.data;
    }, (error) => {
    });
  }
  getUnitsForLessonsCat(){
    this.full.getAllUnitsForMaterialForDropdown(this.firstFormGroup.value.lessonsCat)
    .subscribe((response: any) => {
      this.units = response.data;
    }, (error) => {
    });
  }


  filefilesSelectFile(event) {
    this.fileFileList = [];
    this.fileList = event.target.files[0];
    this.fileFileList.push(this.fileList);    
    this.ffileUpload();
  }

  ffileUpload() {
    let fd = new FormData();
    fd.append('images', this.fileFileList[0], this.fileFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          // this.form.get('files').setValue([...this.form.get('files').value, {name: this.thumbnailFileList[0].name, url:event.body.images[0]}])
          // this.thumbnailUrlList2.push({name:this.thumbnailFileList[0].name, url: event.body.images[0]});
          this.form.patchValue({
            file: event.body.images[0]
          });
          this.fileUrlList = event.body.images[0];
      }
    }, err => {
      this.fileUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }
  deleteUploadedFileFiles(file) {
    this.form.patchValue({
      file: null
    });
    this.progress = 0;
    this.fileFileList = [];
  }

  coverSelectFile(event) {
    this.coverFileList = [];
    this.coverList = event.target.files[0];
    this.coverFileList.push(this.coverList);
    this.coverUpload();
  }

  coverUpload() {
    let fd = new FormData();

    fd.append('images', this.coverFileList[0], this.coverFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progressCover = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            coverImage: event.body.images[0]
          });
          this.coverUrlList = event.body.images[0];
      }
    }, err => {
      this.coverUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedCoverImage() {
    this.form.patchValue({
      coverImage: null
    });
    this.progressCover = 0;
    this.coverFileList = [];
  }
}
