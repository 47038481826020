<div class="container container-top-margin add-container">
  <div class="row">
    <div class="col">
      <mat-card class="add-card">
        <h4 class="text-center">{{ "editAboutUs" | translate }}</h4>
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
              <mat-tab-group>
                <mat-tab>
                  <ng-template mat-tab-label>
                    {{ "image" | translate }}
                  </ng-template>

                  <div class="progress form-group">
                    <div
                      class="progress-bar progress-bar-striped bg-success-bar"
                      role="progressbar"
                      [style.width.%]="progress"
                    ></div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="upload-file-card">
                        <div class="flex">
                          <img
                            src="assets/icons/upload-files.png"
                            width="30px"
                            alt=""
                          />
                          <h5 color="primary">
                            <span [hidden]="!urlList">{{
                              "imageUploadedSuccessfully" | translate
                            }}</span>
                            <span [hidden]="urlList">{{
                              "uploadNewImage" | translate
                            }}</span>
                          </h5>
                          <div class="spacer"></div>
                          <button
                            mat-raised-button
                            color="primary"
                            (click)="upload()"
                          >
                            {{ "uploadFile" | translate }}
                          </button>
                        </div>
                        <label class="file-list" *ngIf="fileList.length > 0">
                          <div class="container">
                            <div class="row">
                              <div class="col">
                                <i
                                  class="material-icons pointer"
                                  (click)="deleteUploadedImage()"
                                >
                                  close
                                </i>
                              </div>
                            </div>
                          </div>
                          <div class="container">
                            <div class="col-12 list-col">
                              <ul class="list-ul">
                                <li>
                                  {{ "name" | translate }}: &nbsp;
                                  {{ fileList[0]?.name }}
                                </li>
                                <hr />
                                <li>
                                  {{ "type" | translate }}: &nbsp;
                                  {{ fileList[0]?.type }}
                                </li>
                                <hr />
                                <li>
                                  {{ "size" | translate }}: &nbsp;
                                  {{ fileList[0]?.size / 1000 }}
                                  &nbsp;{{ "kb" | translate }}
                                </li>
                              </ul>
                              <ul class="second-list-ul">
                                <li>
                                  <img
                                    [src]="urlList ? urlList : urlAfterUpload"
                                    alt=""
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </label>
                        <label
                          *ngIf="fileList.length == 0"
                          class="uploader"
                          for="upload-photo"
                          appDrag
                          (files)="filesDropped($event)"
                        >
                          <svg
                            class="upload-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="146.628"
                            height="117.317"
                            viewBox="0 0 146.628 117.317"
                          >
                            <path
                              id="upload-icon-hover"
                              d="M122.549,34.885A51.368,51.368,0,0,0,24.51,48.755a29.353,29.353,0,0,0,6.9,57.9,
                                                                7.338,7.338,0,1,0,0-14.676,14.676,14.676,0,1,1,0-29.353,7.338,7.338,0,0,0,7.338-7.338,
                                                                36.691,36.691,0,0,1,71.4-11.815,7.338,7.338,0,0,0,5.724,4.917,22.015,22.015,0,0,1,1.761,
                                                                42.855,7.351,7.351,0,1,0,3.669,14.236,36.691,36.691,0,0,0,1.247-70.594Zm-41.9,15.19a7.586,
                                                                7.586,0,0,0-10.42,0L48.213,72.09a7.368,7.368,0,1,0,10.42,10.42l9.466-9.54v41.021a7.338,7.338,
                                                                0,1,0,14.676,0V72.971l9.466,9.54a7.368,7.368,0,1,0,10.42-10.42Z"
                              transform="translate(-1.991 -4.013)"
                              fill="#c9c9c9"
                            />
                          </svg>
                          <label for="upload-photo">{{
                            "dragDropFiles" | translate
                          }}</label>
                          <input
                            type="file"
                            (change)="selectFile($event)"
                            name="photo"
                            id="upload-photo"
                          />
                        </label>
                        <p>
                          {{ "filesExtensions" | translate }}: [png, jpg, jpeg,
                          gif, svg]
                        </p>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    {{ "arabic" | translate }}
                  </ng-template>
                  <div class="container">
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="example-full-width">
                          <textarea
                            rows="5"
                            formControlName="sectionDesAr"
                            matInput
                            placeholder="{{ 'sectionDesAr' | translate }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input
                            formControlName="fDecTitleAr"
                            matInput
                            placeholder="{{ 'fDecTitleAr' | translate }}"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="example-full-width">
                          <textarea
                            rows="5"
                            formControlName="fDecAr"
                            matInput
                            placeholder="{{ 'fDecAr' | translate }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input
                            formControlName="sDecTitleAr"
                            matInput
                            placeholder="{{ 'sDecTitleAr' | translate }}"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="example-full-width">
                          <textarea
                            rows="5"
                            formControlName="sDecAr"
                            matInput
                            placeholder="{{ 'sDecAr' | translate }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>
                    {{ "english" | translate }}
                  </ng-template>
                  <div class="container">
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="example-full-width">
                          <textarea
                            rows="5"
                            formControlName="sectionDesEn"
                            matInput
                            placeholder="{{ 'sectionDesEn' | translate }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input
                            formControlName="fDecTitleEn"
                            matInput
                            placeholder="{{ 'fDecTitleEn' | translate }}"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="example-full-width">
                          <textarea
                            rows="5"
                            formControlName="fDecEn"
                            matInput
                            placeholder="{{ 'fDecEn' | translate }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input
                            formControlName="sDecTitleEn"
                            matInput
                            placeholder="{{ 'sDecTitleEn' | translate }}"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="example-full-width">
                          <textarea
                            rows="5"
                            formControlName="sDecEn"
                            matInput
                            placeholder="{{ 'sDecEn' | translate }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>
                    {{ "french" | translate }}
                  </ng-template>
                  <div class="container">
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="example-full-width">
                          <textarea
                            rows="5"
                            formControlName="sectionDesFr"
                            matInput
                            placeholder="{{ 'sectionDesFr' | translate }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input
                            formControlName="fDecTitleFr"
                            matInput
                            placeholder="{{ 'fDecTitleFr' | translate }}"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="example-full-width">
                          <textarea
                            rows="5"
                            formControlName="fDecFr"
                            matInput
                            placeholder="{{ 'fDecFr' | translate }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input
                            formControlName="sDecTitleFr"
                            matInput
                            placeholder="{{ 'sDecTitleFr' | translate }}"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <mat-form-field class="example-full-width">
                          <textarea
                            rows="5"
                            formControlName="sDecFr"
                            matInput
                            placeholder="{{ 'sDecFr' | translate }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>

          <div class="row text-center">
            <div class="col">
              <button
                mat-raised-button
                (click)="edit()"
                [disabled]="!form.valid"
                color="primary"
              >
                <span>{{ "editAboutUs" | translate }}</span>
              </button>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
