import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MessageComponent } from "../modals/message/message.component";
import { ImageComponent } from "../modals/image/image.component";
import { ToastrManager } from "ng6-toastr-notifications";
import { FullServiceService } from "../../services/full-service.service";
import { UserService } from "../../services/user.service";
import {
  ConfirmDialogModel,
  ConfirmDialogComponent,
} from "../confirm-dialog/confirm-dialog.component";
import { Article } from "../../models/article";
import {
  Chart,
  ChartConfiguration,
  ChartEvent,
  ChartOptions,
  ChartType,
} from "chart.js";
import { BaseChartDirective } from "ng2-charts";

import { default as Annotation } from "chartjs-plugin-annotation";
import { Observable, Subject } from "rxjs";
import { startWith } from "rxjs/operators";

interface Statistics {
  lessonsCatTotal: number;
  lessonTotal: number;
  exercisTotal: number;
  baccalaureateTotal: number;
  bookTotal: number;
  examTotal: number;
  questionTotal: number;
  userTotal: number;
}
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {



dataStatistics$: Observable<Statistics>;
private dataStatisticsSubject = new Subject<Statistics>();
  pageEvent;

  users = 0;

  form: UntypedFormGroup;

  public lineChartData: ChartConfiguration<"line">["data"] = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        data: [65, 59, 80, 81, 56, 55, 40],
        label: "الطلاب",
        borderColor: "rgba(148,159,177,1)",
        pointBackgroundColor: "rgba(148,159,177,1)",
        pointBorderColor: "#fff",
        backgroundColor: "rgba(255,0,0,0.3)",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(148,159,177,0.8)",
        fill: "origin",
      },
      {
        data: [28, 48, 40, 19, 86, 27, 90],
        label: "الطلاب المشتركين",
        backgroundColor: "rgba(77,83,96,0.2)",
        borderColor: "rgba(77,83,96,1)",
        pointBackgroundColor: "rgba(77,83,96,1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(77,83,96,1)",
        fill: "origin",
      },
    ],
  };
  public lineChartOptions: ChartOptions<"line"> = {
    responsive: false,
  };
  public lineChartLegend = true;

  // Pie
  public pieChartOptions: ChartOptions<"pie"> = {
    responsive: false,
  };
  public pieChartLabels = [
    ["المشتركين"],
    ["الطلاب الكلي"],
    "الامتحانات المقدمة",
  ];
  public pieChartDatasets = [
    {
      data: [300, 500, 100],
    },
  ];
  public pieChartLegend = true;
  public pieChartPlugins = [];

  constructor(
    private full: FullServiceService,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    public dialog: MatDialog,
    private toastr: ToastrManager
  ) {
    this.dataStatistics$ = this.dataStatisticsSubject.asObservable().pipe(startWith({
      lessonsCatTotal: 0,
      lessonTotal: 0,
      exercisTotal: 0,
      baccalaureateTotal: 0,
      bookTotal: 0,
      examTotal: 0,
      questionTotal: 0,
      userTotal: 0,
    }));
    this.getStatistic();
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      index: new UntypedFormControl("", [Validators.required]),
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl("/" + url);
  }
  getStatistic(){
    this.full.getStatisticsForDashboard().subscribe((data: any)=>{
      this.dataStatisticsSubject.next(data.data);
    })
  }
}
