import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FullServiceService } from '../../services/full-service.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-logger',
  templateUrl: './logger.component.html',
  styleUrls: ['./logger.component.scss']
})
export class LoggerComponent implements OnInit {
  activeForm: UntypedFormGroup;
  userFilter: UntypedFormGroup;
  data: any[] = [];
  pageEvent;
  displayedColumns: string[] = ['user', 'method', 'apiLink', 'statusCode', 'date'];
  dataSource: MatTableDataSource<any>;

  users = [];
  usersSearch = '';

  userID = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {
    this.userFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      role: new UntypedFormControl('user', []),
      deleted: new UntypedFormControl(false, []),
      active: new UntypedFormControl(true, []),
    })
    this.getAllData(1);
  }

  ngOnInit() {
    this.userFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      role: new UntypedFormControl('user', []),
      deleted: new UntypedFormControl(false, []),
      active: new UntypedFormControl(true, []),
    })
    this.getAllUsers();

    // this.activeForm = new FormGroup({
    //   fullName: new FormControl('', [Validators.required]),
    //   email: new FormControl('', [Validators.required]),
    //   phone: new FormControl('', [Validators.required]),
    //   message: new FormControl('', [Validators.required]),
    // });
    // this.dataSource.sort = this.sort;
  }

  filterByUser(id: string) {
    this.userID = id;
    if (this.userID) {
      this.getAllDataForUser(1, id)
    } else {
      this.getAllData(1);
    }
  }

  getAllData(event: any) {
    this.full.getAllLogger(event.pageIndex + 1 || 1, event.pageSize).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  getAllDataForUser(event: any, userID) {
    this.full.getAllLoggsForUser(event.pageIndex + 1 || 1, event.pageSize, userID).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllUsers() {
    this.full.getAllUsersForDropDownWithSearch(1, 100, this.userFilter.value)
      .subscribe((response: any) => {
        this.users = response.data;
      }, (error) => {
      });
  }

  changeSearch() {
    if (this.usersSearch) {
      this.userFilter.patchValue({
        searchWord: this.usersSearch
      })

      this.full.getAllUsersForDropDownWithSearch(1, 10, this.userFilter.value)
        .subscribe((response: any) => {
          this.users = response.data;
        }, (error) => {
        });
    }
  }

  delete(id) {
    this.full.deleteOneComplaints(id)
      .subscribe((response) => {
        this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
        this.full.openSnackBar(this.translate.instant('hasBeenDeleted') || '', 'أغلق', 'success');
      }, (error) => {
        this.full.openSnackBar(error || '', 'أغلق', 'danger');
      });
  }

  replaceText(p) {
    return p.replaceAll('/api/v1/', '')
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }

}
