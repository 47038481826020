import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FullServiceService } from './../../../services/full-service.service';
import { CustomValidators } from 'ng2-validation';
import { UserService } from './../../../services/user.service';
import { AuthService } from './../../../services/auth.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataService } from '../../services/global-data.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  passForm: UntypedFormGroup;
  image: any;
  userData: any;
  profileImage: string | ArrayBuffer;
  hideImgUploader = false;
  objectId;

  form: UntypedFormGroup;

  constructor(
    public auth: AuthService,
    private userService: UserService,
    private full: FullServiceService,
    private translate: TranslateService,
    private global: GlobalDataService
  ) { }

  ngOnInit() {
    const newPassword = new UntypedFormControl('', [Validators.required]);
    const newPasswordConfirm = new UntypedFormControl('', [CustomValidators.equalTo(newPassword)]);

    this.passForm = new UntypedFormGroup({
      oldPassword: new UntypedFormControl('', [Validators.required]),
      newPassword,
      newPasswordConfirm
    });

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.required, Validators.pattern('^[+]*[\s\./0-9]{3,}$')]),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}')]),
    });

    this.userService.getMyData().subscribe((data: any) => {
      console.log('d', data);
      
      this.global.userData = data.data;
      this.form.patchValue(this.global.userData);

    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });


    if (localStorage.getItem('currentUser')) {
      this.userData = JSON.parse(localStorage.getItem('currentUser')).data._id;
    }
  }

  selectImage(event) {
    const reader = new FileReader();

    this.profileImage = event.target.files[0];
    this.image = event.target.files[0];

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.profileImage = reader.result;
      this.hideImgUploader = true;
      this.updateImage();
    };
  }

  deleteImg() {
    this.profileImage = '';
    this.hideImgUploader = false;
  }

  updateImage() {
    if (!this.hideImgUploader) {
      return false;
    }

    const fd = new FormData();
    fd.append('images', this.image, this.image.name);

    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0].link
          });
        // this.changeImage();
      }
    }, err => {
    });
  }

  changePassword() {
    // this.userService.changeUserPassword(this.passForm.value)
    //   .subscribe((data: any) => {
    //     this.passForm.reset();
    //     this.full.openSnackBar(data.message[0] || '', 'أغلق', 'success');
    //   }, err =>
    //     this.full.openSnackBar(err || '', 'أغلق', 'danger')
    //   )
  }

  editProfile() {
    this.userService.editProfile(this.form.value)
      .subscribe((data: any) => {
        this.global.userData = data.data;
        this.passForm.reset();
        this.full.openSnackBar(this.translate.instant('profileDataEditedSuccess') || '', 'أغلق', 'success');
      }, err =>
        this.full.openSnackBar(err || '', 'أغلق', 'danger')
      );
  }

  changeImage() {
    // this.userService.changeUserImage(this.form.value).subscribe((data: any) => {
    //   this.full.openSnackBar(data.message[0] || '', 'أغلق', 'success');
    // }, err => {
    //   this.full.openSnackBar(err || '', 'أغلق', 'danger');
    // });
  }
}
