import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-discount',
  templateUrl: './add-discount.component.html',
  styleUrls: ['./add-discount.component.scss']
})

export class AddDiscountComponent implements OnInit {
  form: UntypedFormGroup;
  objectId: string;
  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  coverFileList: any = [];
  coverUrlList: string;
  coverUrlAfterUpload: any;
  coverList: File = null;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private snackBar: MatSnackBar,
    private activateRouter: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      image: new UntypedFormControl('', []),
      nameAr: new UntypedFormControl('', []),
      nameEn: new UntypedFormControl('', []),
      nameFr: new UntypedFormControl('', []),
      discountValue: new UntypedFormControl(0, [Validators.required, Validators.min(1), Validators.max(99)]),
      code: new UntypedFormControl('', [Validators.required]),
    });

    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneDiscount(this.objectId).subscribe((data: any) => {
      this.form.patchValue(data.data);
      this.thumbnailFileList = data.data.image;
      this.thumbnailUrlList = data.data.image;
      this.coverFileList = data.data.coverImage;
      this.coverUrlList = data.data.coverImage;
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  coverSelectFile(event) {
    this.coverFileList = [];
    this.coverList = event.target.files[0];
    this.coverFileList.push(this.coverList);
    this.coverUpload();
  }

  coverUpload() {
    let fd = new FormData();

    fd.append('images', this.coverFileList[0], this.coverFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            coverImage: event.body.images[0]
          });
          this.coverUrlList = event.body.images[0];
      }
    }, err => {
      this.coverUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedCoverImage() {
    this.form.patchValue({
      coverImage: null
    });
    this.progress = 0;
    this.coverFileList = [];
  }


  addEdit() {
    if (!this.form.valid) {
      return false;
    }
    if (!this.objectId) {
      this.full.addDiscount(this.form.value).subscribe((data: any) => {
        this.router.navigateByUrl('/discount-list');
        this.full.openSnackBar(data.message || '', 'أغلق', 'success');
      }, err => {
        this.full.openSnackBar(err || '', 'أغلق', 'danger');
      });
    } else {
      this.full.editOneDiscount(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.router.navigateByUrl('/discount-list');
          this.full.openSnackBar(response.message || '', 'أغلق', 'success');
        }, (error) => {
          this.full.openSnackBar(error || '', 'أغلق', 'danger');
        });
    }
  }
}
