import { ConversationsComponent } from './conversations/conversations.component';
import { FullServiceService } from './../../services/full-service.service';
import { TranslateService } from '@ngx-translate/core';
import * as io from 'socket.io-client';
import { apiUrl } from './../../models/BaseURL';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})

export class ChatComponent implements OnInit {
  activeForm: UntypedFormGroup;
  data: any[] = [];
  pageEvent;
  displayedColumns: string[] = ['fullName', 'email', 'phone', 'message', 'newMessages'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;
  private socket;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {
    this.socket = io(apiUrl);

  }

  ngOnInit() {
    this.socket.on('refreshMessages', () => {
      this.getAllMessages(1);
      this.playAudio();
    });
    this.getAllMessages(1);
  }

  playAudio() {
    const audio = new Audio();
    audio.src = '../../../assets/nots/1.mp3';
    audio.load();
    audio.play();
  }

  getAllMessages(event: any) {
    this.full.getAllMessages().subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data.reverse());
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.full.deleteOneContact(id)
      .subscribe((response) => {
        this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
        this.toastr.successToastr(this.translate.instant('hasBeenDeleted'));
      }, (error) => {
        this.toastr.errorToastr(error);
      });
  }

  openMessages(chat: any): void {
    const dialogRef = this.dialog.open(ConversationsComponent, {
      width: '650px',
      data: { chat }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllMessages(1);
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }

}
