import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'


@Component({
  selector: 'app-edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: ['./edit-comment.component.scss']
})
export class EditCommentComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private full: FullServiceService,
    private toastr: ToastrManager
  ) {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      comment: new UntypedFormControl('', [Validators.required])
    });

    this.full.getOneComment(this.data.id).subscribe((data: any) => {
      this.form.patchValue({
        comment: data.data.comment
      });
    });
  }

  edit() {
    this.full.editOneComment(this.data.id, this.form.value).subscribe((data: any) => {
      this.dialogRef.close();
      this.toastr.successToastr(data.message);
    }, err => {
      this.toastr.errorToastr(err);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
