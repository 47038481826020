import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ImageComponent } from '../image/image.component';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FullServiceService } from '../../../services/full-service.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  message;
  chatHistory = [];
  form: UntypedFormGroup;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    public dialogRef: MatDialogRef<ImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.message = this.data;
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', []),
      email: new UntypedFormControl('', []),
      phone: new UntypedFormControl('', []),
      message: new UntypedFormControl('', [Validators.required]),
      user: new UntypedFormControl(this.message.user, [Validators.required]),
      parent: new UntypedFormControl(this.message._id, [Validators.required]),
    });
    this.getUserComplaints(this.message._id);
  }

  send() {
    this.full.sendComplaint(this.form.value).subscribe((data: any) => {
      this.chatHistory.push(data.data)
      // this.toastr.successToastr('تم ارسال الرسالة بنجاح');
      this.form.get('message').reset();
    }, err => {
      this.toastr.errorToastr(err);
    });
  }

  getUserComplaints(id: string) {
    this.full.getComplaintsForParent(id).subscribe((data: any) => {
      this.chatHistory = data.data;
      
    }, err => {
      this.toastr.errorToastr(err);
    });
  }

}
