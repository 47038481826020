import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  image = [];
  title: string = 'يرجى الضغط على حفظ التغيرات لعدم حذف الصور';

  text = `{ "text": "This is text file!中文" }`;
  fileName?: string;
  options: any = {
    autoBom: false,
  };
  images = [];
  constructor(
    public dialogRef: MatDialogRef<ImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpClient: HttpClient,
    private fileSaverService: FileSaverService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.image = this.data;
    this.images = this.data;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.image, event.previousIndex, event.currentIndex);
  }

  removeItem(item) {

    this.image = this.image.filter(e => e !== item);
  }
  openImage(image: any){
    window.open(image, '_blank')
  }
  onDown(type: string, fromRemote: boolean, image: string) {
    // const nn = image.split('.').slice(0,-1).join('.');
    // console.log('mm', nn);
    
    const fileName = `save.${type}`;
    if (fromRemote) {
      this.httpClient
        // .get(`${nn}.pdf`, {
        .get(image, {
          observe: 'response',
          responseType: 'blob',
        })
        .subscribe((res) => {
          this.fileSaverService.save(res.body, fileName);
        });
      return;
    }
    const fileType = this.fileSaverService.genType(fileName);
    const txtBlob = new Blob([this.text], { type: fileType });
    this.fileSaverService.save(txtBlob, fileName, undefined, this.options);
  }


  getBase64Image(img) {
    var canvas = document.createElement('canvas');
    canvas.classList.add('myStyle');
    console.log(img.width, 'x', img.height);
    canvas.width = 446;
    canvas.height = 631;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL('image/png');
    return { img: dataURL, width: canvas.width, height: canvas.height };
  }

  download() {
    // let doc = new jsPDF('p', 'px', 'a4');
    // var width = doc.internal.pageSize;
    // for (var i = 0; i < this.images.length; i++) {
    //   let imageData: any = this.getBase64Image(
    //     document.getElementById('img' + i)
    //   );
    //   doc.addImage(
    //     imageData.img,
    //     'JPG',
    //     10,
    //     10,
    //     imageData.width,
    //     imageData.height
    //   );
    //   doc.addPage();
    // }
    // doc.save('FirstPdf.pdf');
    var doc = new jsPDF();
    // doc.addImage(this.image[0], "JPEG", 0, 0, 0, 0);
    doc.addImage(this.image[0], 20, 10, 170,280);
    doc.save('file.pdf');
  }
}
