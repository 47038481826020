import { map } from 'rxjs/operators';
import { Sections } from './../models/Sections';
import { apiUrl } from './../models/BaseURL';
import { Observable } from 'rxjs';
import { Baccalaureate, Book, Category, City, Discount, Exam, Exercis, LessCat, Lesson, Package, PaymentMethod, Question, RequestPackage, Unit } from './../models/category';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Article } from '../models/article';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class FullServiceService {
  videoLinks = [];
  sections: Sections[] = [];
  private socket;
  public user;

  readonly api = apiUrl;
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    // this.socket = io(apiUrl);
  }

  async openSnackBar(message: string, action: string, panelClass) {
    this.snackBar.open(message, action, {
      panelClass,
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

    // Office Transfer List
    getAllOfficeTrasnfers(page: Number, limit: Number) {
      return this.http.get(`${this.api}/officeTransfer?page=${page}&limit=${limit}`);
    }
  
    addOfficeTrasnfer(body: any) {
      return this.http.post(`${this.api}/officeTransfer/new`, body);
    }
  
    getOneOfficeTrasnfer(id: string) {
      return this.http.get(`${this.api}/officeTransfer/${id}`);
    }
  
    editOneOfficeTrasnfer(id: string, body: any) {
      return this.http.patch(`${this.api}/officeTransfer/${id}`, body);
    }
  
    deleteOneOfficeTrasnfer(id: string) {
      return this.http.delete(`${this.api}/officeTransfer/${id}`);
    }

    // Code List
    getAllCodes(page: Number, limit: Number) {
      return this.http.get(`${this.api}/codes?page=${page}&limit=${limit}`);
    }

    addCode(body: any) {
      return this.http.post(`${this.api}/codes/new`, body);
    }

    getOneCode(id: string) {
      return this.http.get(`${this.api}/codes/${id}`);
    }

    editOneCode(id: string, body: any) {
      return this.http.patch(`${this.api}/codes/${id}`, body);
    }

    deleteOneCode(id: string) {
      return this.http.delete(`${this.api}/codes/${id}`);
    }
    
    // Transfers
    getAllTransfers(page = 0, limit = 10) {
      return this.http.get(`${this.api}/charge?page=${page}&limit=${limit}`);
    }
    editTransfer(id: string, body: any) {
      return this.http.patch(`${this.api}/charge/${id}`, body);
    }

   // Bank List
   getAllBanks(page: Number, limit: Number) {
    return this.http.get(`${this.api}/banks?page=${page}&limit=${limit}`);
  }

  addBank(body: any) {
    return this.http.post(`${this.api}/banks/new`, body);
  }

  getOneBank(id: string) {
    return this.http.get(`${this.api}/banks/${id}`);
  }

  editOneBank(id: string, body: any) {
    return this.http.patch(`${this.api}/banks/${id}`, body);
  }

  deleteOneBank(id: string) {
    return this.http.delete(`${this.api}/banks/${id}`);
  }

  // Categories
  addCategory(body: Category) {
    return this.http.post(`${this.api}/category/new`, body) as Observable<Category>;
  }

  getAllParentCategoriesForDropdown(page = 0, limit = 10, searchWord = '') {
    return this.http.get(`${this.api}/category/parent/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}`);
  }


  getAllCategories(page = 0, limit = 10) {
    return this.http.get(`${this.api}/category?page=${page}&limit=${limit}`);
  }

  getAllParentCategories(page = 0, limit = 10, searchWord = '') {
    return this.http.get(`${this.api}/category/parent?page=${page}&limit=${limit}&searchWord=${searchWord}`);
  }

  getAllSubsForCategory(page = 0, limit = 10, id: string) {
    return this.http.get(`${this.api}/category/sub-for-category/${id}?page=${page}&limit=${limit}`);
  }

  getCategoriesNames() {
    return this.http.get(`${this.api}/category/parent/dropdown`);
  }

  getOneCategory(id: string) {
    return this.http.get(`${this.api}/category/${id}`);
  }


  editOneCategory(id: string, body: Category) {
    return this.http.patch(`${this.api}/category/${id}`, body);
  }


  deleteOneCategory(id: string) {
    return this.http.delete(`${this.api}/category/${id}`);
  }

  // Cities
  // addCity(body: Category) {
  //   return this.http.post(`${this.api}/city/new`, body) as Observable<Category>;
  // }

  getAllParentCitiesForDropdown(page = 0, limit = 10) {
    return this.http.get(`${this.api}/city/parent/dropdown?page=${page}&limit=${limit}`);
  }

  // getAllCities(page = 0, limit = 10) {
  //   return this.http.get(`${this.api}/city?page=${page}&limit=${limit}`);
  // }

  getAllParentCities(page = 0, limit = 10) {
    return this.http.get(`${this.api}/city/parent?page=${page}&limit=${limit}`);
  }

  getAllSubsForCity(page = 0, limit = 10, id: string, searchWord = '') {
    return this.http.get(`${this.api}/city/state/${id}?page=${page}&limit=${limit}&searchWord=${searchWord}`);
  }

  getCitiesNames() {
    return this.http.get(`${this.api}/city/parent/dropdown`);
  }

  // getOneCity(id: string) {
  //   return this.http.get(`${this.api}/city/${id}`);
  // }

  // editOneCity(id: string, body: Category) {
  //   return this.http.patch(`${this.api}/city/${id}`, body);
  // }
  addCity(body: City) {
    return this.http.post(`${this.api}/cities/new`, body) as Observable<City>;
  }
  getOneCity(id: string) {
    return this.http.get(`${this.api}/cities/${id}`);
  }
  editOneCity(id: string, body: City) {
    return this.http.patch(`${this.api}/cities/${id}`, body);
  }
  getAllCities(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/cities?page=${page}&limit=${limit}`, body);
  }
  changeDeletedCity(id: string, body: any) {
    return this.http.patch(`${this.api}/cities/${id}/change-delete-activate`, body);
  }
  getAllCitiesForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/cities/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }

  sendFcm(body: any) {
    return this.http.post(`${this.api}/noti/new`, body);
  }

  sendFcmForUser(body: any) {
    return this.http.post(`${this.api}/noti/new/user`, body);
  }

  deleteOneCity(id: string) {
    return this.http.delete(`${this.api}/city/${id}`);
  }

  // Articles
  getArticlesForCategory(id: string, page = 1, limit = 10) {
    return this.http.get(`${this.api}/category/articles/${id}?page=${page}&limit=${limit}`);
  }

  getAllArticles(page = 0, limit = 10) {
    return this.http.get(`${this.api}/article?page=${page}&limit=${limit}`);
  }

  addArticle(body: Article) {
    return this.http.post(`${this.api}/article`, body) as Observable<Article>;
  }

  getOneArticle(id: string) {
    return this.http.get(`${this.api}/article/${id}`);
  }

  editOneArticle(id: string, body: Article) {
    return this.http.put(`${this.api}/article/${id}`, body);
  }

  deleteOneArticle(id: string) {
    return this.http.delete(`${this.api}/article/${id}`);
  }

  changeStatusArticle(id: string, body: Article) {
    return this.http.put(`${this.api}/article/activate/${id}`, body);
  }

  // Products
  getProductsForCategory(id: string, page = 1, limit = 10) {
    return this.http.get(`${this.api}/products/category/${id}?page=${page}&limit=${limit}`);
  }

  getAllProducts(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/products?page=${page}&limit=${limit}`, body);
  }

  getUnreadedDevReqs(page = 0, limit = 10) {
    return this.http.get(`${this.api}/req-dev/unreaded/all?page=${page}&limit=${limit}`);
  }

  getUnreadedCatReqs(page = 0, limit = 10) {
    return this.http.get(`${this.api}/add-cat-req/unreaded/all?page=${page}&limit=${limit}`);
  }

  getUnreadedGallery(page = 0, limit = 10) {
    return this.http.get(`${this.api}/gallery/unreaded/all?page=${page}&limit=${limit}`);
  }

  addProduct(body: any) {
    return this.http.post(`${this.api}/products/new`, body) as Observable<any>;
  }

  getOneProduct(id: string) {
    return this.http.get(`${this.api}/products/${id}`);
  }

  editOneProduct(id: string, body: any) {
    return this.http.patch(`${this.api}/products/${id}`, body);
  }

  deleteOneProduct(id: string) {
    return this.http.delete(`${this.api}/products/${id}`);
  }

  deleteOneUser(id: string) {
    return this.http.delete(`${this.api}/users/${id}`);
  }

  deleteOneFaq(id: string) {
    return this.http.delete(`${this.api}/faq/${id}`);
  }

  changeStatusProduct(id: string, body: any) {
    return this.http.patch(`${this.api}/products/${id}`, body);
  }
  addNoteForUser(id: string, body: any) {
    return this.http.post(`${this.api}/users/${id}/add-note-to-user`, body);
  }


  getVerifiedUsersForHome(page = 1, limit = 4) {
    return this.http.put(`${this.api}/users/verified/home?page=${page}&limit=${limit}`, {});
  }

  // Client Base List
  addClientBase(body: any) {
    return this.http.post(`${this.api}/clients-base/new`, body) as Observable<any>;
  }

  getAllClientBase(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/clients-base?page=${page}&limit=${limit}`, body);
  }

  getOneClientBase(id: string) {
    return this.http.get(`${this.api}/clients-base/${id}`);
  }

  editOneClientBase(id: string, body: any) {
    return this.http.patch(`${this.api}/clients-base/${id}`, body);
  }

  deleteOneClientBase(id: string) {
    return this.http.delete(`${this.api}/clients-base/${id}`);
  }

  // Comminucation Attempts List
  addCommunicationAttempt(body: any) {
    return this.http.post(`${this.api}/communication-attempts/new`, body) as Observable<any>;
  }

  getAllCommunicationAttempt(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/communication-attempts?page=${page}&limit=${limit}`, body);
  }

  getOneCommunicationAttempt(id: string) {
    return this.http.get(`${this.api}/communication-attempts/${id}`);
  }

  editOneCommunicationAttempt(id: string, body: any) {
    return this.http.patch(`${this.api}/communication-attempts/${id}`, body);
  }

  deleteOneCommunicationAttempt(id: string) {
    return this.http.delete(`${this.api}/communication-attempts/${id}`);
  }

  getCommunicationAttemptForClient(id: string) {
    return this.http.get(`${this.api}/communication-attempts/client/${id}`);
  }

  // Client Address List
  addClientAddress(body: any) {
    return this.http.post(`${this.api}/clientAddress/new`, body) as Observable<any>;
  }

  getAllClientAddress(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/clientAddress?page=${page}&limit=${limit}`, body);
  }

  getOneClientAddress(id: string) {
    return this.http.get(`${this.api}/clientAddress/${id}`);
  }

  editOneClientAddress(id: string, body: any) {
    return this.http.patch(`${this.api}/clientAddress/${id}`, body);
  }

  deleteOneClientAddress(id: string) {
    return this.http.delete(`${this.api}/clientAddress/${id}`);
  }

  getClientAddressForClient(id: string) {
    return this.http.get(`${this.api}/clientAddress/client/${id}`);
  }

  // Authors
  addAuthor(body: any) {
    return this.http.post(`${this.api}/author`, body) as Observable<any>;
  }

  getOneAuthor(id: string) {
    return this.http.get(`${this.api}/author/${id}`);
  }

  getOneComment(id: string) {
    return this.http.get(`${this.api}/comments/${id}`);
  }

  editOneAuthor(id: string, body: any) {
    return this.http.put(`${this.api}/author/${id}`, body);
  }

  editOneComment(id: string, body: any) {
    return this.http.patch(`${this.api}/comments/${id}`, body);
  }

  deleteOneAuthor(id: string) {
    return this.http.delete(`${this.api}/author/${id}`);
  }

  changeStatusAuthor(id: string, body: any) {
    return this.http.put(`${this.api}/author/activate/${id}`, body);
  }


  // Contacts
  getAllContacts(page = 0, limit = 10) {
    return this.http.get(`${this.api}/contacts?page=${page}&limit=${limit}`);
  }

  deleteOneContact(id: string) {
    return this.http.delete(`${this.api}/contacts/${id}`);
  }

  // Contacts
  getAllComplaints(page = 0, limit = 10) {
    return this.http.get(`${this.api}/complaint?page=${page}&limit=${limit}`);
  }
  getAllUnreadedComplaints(page = 0, limit = 10) {
    return this.http.get(`${this.api}/complaint/unreaded/all?page=${page}&limit=${limit}`);
  }

  getAllDevelopmentRequests(page = 0, limit = 10) {
    return this.http.get(`${this.api}/req-dev?page=${page}&limit=${limit}`);
  }

  getAllVerifyRequests(page = 0, limit = 10) {
    return this.http.get(`${this.api}/verify?page=${page}&limit=${limit}`);
  }

  getAllAddCatReq(page = 0, limit = 10) {
    return this.http.get(`${this.api}/add-cat-req?page=${page}&limit=${limit}`);
  }

  getCommentsForProduct(page = 1, limit = 12, id: string): Observable<any[]> {
    return this.http.get(`${this.api}/comments/product/${id}?page=${page}&limit=${limit}`) as Observable<any[]>;
  }

  getCommentsForUser(page = 1, limit = 12, id: string): Observable<any[]> {
    return this.http.get(`${this.api}/comments/profile/all/${id}?page=${page}&limit=${limit}`) as Observable<any[]>;
  }

  deleteOneComment(id: string) {
    return this.http.delete(`${this.api}/comments/${id}`);
  }

  deleteOneComplaints(id: string) {
    return this.http.delete(`${this.api}/complaint/${id}`);
  }

  deleteOneVerify(id: string) {
    return this.http.delete(`${this.api}/verify/${id}`);
  }

  deleteOneDevReq(id: string) {
    return this.http.delete(`${this.api}/req-dev/${id}`);
  }

  deleteOneCatReq(id: string) {
    return this.http.delete(`${this.api}/add-cat-req/${id}`);
  }

  // Logger Services
  getAllLogger(page = 0, limit = 10) {
    return this.http.get(`${this.api}/log?page=${page}&limit=${limit}`);
  }

  getAllLoggsForUser(page = 0, limit = 10, id: string) {
    return this.http.get(`${this.api}/log/user/${id}?page=${page}&limit=${limit}`);
  }

  // inquiry
  getAllInquiry(page = 0, limit = 10) {
    return this.http.get(`${this.api}/inquiry?page=${page}&limit=${limit}`);
  }

  deleteOneInquiry(id: string) {
    return this.http.delete(`${this.api}/inquiry/${id}`);
  }

  // Code Repos Management
  getAllCodeRepos(page: Number, limit: Number) {
    return this.http.get(`${this.api}/code-reposity?page=${page}&limit=${limit}`, {});
  }

  addCodeRepos(body: any) {
    return this.http.post(`${this.api}/code-reposity/new`, body);
  }

  getOneCodeRepos(id: string) {
    return this.http.get(`${this.api}/code-reposity/${id}`);
  }

  editOneCodeRepos(id: string, body: any) {
    return this.http.patch(`${this.api}/code-reposity/${id}`, body);
  }

  deleteOneCodeRepos(id: string) {
    return this.http.delete(`${this.api}/code-reposity/${id}`);
  }

  // Orders
  getAllOrders(page = 0, limit = 10) {
    return this.http.get(`${this.api}/order?page=${page}&limit=${limit}`);
  }

  deleteOneOrder(id: string) {
    return this.http.delete(`${this.api}/orders/${id}`);
  }

  // accountRequest
  getAllAccountOrders(page = 0, limit = 10) {
    return this.http.get(`${this.api}/accountRequest?page=${page}&limit=${limit}`);
  }

  deleteOneAccountOrder(id: string) {
    return this.http.delete(`${this.api}/accountRequest/${id}`);
  }

  // Sliders
  // getAllSliders() {
  //   return this.http.get(`${this.api}/slider`);
  // }
  getAllSliders(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/slider?page=${page}&limit=${limit}`, body);
  }
  changeDeletedSlider(id: string, body: any) {
    return this.http.patch(`${this.api}/slider/${id}/change-delete-activate`, body);
  }

  addSlider(body: any) {
    return this.http.post(`${this.api}/slider/new`, body);
  }

  sendComplaint(body: any) {
    return this.http.post(`${this.api}/complaint/new`, body);
  }

  getComplaintsForParent(id: string) {
    return this.http.get(`${this.api}/complaint/parent/${id}`);
  }

  getOneSlider(id: string) {
    return this.http.get(`${this.api}/slider/${id}`);
  }

  editOneSlider(id: string, body: any) {
    return this.http.patch(`${this.api}/slider/${id}`, body);
  }

  deleteAllSliders() {
    return this.http.delete(`${this.api}/slider`);
  }

  deleteOneSlider(id: string) {
    return this.http.delete(`${this.api}/slider/${id}`);
  }

  changeStatusSlider(id: string, body: any) {
    return this.http.put(`${this.api}/slider/activate/${id}`, body);
  }

  // Lot Services
  getAllLots(page = 1, limit = 20) {
    return this.http.get(`${this.api}/lot?page=${page}&limit=${limit}`);
  }

  getAllLotSubs(page = 1, limit = 20, id: string) {
    return this.http.get(`${this.api}/lot/subs/${id}?page=${page}&limit=${limit}`);
  }

  addLot(body: any) {
    return this.http.post(`${this.api}/lot/new`, body);
  }

  getOneLot(id: string) {
    return this.http.get(`${this.api}/lot/${id}`);
  }

  editOneLot(id: string, body: any) {
    return this.http.patch(`${this.api}/lot/${id}`, body);
  }

  deleteOneLot(id: string) {
    return this.http.delete(`${this.api}/lot/${id}`);
  }


  // Gallery
  getAllGallerys(page = 1, limit = 20, userId = '') {
    return this.http.get(`${this.api}/gallery/all?page=${page}&limit=${limit}&userId=${userId}`);
  }

  addGallery(body: any) {
    return this.http.post(`${this.api}/gallery/new`, body);
  }

  getOneGallery(id: string) {
    return this.http.get(`${this.api}/gallery/${id}`);
  }

  editOneGallery(id: string, body: any) {
    return this.http.patch(`${this.api}/gallery/${id}`, body);
  }

  deleteOneGallery(id: string) {
    return this.http.delete(`${this.api}/gallery/${id}`);
  }

  // Feature
  // getAllFeatures() {
  //   return this.http.get(`${this.api}/features`);
  // }
  getAllFeatures(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/features?page=${page}&limit=${limit}`, body);
  }

  addFeature(body: any) {
    return this.http.post(`${this.api}/features/new`, body);
  }

  getOneFeature(id: string) {
    return this.http.get(`${this.api}/features/${id}`);
  }

  editOneFeature(id: string, body: any) {
    return this.http.patch(`${this.api}/features/${id}`, body);
  }

  deleteOneFeature(id: string) {
    return this.http.delete(`${this.api}/features/${id}`);
  }
  changeDeletedFeature(id: string, body: any) {
    return this.http.patch(`${this.api}/features/${id}/change-delete-activate`, body);
  }
  getAllFeaturesForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/features/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }

  // Specials
  getAllSpecials(page = 1, limit = 20) {
    return this.http.get(`${this.api}/specials?page=${page}&limit=${limit}`);
  }

  addSpecials(body: any) {
    return this.http.post(`${this.api}/specials/new`, body);
  }

  getOneSpecials(id: string) {
    return this.http.get(`${this.api}/specials/${id}`);
  }

  editOneSpecials(id: string, body: any) {
    return this.http.patch(`${this.api}/specials/${id}`, body);
  }

  deleteOneSpecials(id: string) {
    return this.http.delete(`${this.api}/specials/${id}`);
  }

  getAllSpecialsForCats(page = 1, limit = 20, body) {
    return this.http.put(`${this.api}/specials/cats/all?page=${page}&limit=${limit}`, body);
  }
  
  // Discounts
  getAllDiscounts(page = 1, limit = 20) {
    return this.http.get(`${this.api}/discount?page=${page}&limit=${limit}`);
  }

  addDiscounts(body: any) {
    return this.http.post(`${this.api}/discount/new`, body);
  }

  getOneDiscounts(id: string) {
    return this.http.get(`${this.api}/discount/${id}`);
  }

  editOneDiscounts(id: string, body: any) {
    return this.http.patch(`${this.api}/discount/${id}`, body);
  }

  deleteOneDiscounts(id: string) {
    return this.http.delete(`${this.api}/discount/${id}`);
  }

  getAllDiscountsForCats(page = 1, limit = 20, body) {
    return this.http.put(`${this.api}/discount/cats/all?page=${page}&limit=${limit}`, body);
  }

  // Services
  getAllServices(page = 0, limit = 10) {
    return this.http.get(`${this.api}/our-services?page=${page}&limit=${limit}`);
  }

  addService(body: any) {
    return this.http.post(`${this.api}/our-services`, body);
  }

  getOneService(id: string) {
    return this.http.get(`${this.api}/our-services/${id}`);
  }

  editOneService(id: string, body: any) {
    return this.http.put(`${this.api}/our-services/${id}`, body);
  }

  deleteAllServices() {
    return this.http.delete(`${this.api}/our-services`);
  }

  deleteOneService(id: string) {
    return this.http.delete(`${this.api}/our-services/${id}`);
  }

  changeStatusService(id: string, body: any) {
    return this.http.put(`${this.api}/our-services/activate/${id}`, body);
  }

  // Agents
  getAllAgents(page = 0, limit = 10) {
    return this.http.get(`${this.api}/our-agents?page=${page}&limit=${limit}`);
  }
  addAgent(body: any) {
    return this.http.post(`${this.api}/our-agents`, body);
  }
  getOneAgent(id: string) {
    return this.http.get(`${this.api}/our-agents/${id}`);
  }
  editOneAgent(id: string, body: any) {
    return this.http.put(`${this.api}/our-agents/${id}`, body);
  }
  deleteAllAgents() {
    return this.http.delete(`${this.api}/our-agents`);
  }
  deleteOneAgent(id: string) {
    return this.http.delete(`${this.api}/our-agents/${id}`);
  }
  changeStatusAgent(id: string, body: any) {
    return this.http.put(`${this.api}/our-agents/activate/${id}`, body);
  }

  // Home Videos
  getAllHomeVideos(page = 0, limit = 10) {
    return this.http.get(`${this.api}/home-videos?page=${page}&limit=${limit}`);
  }
  addHomeVideo(body: any) {
    return this.http.post(`${this.api}/home-videos`, body);
  }
  getOneHomeVideo(id: string) {
    return this.http.get(`${this.api}/home-videos/${id}`);
  }
  editOneHomeVideo(id: string, body: any) {
    return this.http.put(`${this.api}/home-videos/${id}`, body);
  }
  deleteAllHomeVideos() {
    return this.http.delete(`${this.api}/home-videos`);
  }
  deleteOneHomeVideo(id: string) {
    return this.http.delete(`${this.api}/home-videos/${id}`);
  }
  changeStatusHomeVideo(id: string, body: any) {
    return this.http.put(`${this.api}/home-videos/activate/${id}`, body);
  }

  // partners
  getAllPartners(page = 0, limit = 10) {
    return this.http.get(`${this.api}/partners?page=${page}&limit=${limit}`);
  }
  addPartner(body: any) {
    return this.http.post(`${this.api}/partners`, body);
  }
  getOnePartner(id: string) {
    return this.http.get(`${this.api}/partners/${id}`);
  }
  editOnePartner(id: string, body: any) {
    return this.http.put(`${this.api}/partners/${id}`, body);
  }
  deleteAllPartners() {
    return this.http.delete(`${this.api}/partners`);
  }
  deleteOnePartner(id: string) {
    return this.http.delete(`${this.api}/partners/${id}`);
  }
  changeStatusPartner(id: string, body: any) {
    return this.http.put(`${this.api}/partners/activate/${id}`, body);
  }

  // Our Clients
  getAllClients(page = 0, limit = 10) {
    return this.http.get(`${this.api}/clients?page=${page}&limit=${limit}`);
  }
  addClient(body: any) {
    return this.http.post(`${this.api}/clients`, body);
  }
  getOneClient(id: string) {
    return this.http.get(`${this.api}/clients/${id}`);
  }
  editOneClient(id: string, body: any) {
    return this.http.put(`${this.api}/clients/${id}`, body);
  }
  deleteAllClients() {
    return this.http.delete(`${this.api}/clients`);
  }
  deleteOneClient(id: string) {
    return this.http.delete(`${this.api}/clients/${id}`);
  }
  changeStatusClient(id: string, body: any) {
    return this.http.put(`${this.api}/clients/activate/${id}`, body);
  }

  // Offers
  getAllOffers() {
    return this.http.get(`${this.api}/offer`);
  }

  addOffer(body: any) {
    return this.http.post(`${this.api}/offer`, body);
  }

  getOneOffer(id: string) {
    return this.http.get(`${this.api}/offer/${id}`);
  }

  editOneOffer(id: string, body: any) {
    return this.http.put(`${this.api}/offer/${id}`, body);
  }

  deleteOneOffer(id: string) {
    return this.http.delete(`${this.api}/offer/${id}`);
  }

  // Settings
  getSettings() {
    return this.http.get(`${this.api}/settings`);
  }

  editSetting(id: string, body: any) {
    return this.http.patch(`${this.api}/settings/${id}`, body);
  }

  // Site Info
  getSite() {
    return this.http.get(`${this.api}/site`);
  }

  editSite(id: string, body: any) {
    return this.http.patch(`${this.api}/site/${id}`, body);
  }

  // Chat
  sendMessage(body: any) {
    return this.http.post(`${this.api}/users/replayChat`, body);
  }

  public getAllMessages() {
    return this.http.get(`${this.api}/users/all-conversation`);
  }

  public getAllUserChats(body) {
    return this.http.put(`${this.api}/users/chat`, body);
  }

  public getAllUsersForDropDown(page = 1, limit = 10, searchWord = '', body = {}) {
    return this.http.put(`${this.api}/users/search?page=${page}&limit=${limit}&searchWord=${searchWord}`, body);
  }

  public getAllUsersForDropDownWithSearch(page = 1, limit = 10, body: any) {
    return this.http.put(`${this.api}/users/search?page=${page}&limit=${limit}`, body);
  }

  public getAllProductsForDropDown(page = 1, limit = 10, searchWord = '') {
    return this.http.get(`${this.api}/products/search/all?page=${page}&limit=${limit}&searchWord=${searchWord}`);
  }

  // Image Uploader
  uploadVideos(body: any) {
    return this.http.post(`${this.api}/upload/upload-video`, body, {
      reportProgress: true,
      observe: 'events'
    });
  }
  uploadImages(body: any) {
    return this.http.post(`${this.api}/upload/mulImages`, body, {
      reportProgress: true,
      observe: 'events'
    });
  }

  // File Uploader
  uploadFiles(body: any) {
    return this.http.post(`${this.api}/upload-files/mulFiles`, body, {
      reportProgress: true,
      observe: 'events'
    });
  }

  // About US
  getAboutus() {
    return this.http.get(`${this.api}/about`);
  }

  editAboutus(id: string, body: any) {
    return this.http.put(`${this.api}/about/${id}`, body);
  }

  // Forex US
  getForex() {
    return this.http.get(`${this.api}/forex`);
  }

  editForex(id: string, body: any) {
    return this.http.put(`${this.api}/forex/${id}`, body);
  }

  // Faq
  getAllFaqs(page: number, searchWord?: string) {
    return this.http.get(
      `${apiUrl}/faq/?page=${page}&searchWord=${searchWord}&pageSize=10`
    );
    // .pipe(catchError(e => this.mingoErrorsHandler.handleError(e))) as Observable<BrandData>;
  }

  getOneFaq(id: string) {
    return this.http.get(`${apiUrl}/faq/${id}`);
    // .pipe(catchError(e => this.mingoErrorsHandler.handleError(e))) as Observable<any>;
  }
  addNewFaq(brand) {
    return this.http.post(`${apiUrl}/faq/new`, brand).pipe(
      map((res: any) => {

        return res;
      })
      // catchError(e => this.mingoErrorsHandler.handleError(e))
    ) as Observable<any>;
  }
  editFaq(id, brand) {
    return this.http.patch(`${apiUrl}/faq/${id}`, brand).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  deleteFaq(id) {
    return this.http.delete(`${apiUrl}/faq/${id}`).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  // Interests
  getAllInterests(page: number, searchWord?: string) {
    return this.http.get(
      `${apiUrl}/interests/?page=${page}&searchWord=${searchWord}&pageSize=200`
    );
  }

  getOneInterest(id: string) {
    return this.http.get(`${apiUrl}/interests/${id}`);
  }

  addNewInterest(brand) {
    return this.http.post(`${apiUrl}/interests/new`, brand).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  editInterest(id, brand) {
    return this.http.patch(`${apiUrl}/interests/${id}`, brand).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  deleteInterest(id) {
    return this.http.delete(`${apiUrl}/interests/${id}`).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  // Jobs
  getAllJobs(page: number, searchWord?: string) {
    return this.http.get(
      `${apiUrl}/jobs/?page=${page}&searchWord=${searchWord}&pageSize=200`
    );
  }

  getOneJob(id: string) {
    return this.http.get(`${apiUrl}/jobs/${id}`);
  }

  addNewJob(brand) {
    return this.http.post(`${apiUrl}/jobs/new`, brand).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  editJob(id, brand) {
    return this.http.patch(`${apiUrl}/jobs/${id}`, brand).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  deleteJob(id) {
    return this.http.delete(`${apiUrl}/jobs/${id}`).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  // Client Orders
  getAllClientOrder(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/orders-list?page=${page}&limit=${limit}`, body);
  }

  getOneClientOrder(id: string) {
    return this.http.get(`${apiUrl}/orders-list/${id}`);
  }

  addNewClientOrder(brand) {
    return this.http.post(`${apiUrl}/orders-list/new`, brand).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  editClientOrder(id, brand) {
    return this.http.patch(`${apiUrl}/orders-list/${id}`, brand).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  deleteClientOrder(id) {
    return this.http.delete(`${apiUrl}/orders-list/${id}`).pipe(
      map((res: any) => {

        return res;
      })
    ) as Observable<any>;
  }

  addLessCat(body: LessCat) {
    return this.http.post(`${this.api}/lessonsCat/new`, body) as Observable<LessCat>;
  }
  getOneLessCat(id: string) {
    return this.http.get(`${this.api}/lessonsCat/${id}`);
  }
  editOneLessCat(id: string, body: LessCat) {
    return this.http.patch(`${this.api}/lessonsCat/${id}`, body);
  }
  getAllLessCat(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/lessonsCat?page=${page}&limit=${limit}`, body);
  }
  changeDeletedLessCat(id: string, body: any) {
    return this.http.patch(`${this.api}/lessonsCat/${id}`, body);
  }
  getAllLessonsCategoriesForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/lessonsCat/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }
  getAllLessonsCategoriesForDropdownForAdd() {
    return this.http.get(`${this.api}/lessonsCat/dropdown/for-add`);
  }
  addUnit(body: Unit) {
    return this.http.post(`${this.api}/unites/new`, body) as Observable<Unit>;
  }
  getOneUnit(id: string) {
    return this.http.get(`${this.api}/unites/${id}`);
  }
  editOneUnit(id: string, body: Unit) {
    return this.http.patch(`${this.api}/unites/${id}`, body);
  }
  getAllUnit(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/unites?page=${page}&limit=${limit}`, body);
  }
  changeDeletedUnit(id: string, body: any) {
    return this.http.patch(`${this.api}/unites/${id}/change-delete-activate`, body);
  }
  getAllUnitsForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/unites/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }
  getAllUnitsForMaterialForDropdown(lessonCat) {
    return this.http.get(`${this.api}/unites/dropdown/${lessonCat}`);
  }

  addLesson(body: Lesson) {
    return this.http.post(`${this.api}/lessons/new`, body) as Observable<Lesson>;
  }
  getOneLesson(id: string) {
    return this.http.get(`${this.api}/lessons/${id}`);
  }
  editOneLesson(id: string, body: Lesson) {
    return this.http.patch(`${this.api}/lessons/${id}`, body);
  }
  getAllLessons(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/lessons?page=${page}&limit=${limit}`, body);
  }
  changeDeletedLesson(id: string, body: any) {
    return this.http.patch(`${this.api}/lessons/${id}/change-delete-activate`, body);
  }

  addExercis(body: Exercis) {
    return this.http.post(`${this.api}/exercises/new`, body) as Observable<Exercis>;
  }
  getOneExercis(id: string) {
    return this.http.get(`${this.api}/exercises/${id}`);
  }
  editOneExercis(id: string, body: Exercis) {
    return this.http.patch(`${this.api}/exercises/${id}`, body);
  }
  getAllExercises(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/exercises?page=${page}&limit=${limit}`, body);
  }
  changeDeletedExercis(id: string, body: any) {
    return this.http.patch(`${this.api}/exercises/${id}/change-delete-activate`, body);
  }

  addPrevBaccalaureate(body: Baccalaureate) {
    return this.http.post(`${this.api}/previous-baccalaureate/new`, body) as Observable<Baccalaureate>;
  }
  getOnePrevBaccalaureate(id: string) {
    return this.http.get(`${this.api}/previous-baccalaureate/${id}`);
  }
  editOnePrevBaccalaureate(id: string, body: Baccalaureate) {
    return this.http.patch(`${this.api}/previous-baccalaureate/${id}`, body);
  }
  getAllPrevBaccalaureates(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/previous-baccalaureate?page=${page}&limit=${limit}`, body);
  }
  changeDeletedPrevBaccalaureate(id: string, body: any) {
    return this.http.patch(`${this.api}/previous-baccalaureate/${id}/change-delete-activate`, body);
  }
  getAllPrevBaccalaureatesForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/previous-baccalaureate/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }


  getOneRequestPackage(id: string) {
    return this.http.get(`${this.api}/request-package/${id}`);
  }
  editOneRequestPackage(id: string, body: RequestPackage) {
    return this.http.patch(`${this.api}/request-package/${id}`, body);
  }
  getAllRequestPackages(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/request-package?page=${page}&limit=${limit}`, body);
  }
  changeDeletedRequestPackage(id: string, body: any) {
    return this.http.patch(`${this.api}/request-package/${id}/change-delete-activate`, body);
  }
  acceptRequestPackage(id: string) {
    return this.http.patch(`${this.api}/request-package/${id}/accept-request`, {});
  }
  rejectRequestPackage(id: string) {
    return this.http.patch(`${this.api}/request-package/${id}/reject-request`, {});
  }

  addBook(body: Book) {
    return this.http.post(`${this.api}/books/new`, body) as Observable<Book>;
  }
  getOneBook(id: string) {
    return this.http.get(`${this.api}/books/${id}`);
  }
  editOneBook(id: string, body: Book) {
    return this.http.patch(`${this.api}/books/${id}`, body);
  }
  getAllBooks(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/books?page=${page}&limit=${limit}`, body);
  }
  changeDeletedBook(id: string, body: any) {
    return this.http.patch(`${this.api}/books/${id}/change-delete-activate`, body);
  }
  getAllBooksForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/books/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }

  addExam(body: Exam) {
    return this.http.post(`${this.api}/exams/new`, body) as Observable<Exam>;
  }
  getOneExam(id: string) {
    return this.http.get(`${this.api}/exams/${id}`);
  }
  editOneExam(id: string, body: Exam) {
    return this.http.patch(`${this.api}/exams/${id}`, body);
  }
  getAllExams(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/exams?page=${page}&limit=${limit}`, body);
  }
  changeDeletedExam(id: string, body: any) {
    return this.http.patch(`${this.api}/exams/${id}/change-delete-activate`, body);
  }
  getAllExamsForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/exams/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }
  
  addQuestionForExam(body: Question) {
    return this.http.post(`${this.api}/questions/new`, body) as Observable<Question>;
  }
  getOneQuestion(id: string) {
    return this.http.get(`${this.api}/questions/${id}`);
  }
  editOneQuestion(id: string, body: Question) {
    return this.http.patch(`${this.api}/questions/${id}`, body);
  }
  getAllQuestions(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/questions?page=${page}&limit=${limit}`, body);
  }
  changeDeletedQuestion(id: string, body: any) {
    return this.http.patch(`${this.api}/questions/${id}/change-delete-activate`, body);
  }
  getAllQuestionsForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/questions/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }

  addPackage(body: Package) {
    return this.http.post(`${this.api}/packages/new`, body) as Observable<Package>;
  }
  getOnePackage(id: string) {
    return this.http.get(`${this.api}/packages/${id}`);
  }
  editOnePackage(id: string, body: Package) {
    return this.http.patch(`${this.api}/packages/${id}`, body);
  }
  getAllPackages(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/packages?page=${page}&limit=${limit}`, body);
  }
  changeDeletedPackage(id: string, body: any) {
    return this.http.patch(`${this.api}/packages/${id}/change-delete-activate`, body);
  }
  getAllPackagesForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/packages/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }

  addPaymentMethod(body: PaymentMethod) {
    return this.http.post(`${this.api}/payment-methods/new`, body) as Observable<PaymentMethod>;
  }
  getOnePaymentMethod(id: string) {
    return this.http.get(`${this.api}/payment-methods/${id}`);
  }
  editOnePaymentMethod(id: string, body: PaymentMethod) {
    return this.http.patch(`${this.api}/payment-methods/${id}`, body);
  }
  getAllPaymentMethods(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/payment-methods?page=${page}&limit=${limit}`, body);
  }
  changeDeletedPaymentMethod(id: string, body: any) {
    return this.http.patch(`${this.api}/payment-methods/${id}/change-delete-activate`, body);
  }
  getAllPaymentMethodsForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/payment-methods/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }
  getStatisticsForDashboard(){
    return this.http.get(`${this.api}/statistics/dashboard`)
  }


  addDiscount(body: Discount) {
    return this.http.post(`${this.api}/discounts/new`, body) as Observable<Discount>;
  }
  getOneDiscount(id: string) {
    return this.http.get(`${this.api}/discounts/${id}`);
  }
  editOneDiscount(id: string, body: Discount) {
    return this.http.patch(`${this.api}/discounts/${id}`, body);
  }
  getAllDiscountsMe(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/discounts?page=${page}&limit=${limit}`, body);
  }
  changeDeletedDiscount(id: string, body: any) {
    return this.http.patch(`${this.api}/discounts/${id}`, body);
  }
  getAllDiscountsForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/discounts/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }
  getAllDiscountsForDropdownForAdd() {
    return this.http.get(`${this.api}/discounts/dropdown/for-add`);
  }

  getAllPackagesFeatures(page = 0, limit = 10, body: any) {
    return this.http.post(`${this.api}/packages-features?page=${page}&limit=${limit}`, body);
  }

  addPackagesFeature(body: any) {
    return this.http.post(`${this.api}/packages-features/new`, body);
  }

  getOnePackagesFeature(id: string) {
    return this.http.get(`${this.api}/packages-features/${id}`);
  }

  editOnePackagesFeature(id: string, body: any) {
    return this.http.patch(`${this.api}/packages-features/${id}`, body);
  }

  deleteOnePackagesFeature(id: string) {
    return this.http.delete(`${this.api}/packages-features/${id}`);
  }
  changeDeletedPackagesFeature(id: string, body: any) {
    return this.http.patch(`${this.api}/packages-features/${id}/change-delete-activate`, body);
  }
  getAllPackagesFeaturesForDropdown(page = 0, limit = 10, searchWord = '', isActive = null, deleted= null) {
    return this.http.get(`${this.api}/packages-features/dropdown?page=${page}&limit=${limit}&searchWord=${searchWord}&isActive=${isActive}&deleted=${deleted}`);
  }

  addPackageForUser(id: string, body: any) {
    return this.http.post(`${this.api}/users/${id}/add-package-to-user`, body);
  }
}
