<div class="container" id="scrollMe">
    <div class="row">
        <div class="col">
            <div class="messages-box">
                <span class="chat_msg_item" *ngFor="let m of messages" [class.chat_msg_item_admin]='!m?.admin'
                    [class.chat_msg_item_user]='m.admin'>
                    {{m.content}}
                    <p class="msg-date no-margin">{{m.createdAt | date:'shortTime' }}</p>
                </span>
            </div>
        </div>
    </div>
    <div class="row text-center">
        <div class="col">
            <form [formGroup]='form'>
                <mat-form-field class="full-width">
                    <input formControlName='content' matInput placeholder="{{'letMessage' | translate}}"
                        (keyup.enter)="sendMessage()">
                </mat-form-field>
            </form>
        </div>
    </div>
</div>