import { TranslateService } from '@ngx-translate/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-code',
  templateUrl: './add-code.component.html',
  styleUrls: ['./add-code.component.scss']
})
export class AddCodeComponent implements OnInit {
  form: FormGroup;

  objectId: string;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      value: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      useLength: new FormControl('', [Validators.required]),
      notes: new FormControl('', []),
    });

    this.objectId = this.activateRouter.snapshot.params.id;

    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneCode(this.objectId).subscribe((data: any) => {
      const s = data.data;
      this.form.patchValue(s);
    }, err => {
      this.snackBar.open(err);
    });
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      this.full.addCode(this.form.value).subscribe((data: any) => {
        this.toastr.successToastr('تمت إضافة الكود بنجاح');
        this.router.navigateByUrl('/codes-list');
      }, err => {
        this.toastr.errorToastr(err);
      });
    } else {
      this.full.editOneCode(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.toastr.successToastr('حدث خطأ أثناء إضافة الكود');
          this.router.navigateByUrl('/codes-list');
        }, (error) => {
          this.toastr.errorToastr(error);
        });
    }
  }
}