<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'clientLists' | translate}}</h3>
                    </div>
                </div>

                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary" (click)="add()">{{'addNewClient' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <div class="row text-center">
                    <div class="col-lg-2 col-12">
                        <mat-form-field class="example-form-field">
                            <mat-label>{{'searchInClients' | translate}}</mat-label>
                            <input matInput type="text" [(ngModel)]="searchWord"
                                (input)='searchAds()'>
                            <button *ngIf="searchWord" matSuffix mat-icon-button aria-label="Clear"
                                (click)="searchWord=''">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <form [formGroup]='filterForm'>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'gender' | translate}}</mat-label>
                                            <mat-select formControlName='gender'>
                                                <mat-option value='male'>
                                                    {{'male' | translate}}
                                                </mat-option>
                                                <mat-option value='female'>
                                                    {{'female' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-3">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'interests' | translate}}</mat-label>
                                            <mat-select formControlName='interests'>
                                                <mat-option *ngFor="let i of interests"
                                                    [value]='i._id'>
                                                    {{ i?.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-3">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'jobs' | translate}}</mat-label>
                                            <mat-select formControlName='jobs'>
                                                <mat-option *ngFor="let i of jobs" [value]='i._id'>
                                                    {{ i?.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-3">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'releatedServices' | translate}}
                                            </mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="services">
                                                <mat-option>
                                                    <ngx-mat-select-search [(ngModel)]="usersSearch"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [placeholderLabel]="''"
                                                        (ngModelChange)="changeSearch()">
                                                        <mat-icon ngxMatSelectSearchClear>delete
                                                        </mat-icon>
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let g of users" [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-3">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'linkedUsers' | translate}}
                                            </mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="linkedUsers">
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                        [(ngModel)]="clientSearch"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [placeholderLabel]="''"
                                                        (ngModelChange)="changeLinkedUsersSearch()">
                                                        <mat-icon ngxMatSelectSearchClear>delete
                                                        </mat-icon>
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let g of linkedUsers"
                                                    [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <button mat-raised-button (click)="filter()"
                                            color="primary">
                                            <span>{{'filterClients' | translate}}</span>
                                        </button>
                                    </div>
                                    <div class="col-lg-2">
                                        <button mat-raised-button (click)="getAll()"
                                            color="primary">
                                            <span>{{'getAllUsers' | translate}}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'id' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row; let i = index"> {{ i+1 }} </td>
                    </ng-container>

                    <ng-container *ngFor='let t of tabelKeys' [matColumnDef]="t?.label">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{t?.label |
                            translate}} </th>

                        <td mat-cell *matCellDef="let row">
                            <span *ngIf='t?.type === "text"' [title]='row[t?.key]'> {{row[t?.key] |
                                slice:0:40}} ..
                            </span>
                            <span *ngIf='t?.type === "number"'> {{row[t?.key] }} </span>

                            <span *ngIf='t?.type === "translated-text"'> {{row[t?.key] | translate}}
                            </span>


                            <span *ngIf='t?.type === "date"'> {{row[t?.key] | date : 'short'}}
                            </span>

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="phones">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <p *ngFor="let p of row?.phones">
                                {{p}}
                            </p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row?.email}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="job">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'job' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row?.job}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="age">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'age' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row?.age}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="gender">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'gender' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row?.gender | translate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="communications">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'communications' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer"
                                (click)="goToAndFilter('communications-list', row._id)">
                                visibility
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="orders">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'orders' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer"
                                (click)="goToAndFilter('clients-orders-list', row._id)">
                                visibility
                            </i>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="facebook">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'facebook' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <a href="{{row?.facebook}}" target="_blank">اضغط</a>
                        </td>
                    </ng-container> -->

                    <!-- <ng-container matColumnDef="instagram">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'instagram' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <a href="{{row?.instagram}}" target="_blank">اضغط</a>
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <a (click)='edit(row._id)'>
                                <i class="material-icons pointer">
                                    edit
                                </i>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <span (click)='confirmDialog(row._id)' class="pointer">{{'delete' |
                                translate}}</span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getData($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>