<div class="container container-top-margin add-container">
  <div class="row">
    <div class="col">
      <div class="page-head">
        <div class="flex flex-centerd">
          <i class="uil uil-book-medical"></i>
          <div class="flex-column">
            <h4 class="h4-responsive text-center">
              {{ "requestPackageDetails" | translate }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!(spinner$ | async)">
    <div class="col">
      <mat-card class="add-card">
          <div class="row text-center">
            <div class="col">
              <h5 color="primary">
              <span *ngIf="requestPackage.discountCode !== ''">{{ "discountCode" | translate }}: {{requestPackage.discountCode}}</span>
              <br>
              <span>{{ "image" | translate }}</span>
              </h5>
              <label *ngIf="requestPackage.image !== 'by-admin.jpg' && requestPackage.image !== 'aaa' " class="upload-label" for="upload-photo" appDrag style="overflow: auto;">
                <img id="maa" [src]="requestPackage.image | secure | async" width="80%" alt="" class="pointer" (click)="openImage(requestPackage.image)"/>
              </label>
              <button *ngIf="requestPackage.image !== 'by-admin.jpg' && requestPackage.image !== 'aaa' " (click)="download()" class="btn btn-primary">Download PDF</button>
            </div>
          </div>
          <div class="row text-center my-10px border">
            <div class="col-4 margin-auto brder-after">
                <h4>{{'user' | translate}}</h4>
                <span>{{'userName' | translate}}: {{requestPackage.user.name}}</span><br>
                <span class="uni">{{'userPhone' | translate}}: {{requestPackage.user.phone}}</span><br>
                <span class="uni">{{'userWhatsappPhone' | translate}}: {{requestPackage?.user?.whatsappPhone}}</span>
            </div>
            <div class="col-4 margin-auto brder-after">
                <h4>{{'paymentMethod' | translate}}</h4>
                <span>{{'paymentMethodName' | translate}}: {{requestPackage?.paymentMethod?.nameEn}}</span><br>
                <span>{{'paymentMethodAccountNumber' | translate}}: {{requestPackage?.paymentMethod?.accountNumber}}</span><br>
            </div>
            <div class="col-4 margin-auto">
                <h4>{{'package' | translate}}</h4>
                <span>{{'packageName' | translate}} : {{requestPackage.package.nameEn}}</span><br>
                <span>{{'packageActivateDurationInDays' | translate}} : {{requestPackage.package.activateDurationInDays}}</span><br>
                <span>{{'packagePrice' | translate}} : {{requestPackage.package.price}}</span><br>
            </div>
          </div>
          <div class="row text-center">
            <div class="col yu">
              <button mat-raised-button (click)="acceptRequest()" [disabled]="requestPackage.isProccessed" color="primary">
                <span >{{ "AcceptRequestPackage" | translate }}</span>
              </button>
              <button mat-raised-button (click)="rejectRequest()" [disabled]="requestPackage.isProccessed" color="primary">
                <span >{{ "RefusedRequestPackage" | translate }}</span>
              </button>
            </div>
          </div>
      </mat-card>
    </div>
  </div>
</div>
  