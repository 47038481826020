<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'officeTransferList' | translate}}</h3>
                    </div>
                </div>
                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-office')">{{'addNewOffice' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="address">
                        <th style="width: 30%" mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{'address' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.address}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{'phone' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row.phone}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="city">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{'city' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row?.city?.nameAr}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{'type' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            {{row?.type | translate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" routerLink='/edit-office/{{row._id}}'>
                                edit
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" (click)="confirmDialog(row._id)">
                                delete
                            </i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                    [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = getData()">
                </mat-paginator>
            </mat-card>
        </div>
    </div>
</div>