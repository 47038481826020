<div class="container-fluid container-top-margin add-container">
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <h4 class="text-center">{{'uploadFiles' | translate}}</h4>
                <div class="progress form-group">
                    <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar"
                        [style.width.%]="progress">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="upload-file-card">
                            <div class="flex">
                                <img src="assets/icons/upload-files.png" width="30px" alt="">
                                <h5 color='primary'>
                                    <span [hidden]='!urlList'>{{'imageUploadedSuccessfully' | translate}}</span>
                                    <span [hidden]='urlList'>{{'uploadNewFile' | translate}}</span>
                                </h5>
                                <div class="spacer"></div>
                                <button mat-raised-button color="primary" [disabled]='disabledBtn'
                                    (click)="upload()">{{'uploadFile' | translate}}</button>
                            </div>
                            <label class="file-list" *ngIf="fileList.length > 0">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col">
                                            <i class="material-icons pointer" (click)="deleteUploadedImage()">
                                                close
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="container-fluid">
                                    <div class="col">
                                        <table mat-table [dataSource]="fileList" matSort>

                                            <ng-container matColumnDef="name">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    {{'name' | translate}} </th>
                                                <td mat-cell *matCellDef="let row"> {{row.name}} {{row.originalname}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="type">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    {{'type' | translate}} </th>
                                                <td mat-cell *matCellDef="let row"> {{row.mimetype}} {{row.type}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="size">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    {{'size' | translate}} </th>
                                                <td mat-cell *matCellDef="let row"> {{row.size/1000000}}
                                                    {{'mb' | translate}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="downloadLink">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    {{'downloadLink' | translate}} </th>
                                                <td mat-cell *matCellDef="let row">
                                                    <span [hidden]='!downloadLink'>
                                                        <a class="downloadLink" href="{{api}}/{{row.path}}"
                                                            target="_blank">{{api}}/{{row.path}}</a>
                                                    </span>
                                                    <span [hidden]='downloadLink'>
                                                        {{'downloadLinkWillBeShowHere' | translate}} </span>

                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="delete">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    {{'delete' | translate}} </th>
                                                <td mat-cell *matCellDef="let row">
                                                    <i class="material-icons pointer">
                                                        delete
                                                    </i>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                            </tr>
                                        </table>
                                        <!-- <ul class="list-ul" >
                                            <li>
                                                {{'name' | translate}}: &nbsp; {{fileList[0]?.name}}
                                            </li>
                                            <hr>
                                            <li>
                                                {{'type' | translate}}: &nbsp; {{fileList[0]?.type}}
                                            </li>
                                            <hr>
                                            <li>
                                                {{'size' | translate}}: &nbsp; {{fileList[0]?.size / 1000000}}
                                                &nbsp;{{'MB' | translate}}
                                            </li>
                                        </ul> -->
                                    </div>
                                </div>
                            </label>
                            <label *ngIf="fileList.length === 0" class="uploader" for="upload-photo" appDrag
                                (files)="filesDropped($event)">
                                <svg class="upload-icon" xmlns="http://www.w3.org/2000/svg" width="146.628"
                                    height="117.317" viewBox="0 0 146.628 117.317">
                                    <path id="upload-icon-hover" d="M122.549,34.885A51.368,51.368,0,0,0,24.51,48.755a29.353,29.353,0,0,0,6.9,57.9,
                                                7.338,7.338,0,1,0,0-14.676,14.676,14.676,0,1,1,0-29.353,7.338,7.338,0,0,0,7.338-7.338,
                                                36.691,36.691,0,0,1,71.4-11.815,7.338,7.338,0,0,0,5.724,4.917,22.015,22.015,0,0,1,1.761,
                                                42.855,7.351,7.351,0,1,0,3.669,14.236,36.691,36.691,0,0,0,1.247-70.594Zm-41.9,15.19a7.586,
                                                7.586,0,0,0-10.42,0L48.213,72.09a7.368,7.368,0,1,0,10.42,10.42l9.466-9.54v41.021a7.338,7.338,
                                                0,1,0,14.676,0V72.971l9.466,9.54a7.368,7.368,0,1,0,10.42-10.42Z"
                                        transform="translate(-1.991 -4.013)" fill="#c9c9c9" />
                                </svg>
                                <label for="upload-photo">{{'dragDropFiles' | translate}}</label>
                                <input multiple type="file" (change)="selectFile($event)" name="photo"
                                    id="upload-photo" />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="filesExtensions">
                            {{'filesExtensions' | translate}}: [pdf | PDF | doc | DOC | docx | DOCX | xls | XLS |
                            xlsx | XLSX | csv | CSV | txt | TXT | rtf | RTF | html | HTML | zip | ZIP | mp3 | MP3 |
                            mp4 | MP4 | wma | WMA | mpg | MPG | flv | FLV | jpg | JPG | jpeg | JPEG | png | PNG |
                            gif | GIF | pbix | PBIX | rar | RAR]
                        </p>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>