<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{'authorList' | translate}}</h3>
                    </div>
                </div>

                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-author')">{{'addNewAuthor' | translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container *ngFor='let t of tabelKeys' [matColumnDef]="t?.label">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{t?.label | translate}} </th>

                        <td mat-cell *matCellDef="let row">
                            <span *ngIf='t?.type === "text"'> {{row[t?.key]}} </span>

                            <span *ngIf='t?.type === "translated-text"'> {{row[t?.key] | translate}} </span>


                            <span *ngIf='t?.type === "date"'> {{row[t?.key] | date : 'short'}} </span>

                            <span *ngIf='t?.type === "object"'> {{row[t?.key][t?.child]}} </span>

                            <span *ngIf='t?.type === "boolean"'>
                                <mat-slide-toggle color="primary" [checked]="row[t?.key]"
                                    (change)="activate(row, $event)">
                                    <span class="green" [hidden]='row[t?.key] !== true'>{{'active' | translate}}</span>
                                    <span class="red" [hidden]='row[t?.key] !== false'>{{'inActive' | translate}}</span>
                                </mat-slide-toggle>
                            </span>

                            <span *ngIf='t?.type === "img"'>
                                <i class="material-icons pointer" (click)="openImage(row[t?.key])">
                                    visibility
                                </i>
                            </span>

                            <span *ngIf='t?.type === "innerHtml"'>
                                <span [innerHtml]='row[t?.key] | slice:0:40'></span>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'edit' | translate}} </th>
                        <td mat-cell *matCellDef="let row">
                            <i class="material-icons pointer" routerLink='/edit-author/{{row._id}}'>
                                edit
                            </i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>

                <!-- <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0" [length]="length"
                    [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = getArticles($event)">
                </mat-paginator> -->
            </mat-card>
        </div>
    </div>
</div>