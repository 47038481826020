import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { Category } from './../../../models/category';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit {
  pageEvent;
  userFilter: UntypedFormGroup;
  users = [];
  interests = [];
  jobs = [];
  usersSearch = '';
  searchWord = '';

  clientFilter: UntypedFormGroup;
  linkedUsers = [];
  clientSearch = '';

  filterForm: UntypedFormGroup;
  data: Category[] = [];
  editUrl: '/edit-product/1';

  ages = [
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35','36',
    '37','38','39','40','41','42','43','44','45','46','47', '48', '49', '50','51','52','53','54','55','56','57','58','59','60','61','62','63','64','65',,'66','67','68','69','70'
  ];

  // types is [ text, img, date, inside-url, new-url, boolean, innerHtml, object, translated-text ]
  displayedColumns = [
    'id',
    'name',
    'phones',
    'email',
    'gender',
    'age',
    'job',
    'communications',
    'orders',
    // 'mainImage',
    // 'des',
    // 'price',
    // 'verified',
    // 'promoted',
    // 'status',
    'date',
    // 'comments',
    'edit',
    'delete',
  ];

  tabelKeys = [
    // { label: 'mainImage', key: 'mainImage', type: 'img' },
    { label: 'name', key: 'name', id: '_id', type: 'text' },
    // { label: 'type', key: 'type', type: 'translated-text' },
    // { label: 'price', key: 'price', type: 'number' },
    // { label: 'des', key: 'des', type: 'innerHtml' },
    // { label: 'verified', key: 'verified', type: 'boolean' },
    // { label: 'promoted', key: 'promoted', type: 'boolean' },
    // { label: 'status', key: 'status', type: 'boolean' },
    { label: 'date', key: 'createdAt', type: 'date' },
  ];
  dataSource: MatTableDataSource<Category>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image = [];

  // MatPaginator Inputs
  length: number;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 100, 150, 200];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrManager,
    public dialog: MatDialog
  ) {
    this.userFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      role: new UntypedFormControl('user', []),
      deleted: new UntypedFormControl(false, []),
      active: new UntypedFormControl(true, []),
    });

    this.clientFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
    })
  }

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      gender: new UntypedFormControl(null, []),
      interests: new UntypedFormControl(null, []),
      services: new UntypedFormControl(null, []),
      linkedUsers: new UntypedFormControl(null, []),
      jobs: new UntypedFormControl(null, []),
      age: new UntypedFormControl(null, []),
    });
    this.getData(1);
    this.getAllUsers();
    this.getInterests();

    this.full.getAllClientBase(1, 8, this.clientFilter.value)
    .subscribe((linkedUsersData: any) => {
      this.linkedUsers = linkedUsersData.data;      
    }, (error) => {
  });

  this.full.getAllJobs(1, '').subscribe((usersData: any) => {
    this.jobs = usersData.data;
  }, (error) => { });
  }

  searchAds() {
    this.filterForm.patchValue({
      searchWord: this.searchWord,
    })
    this.getData(1);
  }

  filter() {
    this.getData(1);
  }

  getAll() {
    this.filterForm.patchValue({
      searchWord: null,
      gender: null,
      interests: null,
      services: null,
      linkedUsers: null,
      jobs: null,
      age: null,
    })
    this.getData(1);
  }

  getData(event: any) {
    this.full.getAllClientBase(event.pageIndex + 1 || 1, event.pageSize, this.filterForm.value).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
    }, err => {
    });
  }

  getInterests() {
    this.full.getAllInterests(1).subscribe(
      (data: any) => {
        this.interests = data.data;
      },
      err => {
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  add() {
    let url = window.location.href.replace('clients-list', `add-client-list`);
    window.open(url, '_blank')
  }

  edit(id) {
    let url = window.location.href.replace('clients-list', `edit-client-list/${id}`);
    window.open(url, '_blank')
  }

  filterByUser(id: string) {
    this.filterForm.patchValue({
      user: id
    })
    this.getData(1)
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }

  delete(id) {
    this.full.deleteOneClientBase(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar(this.translate.instant('articleDeletedSuccess'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  copy(val) {
    // const selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    // selBox.style.opacity = '0';
    // selBox.value = `https://ncrgrup.com/product/${val._id}`;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand('copy');
    // document.body.removeChild(selBox);
    // this.toastr.successToastr(this.translate.instant('linkCopied'));
  }

  getAllUsers() {
    this.full.getAllUsersForDropDownWithSearch(1, 100, this.userFilter.value)
      .subscribe((response: any) => {
        this.users = response.data;
      }, (error) => {
      });
  }

  changeSearch() {
    if (this.usersSearch) {
      this.userFilter.patchValue({
        searchWord: this.usersSearch,
        advertiserStatus: true
      })

      this.full.getAllUsersForDropDown(1, 10, this.usersSearch, this.userFilter.value)
        .subscribe((response: any) => {
          this.users = response.data;

        }, (error) => {
        });
    }
  }

  getAllLinkedUsers() {
    this.full.getAllClientBase(1, 50, this.clientFilter.value)
      .subscribe((response: any) => {
        this.linkedUsers = response.data;
      }, (error) => {
      });
  }

  changeLinkedUsersSearch() {
    if (this.clientSearch) {
      this.clientFilter.patchValue({
        searchWord: this.clientSearch,
      })
      this.full.getAllClientBase(1, 50, this.clientFilter.value)
        .subscribe((response: any) => {
          this.linkedUsers = response.data;
        }, (error) => {
        });
    }
  }

  goToAndFilter(link, id) {
    this.router.navigate([link], { queryParams: { filterId: id } });
  }
}
