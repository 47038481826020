<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center" *ngIf="!objectId">{{'addNewUser' |
                            translate}}</h4>
                        <h4 class="h4-responsive text-center" *ngIf="objectId">{{'editUser' |
                            translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <div class="progress form-group">
                        <div class="progress-bar progress-bar-striped bg-success-bar"
                            role="progressbar" [style.width.%]="progress">
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <h5 color='primary'>
                                <span>{{'image' | translate}}</span>
                            </h5>
                            <label *ngIf="thumbnailFileList.length > 0" class="upload-label"
                                for="upload-photo" appDrag>
                                <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer">
                                <i class="material-icons pointer"
                                    (click)="deleteUploadedThumbnailImage()">
                                    close
                                </i>
                            </label>
                            <label *ngIf="thumbnailFileList.length === 0" class="upload-label"
                                for="upload-thumbnail" appDrag>
                                <img class="pointer"
                                    src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="thumbnailSelectFile($event)"
                                    name="photo" id="upload-thumbnail" />
                            </label>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <h5 color='primary'>
                                <span>{{'cover' | translate}}</span>
                            </h5>
                            <label *ngIf="coverFileList?.length > 0" class="upload-label"
                                for="upload-photo" appDrag>
                                <img [src]="coverUrlList | secure | async" width="80%" alt="" class="pointer">
                                <i class="material-icons pointer"
                                    (click)="deleteUploadedCoverImage()">
                                    close
                                </i>
                            </label>
                            <label *ngIf="coverFileList?.length === 0" class="upload-label"
                                for="upload-thumbnail-user-cover" appDrag>
                                <img class="pointer"
                                    src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="coverSelectFile($event)" name="photo" class="upload-thumbnail-id" id="upload-thumbnail-user-cover" />
                            </label>
                        </div>
                    </div>

                    <div class="row text-right">
                        <div class="col">
                            <h6>المعلومات الشخصية</h6>
                            <hr>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='name' matInput
                                    placeholder="{{'name' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='disEmail' type="text" matInput
                                    placeholder="{{'disEmail' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='email' matInput type='text' id='text'
                                    placeholder="{{'email' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='website' type="text" matInput
                                    placeholder="{{'website' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='phone' type="tel" matInput
                                    placeholder="{{'phone' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='facebook' type="text" matInput
                                    placeholder="{{'facebook' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='instagram' type="text" matInput
                                    placeholder="{{'instagram' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='youtube' type="text" matInput
                                    placeholder="{{'youtube' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='tiktok' type="text" matInput
                                    placeholder="{{'tiktok' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-right">
                        <div class="col">
                            <h6>معلومات الحساب</h6>
                            <hr>
                        </div>
                    </div>

                    <div class="row text-center">
                        <!-- <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='galleryMaxCount' matInput type='number'
                                    placeholder="{{'galleryMaxCount' | translate}}">
                            </mat-form-field>
                        </div> -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='index' matInput type='number'
                                    placeholder="{{'index' | translate}}">
                            </mat-form-field>
                        </div>
                        <!-- <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='views' matInput type='text' id='views'
                                    placeholder="{{'views' | translate}}">
                            </mat-form-field>
                        </div> -->
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'isReleatedFirst' | translate}}</mat-label>
                                <mat-select formControlName="releatedFirst">
                                    <mat-option value="true">
                                        {{'releatedFirst' | translate}}
                                    </mat-option>
                                    <mat-option value="false">
                                        {{'notReleatedFirst' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'role' | translate}}</mat-label>
                                <mat-select formControlName="role">
                                    <mat-option *ngFor="let role of roles" [value]="role">
                                        {{role | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <!-- <mat-form-field class="full-width">
                                <mat-label>{{'category' | translate}}</mat-label>
                                <mat-select formControlName="cats" multiple>
                                    <mat-option *ngFor="let role of cats" [value]="role._id">
                                        {{role.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <mat-form-field class="full-width">
                                <mat-label>{{'category' | translate}}</mat-label>
                                <mat-select placeholder="{{ '' | translate }}"
                                    formControlName="cats" multiple="true"
                                    (selectionChange)="getSpecialsForCat($event);" #multiSelect>
                                    <!-- getDiscountsForCat($event) -->
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="catssearchWord"
                                            noEntriesFoundLabel="{{'noResults' | translate}}"
                                            [ngModelOptions]="{ standalone: true }"
                                            [placeholderLabel]="''"
                                            (ngModelChange)="changeCatsSearch()">
                                            <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let g of cats" [value]="g._id">
                                        {{ g.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class="row text-center">
                        <div class="col" *ngIf="form.get('advertiserStatus').value === 'true'">
                            <mat-form-field class="full-width">
                                <mat-label>{{'specials' | translate}}</mat-label>
                                <mat-select formControlName="specials" multiple>
                                    <mat-option *ngFor="let s of specials" [value]="s._id">
                                        {{s?.name | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'discounts' | translate}}</mat-label>
                                <mat-select formControlName="discounts" multiple>
                                    <mat-option *ngFor="let s of discounts" [value]="s._id">
                                        {{s?.name | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->

                    <div class="row text-center">
                        <!-- <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'city' | translate}}</mat-label>
                                <mat-select formControlName="city"
                                    (selectionChange)="getStatesForCity($event.value)">
                                    <mat-option *ngFor="let c of cities" [value]="c._id">
                                        {{c.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                        <!-- <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>{{'state' | translate}}</mat-label>
                                <mat-select placeholder="{{ '' | translate }}"
                                    formControlName="state">
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="stateSearchWord"
                                            noEntriesFoundLabel="{{'noResults' | translate}}"
                                            [ngModelOptions]="{ standalone: true }"
                                            [placeholderLabel]="''"
                                            (ngModelChange)="changeStatesSearch()">
                                            <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let g of states" [value]="g._id">
                                        {{ g.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                    </div>
                    <!-- <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label> {{'gender' | translate}}</mat-label>
                                <mat-select formControlName='gender'>
                                    <mat-option value='1'>
                                        {{'male' | translate}}
                                    </mat-option>
                                    <mat-option value='2'>
                                        {{'female' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label> {{'status' | translate}}</mat-label>
                                <mat-select formControlName='active'>
                                    <mat-option value='true'>
                                        {{'active' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'inActive' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label> {{'promoted' | translate}}</mat-label>
                                <mat-select formControlName='promoted'>
                                    <mat-option value='true'>
                                        {{'isPromoted' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'notPromoted' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- advertiserStatus -->
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label> {{'isVerified' | translate}}</mat-label>
                                <mat-select formControlName='verified'>
                                    <mat-option value='true'>
                                        {{'verified' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'notVerified' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label> {{'advertiserStatus' | translate}}</mat-label>
                                <mat-select formControlName='advertiserStatus'>
                                    <mat-option value='true'>
                                        {{'advertiser' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'user' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-center" *ngIf="!objectId">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='password' type="password" matInput
                                    placeholder="{{'password' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-center" *ngIf="!objectId">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='passwordConfirm' type="password" matInput
                                    placeholder="{{'confirmPassword' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- <div class="row text-right">
                        <div class="col">
                            <br>
                            <br>
                            <h6><b>ساعات العمل والعنوان</b></h6>
                            <hr>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col-12">
                            <mat-form-field class="full-width">
                                <mat-label> {{'alwaysOpen' | translate}}</mat-label>
                                <mat-select formControlName='alwaysOpen'>
                                    <mat-option value='true'>
                                        {{'yes' | translate}}
                                    </mat-option>
                                    <mat-option value='false'>
                                        {{'no' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='hourFrom' matInput type='time' id='timeFrom'
                                    placeholder="{{'hourFrom' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='hourTo' matInput type='time' id='timeTo'
                                    placeholder="{{'hourTo' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='hourFrom1' matInput type='time'
                                    id='timeFrom' placeholder="{{'hourFrom' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='hourTo1' matInput type='time' id='timeTo'
                                    placeholder="{{'hourTo' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <textarea formControlName='address' rows='3' type="text" matInput
                                    placeholder="{{'address' | translate}}"></textarea>
                            </mat-form-field>
                        </div>
                    </div> -->
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <textarea formControlName='bio' rows='10' type="text" matInput
                                    placeholder="{{'bio' | translate}}"></textarea>
                            </mat-form-field>

                            <!-- <span>{{'bio' | translate}}</span>
                            <ckeditor formControlName='bio' [editor]="Editor" [config]='config'>
                            </ckeditor> -->
                        </div>
                    </div>
                    <!-- <div class="row text-center">
                        <div class="col-5">
                            <mat-form-field class="full-width">
                                <input formControlName='long' type="text" matInput
                                    placeholder="{{'lat' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col-5">
                            <mat-form-field class="full-width">
                                <input formControlName='lat' type="text" matInput
                                    placeholder="{{'long' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="col-2 margin-auto">
                            <a href="https://maps.google.com/?q={{form.get('long').value}},{{form.get('lat').value}}"
                                target="_blank">الموقع</a>
                        </div>
                    </div> -->
                    <div class="row text-center">
                        <div class="col-6">
                            <mat-form-field class="full-width">
                                <mat-label> {{'quetion' | translate}}</mat-label>
                                <mat-select formControlName='quetion'>
                                    <mat-option value='ماذا كان اسم استاذك في الابتدائية ؟'>
                                        {{'ماذا كان اسم استاذك في الابتدائية ؟' | translate}}
                                    </mat-option>
                                    <mat-option value='ما هو اسم صديق الطفولة ؟'>
                                        {{'ما هو اسم صديق الطفولة ؟' | translate}}
                                    </mat-option>
                                    <mat-option value='ما هو لونك المفضل ؟'>
                                        {{'ما هو لونك المفضل ؟' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="full-width">
                                <input formControlName='answer' type="text" matInput
                                    placeholder="{{'answer' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-right">
                        <div class="col">
                            <p>{{user?.sequrityQuetions[0]?.quetion}}</p>
                            <p>{{user?.sequrityQuetions[0]?.answer}}</p>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid'
                                color="primary">
                                <span *ngIf="!objectId">{{'addNewUser' | translate}}</span>
                                <span *ngIf="objectId">{{'editUser' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>

                <form [formGroup]='passForm' *ngIf="objectId">
                    <div class="row text-center">
                        <div class="col">
                            <br>
                            <hr>
                            <h5>{{'editUserPass' | translate}}</h5>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='newPassword' type="password" matInput
                                    placeholder="{{'password' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input type="password" formControlName='passwordConfirm' matInput
                                    placeholder="{{'confirmPassword' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="editPassword()"
                                [disabled]='!passForm.valid' color="primary">
                                <span>{{'editPassword' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>