<div class="container-fluid table-container">
    <div class="row">
        <div class="col">
            <mat-card class="table-card">
                <div class="row text-center">
                    <div class="col">
                        <h3>{{ "clientOrders" | translate }}</h3>
                    </div>
                </div>
                <div class="row add-item-row">
                    <div class="col flex">
                        <button mat-raised-button color="primary"
                            (click)="goTo('add-client-order')">{{'addClientOrder' |
                            translate}}</button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <form [formGroup]='filterForm'>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <mat-form-field class="full-width">
                                            <mat-label>{{'client' | translate}}
                                            </mat-label>
                                            <mat-select placeholder="{{ '' | translate }}"
                                                formControlName="client">
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                        [(ngModel)]="clientSearch"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [placeholderLabel]="''"
                                                        (ngModelChange)="changeLinkedUsersSearch()">
                                                        <mat-icon ngxMatSelectSearchClear>delete
                                                        </mat-icon>
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let g of linkedUsers"
                                                    [value]="g._id">
                                                    {{ g.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <button mat-raised-button (click)="filter()"
                                            color="primary">
                                            <span>{{'filterClients' | translate}}</span>
                                        </button>
                                    </div>
                                    <div class="col-lg-2">
                                        <button mat-raised-button (click)="getAll()"
                                            color="primary">
                                            <span>{{'showAll' | translate}}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="productType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "productType" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">{{ row.productType }}</td>
                        </ng-container>

                        <ng-container matColumnDef="qty">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "qty" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <div>{{row?.qty}}</div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="value">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "value" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <div>{{row?.value}}</div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="resource">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "resource" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <div>{{row.resource}}</div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="notes">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "notes" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <div>{{row.notes}}</div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "date" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.date | date: "short" }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="client">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "client" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row" class="pointer"
                                (click)="edit(row?.client?._id)">{{
                                row?.client?.name }}</td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "edit" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="material-icons pointer"
                                    routerLink="/edit-faq/{{ row._id }}">
                                    edit
                                </i>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'delete' |
                                translate}} </th>
                            <td mat-cell *matCellDef="let row">
                                <span (click)='confirmDialog(row._id)' class="pointer">{{'delete' |
                                    translate}}</span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>

                    <mat-paginator #paginator [showFirstLastButtons]="true" [pageIndex]="0"
                        [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="pageEvent = getArticles($event)">
                    </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>