<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="h4-responsive text-center" *ngIf="!objectId">{{'addNewAuthor' | translate}}</h4>
                        <h4 class="h4-responsive text-center" *ngIf="objectId">{{'editAuthor' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <!-- <div class="progress form-group">
                        <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar"
                            [style.width.%]="progress">
                        </div>
                    </div> -->
                    <div class="row text-center">
                        <div class="col-lg-6 col-12">
                            <h5 color='primary'>
                                <span>{{'authorImage' | translate}}</span>
                            </h5>
                            <label *ngIf="fileList.length > 0" class="upload-label" for="upload-photo" appDrag
                                (files)="filesDropped($event)">
                                <img [src]="fileList | secure | async" width="80%" alt="">
                                <span class="delete-img" (click)='deleteImage()'>X</span>
                            </label>
                            <label *ngIf="fileList.length === 0" class="upload-label" for="upload-photo" appDrag
                                (files)="filesDropped($event)">
                                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png"
                                    width="80%" alt="">
                                <input type="file" (change)="selectFile($event)" name="photo" id="upload-photo"
                                    multiple />
                            </label>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label> {{'status' | translate}}</mat-label>
                                <mat-select formControlName='status'>
                                    <mat-option value='false'>
                                        {{'active' | translate}}
                                    </mat-option>
                                    <mat-option value='true'>
                                        {{'inActive' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='name' matInput placeholder="{{'name' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='email' matInput placeholder="{{'email' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='website' matInput placeholder="{{'website' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='facebook' matInput placeholder="{{'facebook' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='twitter' matInput placeholder="{{'twitter' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='instagram' matInput placeholder="{{'instagram' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='phone' matInput placeholder="{{'phone' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input formControlName='age' matInput placeholder="{{'age' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="full-width">
                                <textarea rows='4' formControlName='bio' matInput
                                    placeholder="{{'bio' | translate}}"></textarea>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row text-center">
                        <div class="col">
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid' color="primary">
                                <span *ngIf="!objectId">{{'addNewAuthor' | translate}}</span>
                                <span *ngIf="objectId">{{'editAuthor' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>