import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullServiceService } from '../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-site-info',
  templateUrl: './site-info.component.html',
  styleUrls: ['./site-info.component.scss']
})
export class SiteInfoComponent implements OnInit {
  featArr = [];
  form: UntypedFormGroup;
  settings;
  // Modal Image
  image: string;

  objectId: string;

  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  appFileList = [];
  appUrlList: string;
  appUrlAfterUpload: any;
  appList: File = null;

  constructor(
    private full: FullServiceService,
    private router: Router,
    private translae: TranslateService,
    public dialog: MatDialog,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      feat: new UntypedFormControl('', []),

      headerTitel: new UntypedFormControl('', []),
      headerDes: new UntypedFormControl('', []),
      aboutTitel: new UntypedFormControl('', []),
      aboutDes: new UntypedFormControl('', []),
      dawrliTitel: new UntypedFormControl('', []),
      dawrliDes: new UntypedFormControl('', []),
      featersTitle: new UntypedFormControl('', []),
      featersDes: new UntypedFormControl('', []),
      featersSecondTitle: new UntypedFormControl('', []),
      featers: new UntypedFormControl('', []),
      videoTitle: new UntypedFormControl('', []),
      videoDes: new UntypedFormControl('', []),
      videoLink: new UntypedFormControl('', []),
      musicTitle: new UntypedFormControl('', []),
      musicDes: new UntypedFormControl('', []),
      musicImg: new UntypedFormControl('', []),

      contactTitle: new UntypedFormControl('', []),
      contactDes: new UntypedFormControl('', []),
      downloadDes: new UntypedFormControl('', []),
    });
    this.getObject();
  }

  getObject() {
    this.full.getSite().subscribe((response: any) => {
      const s = response.data[0];
      this.settings = response.data[0];

      this.form.patchValue(s);

      this.thumbnailFileList = s.musicImg;
      this.thumbnailUrlList = s.musicImg;

      this.featArr = s.featers;
    }, err => {
      this.snackBar.open(err);
    });
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = []

    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            musicImg: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      musicImg: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  edit() {
    if (!this.form.valid) {
      return false;
    }

    this.full.editSite(this.settings._id, this.form.value).subscribe((data: any) => {
      this.full.openSnackBar(this.translae.instant('settingsEdited'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  addFeat() {
    this.featArr.push(this.form.get('feat').value);
    this.form.patchValue({
      featers: this.featArr
    })
  }
}

