<div class="container container-top-margin add-container">
    <div class="row">
        <div class="col">
            <div class="page-head">
                <div class="flex flex-centerd">
                    <i class="uil uil-book-medical"></i>
                    <div class="flex-column">
                        <h4 class="text-center" *ngIf="!objectId">{{'addHomeVideo' | translate}}</h4>
                        <h4 class="text-center" *ngIf="objectId">{{'editHomeVideo' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="add-card">
                <form [formGroup]='form'>
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'arabic' | translate}}
                            </ng-template>
                            <div class="container">
                                <div class="row max-width-row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='titleAr' matInput
                                                placeholder="{{'title' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row max-width-row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='subTitleAr' matInput
                                                placeholder="{{'subTitle' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row max-width-row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='videoUrl' matInput
                                                placeholder="{{'videoUrl' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row max-width-row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <mat-label> {{'status' | translate}}</mat-label>
                                            <mat-select formControlName='status'>
                                                <mat-option value='true'>
                                                    {{'active' | translate}}
                                                </mat-option>
                                                <mat-option value='false'>
                                                    {{'inActive' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>

                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'english' | translate}}
                            </ng-template>
                            <div class="container">
                                <div class="row max-width-row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='titleEn' matInput
                                                placeholder="{{'title' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row max-width-row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='subTitleEn' matInput
                                                placeholder="{{'subTitle' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{'turkish' | translate}}
                            </ng-template>
                            <div class="container">
                                <div class="row max-width-row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='titleTr' matInput
                                                placeholder="{{'title' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row max-width-row text-center">
                                    <div class="col">
                                        <mat-form-field class="full-width">
                                            <input formControlName='subTitleTr' matInput
                                                placeholder="{{'subTitle' | translate}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>

                    <div class="row max-width-row text-center">
                        <div class="col">
                            <br>
                            <button mat-raised-button (click)="addEdit()" [disabled]='!form.valid' color="primary">
                                <span *ngIf="!objectId">{{'addHomeVideo' | translate}}</span>
                                <span *ngIf="objectId">{{'editHomeVideo' | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>